<template>
  <div>
    <validation-provider
      #default="{ errors }"
      name="itemStatus"
      :rules="requiredField ? 'required' : ''"
    >
      <b-form-group
        label="Selecione o Status do Item"
        label-for="itemStatus"
      >
        <v-select
          id="itemStatus"
          v-model="statusSelected"
          :class="errors.length > 0 ? 'error-select' : ''"
          :filter="fuseSearch"
          label="name"
          :options="options"
          :placeholder="'Selecione o Status do Item'"
          :disabled="readonly"
          :clearable="!readonly"
          @open="onCreate()"
        >
          <template #option="{ name, color }">
            <b-badge
              pill
              class="text-capitalize"
              :style="{'background-color': color}"
            >
              {{ name | truncate(18, '...') }}
            </b-badge>
          </template>

          <template #selected-option="{ name, color }">
            <b-badge
              pill
              class="text-capitalize"
              :style="{'background-color': color}"
            >
              {{ name | truncate(18, '...') }}
            </b-badge>
          </template>

          <template #no-options>
            Não há opções de status do item.
          </template>
        </v-select>
        <small class="text-danger">{{ errors[0] }}</small>
      </b-form-group>
    </validation-provider>
  </div>
</template>
<script>
import {
  BFormGroup,
  BBadge,
} from 'bootstrap-vue'

import {
  ValidationProvider,
} from 'vee-validate'

import {
  required,
} from '@validations'

import vSelect from 'vue-select'
import Fuse from 'fuse.js'
import truncate from '@/components/utils/filters/truncate'

export default {
  components: {
    BFormGroup,
    BBadge,
    ValidationProvider,
    vSelect,
  },
  filters: {
    truncate,
  },
  model: {
    prop: 'statusValue',
    event: 'statusChange',
  },
  props: {
    statusValue: {
      type: [Object, String],
      required: false,
      default: null,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    requiredField: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    const statusSelected = {
      name: 'Pendente',
      color: '#FF7A00',
      key: 'pending',
    }
    const options = [
      {
        name: 'Ganho',
        color: '#049F14',
        key: 'gain',
      },
      {
        name: 'Perdido',
        color: '#FF0000',
        key: 'lost',
      },
      {
        name: 'Pendente',
        color: '#FF7A00',
        key: 'pending',
      },
    ]

    return {
      statusSelected,
      options,
      required,
    }
  },
  computed: {
    status: {
      get() {
        return this.statusValue
      },
      set(value) {
        this.$emit('statusChange', value)
      },
    },
  },
  watch: {
    statusSelected: function updateStatusSelected(value) {
      if (value) {
        this.status = value.key
      }
    },
  },
  created() {
    this.onCreate()
  },
  methods: {
    onCreate() {
      if (this.status === 'gain') {
        this.statusSelected = {
          name: 'Ganho',
          color: '#049F14',
          key: 'gain',
        }
      } else if (this.status === 'lost') {
        this.statusSelected = {
          name: 'Perdido',
          color: '#FF0000',
          key: 'lost',
        }
      } else {
        this.statusSelected = {
          name: 'Pendente',
          color: '#FF7A00',
          key: 'pending',
        }
      }
      this.$emit('ready', true)
    },
    fuseSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ['name'],
        shouldSort: true,
      })
      return search.length
        ? fuse.search(search).map(({ item }) => item)
        : fuse.list
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style lang="scss" scoped>
.v-select {
  background-color: #fff;
}
</style>
