<template>
  <div
    id="biddingProductList"
    class="mt-1 mx-1"
  >
    <b-row
      v-if="showHeader"
      id="biddingProductListHeader"
      class="pt-1 pb-1"
    >
      <b-col
        cols="12"
        md="12"
        class="biddingCardItemBlock biddingCardItemSection pr-2"
      >
        <div
          class="justify-content-end d-flex"
        >
          <div
            class="mr-2"
          >
            <span
              class="biddingCardItemSectionTitle"
            >
              TOTAL MÍNIMO
            </span>
            <div
              class="biddingCardItemSectionContent mb-0 mt-50"
            >
              <b-row>
                <b-col>
                  <span v-if="!isCalculating">
                    <h5
                      v-if="totalMin > 0"
                      class="mb-0 d-flex justify-content-end"
                    >
                      R$ {{ totalMin | formatCurrency }}
                    </h5>
                    <h5
                      v-else
                      class="mb-0 d-flex justify-content-center"
                    >
                      -
                    </h5>
                  </span>
                  <div v-else>
                    <b-spinner
                      class="spinner-loading"
                      variant="info"
                      type="grow"
                      small
                    />
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
          <div
            class="ml-2"
          >
            <span
              class="biddingCardItemSectionTitle"
            >
              TOTAL ESTIMADO
            </span>
            <div
              class="biddingCardItemSectionContent mb-0 mt-50"
            >
              <b-row>
                <b-col>
                  <span v-if="!isCalculating">
                    <h5
                      class="mb-0 d-flex justify-content-end"
                    >
                      R$ {{ total | formatCurrency }}
                    </h5>
                  </span>
                  <div v-else>
                    <b-spinner
                      class="spinner-loading"
                      variant="info"
                      type="grow"
                      small
                    />
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
          <div
            v-if="!readonly && !addingProduct && items.length > 0"
            class="d-flex align-items-center justify-content-end ml-3"
          >
            <b-form-checkbox
              id="checkAllProducts"
              v-model="checkAllProducts"
              name="checkAllProducts"
              @input="handleCheckAllProducts"
            />
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row
      v-if="waitRefresh"
    >
      <b-col
        class="d-flex justify-content-center align-self-center mt-2"
      >
        <b-spinner
          variant="info"
          class="align-middle mb-1 mt-3"
        />
      </b-col>
    </b-row>
    <b-row
      v-for="(item, index) in indexItem"
      v-else
      :key="index"
      class="biddingProductListItem"
      :class="(index % 2 == 0) ? 'itemEven' : 'itemOdd'"
    >
      <b-col
        v-show="loteOpen === '' || loteOpen === item"
      >
        <bidding-product
          v-if="items[item].type === 'unit'"
          ref="refBiddingProduct"
          :bidding="bidding"
          :item="items[item]"
          :item-list="items"
          :item-index="item"
          :readonly="readonly"
          :is-even="(index % 2 == 0)"
          @open="updateOpen(item)"
          @isAdding="isAddingProduct"
          @delete="deleteItem"
          @toggleCard="toggleCard"
          @product-check="onProductCheck"
          @type-convert="updateItemType"
        />
        <bidding-product-compound
          v-else-if="items[item].type === 'compound'"
          ref="refBiddingProduct"
          :bidding="bidding"
          :item="items[item]"
          :item-list="items"
          :item-index="item"
          :readonly="readonly"
          :is-even="(index % 2 == 0)"
          @open="updateOpen(item)"
          @isAdding="isAddingProduct"
          @delete="deleteItem"
          @toggleCard="toggleCard"
          @product-check="onProductCheck"
          @type-convert="updateItemType"
        />
        <bidding-product-lote
          v-else-if="items[item].type === 'lote'"
          ref="refBiddingProduct"
          :bidding="bidding"
          :lote="items[item]"
          :item-list="items"
          :item-index="item"
          :readonly="readonly"
          :is-even="(index % 2 == 0)"
          @open="updateOpenLote(item)"
          @close="updateCloseLote(item)"
          @delete="deleteItem"
          @duplicate="addDuplicatedLote"
          @toggleCard="toggleCard"
          @product-check="onProductCheck"
        />
      </b-col>
    </b-row>
    <b-row
      v-if="(items.length === 0) && !waitRefresh"
      id="biddingProductEmpty"
    >
      <b-col
        class="d-flex justify-content-center align-self-center"
      >
        <p id="emptyText">
          Não existem produtos cadastrados.
        </p>
      </b-col>
    </b-row>
    <b-row
      v-if="!readonly && !addingProduct && showHeader"
      id="biddingProductAddRow"
    >
      <b-col
        class="justify-content-end d-flex"
      >
        <b-button
          v-b-tooltip.hover
          v-b-modal.idModalAddProduct
          class="btn-icon rounded-circle m-1"
          variant="primary"
          size="md"
          title="Adicionar produto"
        >
          <svg-icon
            type="mdi"
            :path="mdiPlus"
          />
        </b-button>
      </b-col>
    </b-row>
    <div>
      <b-modal
        id="idModalAddProduct"
        ref="modalAddProduct"
        no-close-on-backdrop
        no-close-on-esc
        hide-footer
        centered
      >
        <template #modal-title>
          Selecione o Tipo do Produto
        </template>
        <b-row
          class="mt-1 ml-0 mr-0 mb-2 w-100"
        >
          <b-col
            cols="12"
            md="4"
          >
            <b-button
              v-b-tooltip.hover
              class="m-1 addProductBtn"
              size="md"
              variant=""
              title="Adicionar Produto Unitário"
              @click="addProductUnit()"
            >
              <b-row>
                <b-col>
                  <svg-icon
                    type="mdi"
                    size="45"
                    :path="mdiAlphaUBoxOutline"
                  />
                </b-col>
              </b-row>
              <b-row class="mt-50">
                <b-col>
                  <span class="btn-label">Produto Unitário</span>
                </b-col>
              </b-row>
            </b-button>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-button
              v-b-tooltip.hover
              class="m-1 addProductBtn"
              size="md"
              variant=""
              title="Adicionar Produto Composto"
              @click="addProductCompound()"
            >
              <b-row>
                <b-col>
                  <svg-icon
                    type="mdi"
                    size="45"
                    :path="mdiAlphaCBox"
                  />
                </b-col>
              </b-row>
              <b-row class="mt-50">
                <b-col>
                  <span class="btn-label">Produto Composto</span>
                </b-col>
              </b-row>
            </b-button>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-button
              v-b-tooltip.hover
              class="m-1 addProductBtn"
              size="md"
              variant=""
              title="Adicionar Lote de Produtos"
              @click="addProductLote()"
            >
              <b-row>
                <b-col>
                  <svg-icon
                    type="mdi"
                    size="45"
                    :path="mdiAlphaLCircleOutline"
                  />
                </b-col>
              </b-row>
              <b-row class="mt-50">
                <b-col>
                  <span class="btn-label">Lote de Produtos</span>
                </b-col>
              </b-row>
            </b-button>
          </b-col>
        </b-row>
      </b-modal>
      <validation-observer
        v-slot="{ handleSubmit, invalid }"
        ref="refFormObserver"
      >
        <b-modal
          id="idModalAddLote"
          ref="refModalAddLote"
          no-close-on-backdrop
          no-close-on-esc
          centered
          cancel-variant="outline-primary"
          :ok-disabled="invalid"
          @ok="handleSubmit(addLoteCard)"
          @cancel="cancelLoteCard()"
          @hidden="cancelLoteCard()"
        >
          <template #modal-title>
            Cadastro de Lote
          </template>
          <template #modal-cancel>
            Cancelar
          </template>
          <template #modal-ok>
            Salvar
          </template>
          <b-form
            class="mt-1"
          >
            <b-row>
              <b-col>
                <validation-provider
                  #default="validationContext"
                  name="name"
                  :rules="{
                    required: true,
                  }"
                >
                  <b-form-group
                    label="Nome do Lote"
                    label-for="name"
                  >
                    <b-form-input
                      id="name"
                      v-model="lote.name"
                      name="name"
                      placeholder="Nome do Lote"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
            </b-row>
          </b-form>
        </b-modal>
      </validation-observer>
      <b-modal
        id="idModalGainOrLost"
        ref="refModalGainOrLost"
        class="modalStyle"
        no-close-on-backdrop
        no-close-on-esc
        centered
        hide-footer
      >
        <b-form
          class="py-2"
        >
          <b-row>
            <b-col>
              <span
                class="d-flex justify-content-center px-2 mb-1 layoutModalAlert"
              >
                <svg-icon
                  type="mdi"
                  size="80"
                  :path="mdiAlertCircle"
                />
              </span>
              <span
                class="d-flex justify-content-center px-2 my-1 fixLayoutTextModal"
              >
                Não é possível realizar esta ação, pois um ou mais itens estão marcados como ganho/perdido
              </span>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
      <b-modal
        id="idModalConfirmDeleteSelected"
        ref="refModalConfirmDeleteSelected"
        no-close-on-backdrop
        no-close-on-esc
        centered
        cancel-variant="outline-primary"
        @ok="removeItems"
        @cancel="$refs.refModalConfirmDeleteSelected.hide()"
        @hidden="$refs.refModalConfirmDeleteSelected.hide()"
      >
        <template #modal-title>
          Confirmar?
        </template>
        <template #modal-ok>
          Confirmar
        </template>
        <template #modal-cancel>
          Cancelar
        </template>

        <b-row
          align-h="center"
        >
          <b-col
            cols="12"
            md="12"
          >
            <div
              class="d-flex flex-column align-items-center justify-content-center"
            >
              <svg-icon
                size="70"
                type="mdi"
                :path="mdiAlertCircle"
              />
              <span
                class="my-1 fs-1 font-weight-bold"
              >
                Deseja apagar os produtos selecionados? Esta ação é IRREVERSÍVEL
              </span>
            </div>
          </b-col>
        </b-row>
      </b-modal>
      <b-modal
        id="successDeleteModal"
        ref="refSuccessDeleteModal"
        class="modalStyle"
        no-close-on-backdrop
        no-close-on-esc
        centered
        hide-footer
      >
        <b-form
          class="mt-1"
        >
          <b-row>
            <b-col>
              <span
                class="d-flex justify-content-center px-2 mb-1 layoutModal layoutModalUnsuccess"
              >
                <svg-icon
                  type="mdi"
                  size="70"
                  :style="{'color': '#049F14'}"
                  :path="mdiCheckCircle"
                />
              </span>
              <span
                class="d-flex justify-content-center px-2 my-1 fixLayoutTextModal"
              >
                Itens apagados com sucesso!
              </span>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
      <b-modal
        id="removingProductModal"
        ref="refRemovingProductModal"
        no-close-on-backdrop
        no-close-on-esc
        hide-header
        hide-footer
        centered
      >
        <div class="pt-5 pb-4">
          <div class="d-flex justify-content-center">
            <b-spinner
              id="spinnerRemoving"
              variant="primary"
              type="grow"
            />
          </div>
          <h4
            class="d-flex justify-content-center mt-2 pl-50"
          >
            Removendo...
          </h4>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BModal,
  BForm,
  BFormCheckbox,
  BFormInput,
  BFormGroup,
  BFormInvalidFeedback,
  BSpinner,
  VBTooltip,
  VBModal,
} from 'bootstrap-vue'

import {
  mdiPlus,
  mdiAlphaUBoxOutline,
  mdiAlphaCBox,
  mdiAlphaLCircleOutline,
  mdiAlertCircle,
  mdiCheckCircle,
} from '@mdi/js'

import {
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate'

import {
  useToast,
} from 'vue-toastification/composition'

import SvgIcon from '@jamescoyle/vue-icon'
import formValidation from '@core/comp-functions/forms/form-validation'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BiddingProduct from '@/views/bidding/components/BiddingProduct.vue'
import BiddingProductCompound from '@/views/bidding/components/BiddingProductCompound.vue'
import BiddingProductLote from '@/views/bidding/components/BiddingProductLote.vue'
import formatCurrency from '@/components/utils/filters/formatCurrency'
import syslic from '@/syslic'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BModal,
    BForm,
    BFormCheckbox,
    BFormInput,
    BFormGroup,
    BFormInvalidFeedback,
    BSpinner,
    SvgIcon,
    BiddingProduct,
    BiddingProductCompound,
    BiddingProductLote,
    ValidationObserver,
    ValidationProvider,
  },
  filters: {
    formatCurrency,
  },
  directives: {
    VBModal,
    'b-tooltip': VBTooltip,
  },
  props: {
    bidding: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    const TIMEOUT_REFRESH = 2000

    const itemBlank = {
      type: '',
      parent: '',
      product_list: [],
      number: '',
      name: '',
      quantity: '',
      description: '',
      cost: '',
      price: '',
      price_min: '',
      freight: '',
      fob_freight: '',
      tax: '',
      fixed_cost: '',
      margin_min: 0,
      meta: {
        isOpen: true,
      },
      result: 'pending',
      observation: '',
    }

    const loteBlank = {
      name: '',
    }

    const itemsToRemove = []
    const indexToRemove = []

    const duplicatedLoteItems = []

    const checkedProducts = new Map()

    const checkAllProducts = false

    const isCalculating = false

    const total = 0
    const totalMin = 0

    const items = []
    const lote = JSON.parse(JSON.stringify(loteBlank))
    const indexItem = []

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(this.lote)

    const addingProduct = false
    const loadingList = false
    const showHeader = true
    const waitRefresh = false

    const loteOpen = ''

    return {
      TIMEOUT_REFRESH,
      duplicatedLoteItems,
      checkedProducts,
      checkAllProducts,
      itemBlank,
      itemsToRemove,
      indexToRemove,
      items,
      lote,
      loteBlank,
      isCalculating,
      total,
      totalMin,
      indexItem,
      addingProduct,
      loadingList,
      loteOpen,
      showHeader,
      waitRefresh,
      refFormObserver,
      getValidationState,
    }
  },
  watch: {
    'bidding.items_set': function updateItems(val) {
      if (val) {
        this.items = val
      }
    },
    items: {
      handler: function updateItems() {
        this.refreshItems()
      },
      deep: true,
    },
  },
  created() {
    this.showHeader = true

    if (Object.hasOwn(this.bidding, 'items_set')) {
      this.items = this.bidding.items_set
    }
  },
  methods: {
    getBasicItem(type) {
      const newItem = JSON.parse(JSON.stringify(this.itemBlank))
      newItem.bidding = this.bidding.id
      newItem.type = type
      return newItem
    },
    addProductUnit() {
      this.$refs.modalAddProduct.hide()
      const newItem = this.getBasicItem('unit')
      newItem.product_list = [
        {
          product: '',
          quantity: '',
          price: '',
          product_set: '',
        },
      ]

      this.items.push(newItem)
    },
    addProductCompound() {
      this.$refs.modalAddProduct.hide()
      const newItem = this.getBasicItem('compound')
      this.items.push(newItem)
    },
    addProductLote() {
      this.$refs.modalAddProduct.hide()
      this.$refs.refModalAddLote.show()
    },
    addLoteCard() {
      const newItem = this.getBasicItem('lote')
      newItem.name = this.lote.name
      newItem.quantity = 0
      newItem.cost = 0
      newItem.price = 0
      newItem.price_min = 0
      newItem.freight = 0
      newItem.tax = 0
      newItem.fixed_cost = 0

      delete newItem.number

      this.lote = JSON.parse(JSON.stringify(this.loteBlank))

      syslic
        .bidding
        .item
        .addItem(newItem)
        .then(response => {
          newItem.id = response.data.id
          this.items.push(newItem)
          this.$refs.refModalAddLote.hide()

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Lote adicionado com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(err => {
          let textError = 'Não foi possível adicionar o lote da licitação, por favor entre em contato com o administrador do sistema.'
          let style = 'error'

          if (err.data.length) {
            if (err.data[0].includes('The fields bidding, number must make a unique set.')) {
              textError = 'Não foi possível adicionar este lote, pois já existe um outro produto ou lote cadastrado com o mesmo número nesta licitação.'
              style = 'warning'
            }
          }

          this.$swal({
            title: 'Atenção!',
            text: textError,
            icon: style,
            showConfirmButton: false,
            timer: 10000,
          })
        })
    },
    addDuplicatedLote(lote, loteItems) {
      const loteItemsCopy = JSON.parse(JSON.stringify(loteItems))
      const data = JSON.parse(JSON.stringify(lote))
      delete data.id

      syslic
        .bidding
        .item
        .addItem(data)
        .then(response => {
          data.id = response.data.id
          this.duplicatedLoteItems.push(data)

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Lote duplicado com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })

          this.createParentItems(data, loteItemsCopy)
        })
        .catch(err => {
          let textError = 'Não foi possível adicionar o lote da licitação, por favor entre em contato com o administrador do sistema.'
          let style = 'error'

          if (err.data.length) {
            if (err.data[0].includes('The fields bidding, number must make a unique set.')) {
              textError = 'Não foi possível adicionar este lote, pois já existe um outro produto ou lote cadastrado com o mesmo número nesta licitação.'
              style = 'warning'
            }
          }

          this.$swal({
            title: 'Atenção!',
            text: textError,
            icon: style,
            showConfirmButton: false,
            timer: 10000,
          })
        })
    },
    async createParentItems(data, loteItems) {
      this.waitRefresh = true

      const itemsToAdd = []
      /* eslint-disable no-param-reassign */
      loteItems.forEach(item => {
        delete item.id
        item.parent = data.id
        item.parent_set = {
          id: data.id,
          type: 'lote',
          name: data.name,
        }

        itemsToAdd.push(() => this.createParentItem(item))
      })
      /* eslint-enable no-param-reassign */

      await Promise.all(itemsToAdd.map(fn => fn()))
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Produtos do lote adicionados com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.$swal({
            title: 'Atenção!',
            text: 'Não foi possível adicionar os produtos do lote duplicado, por favor entre em contato com o administrador do sistema.',
            icon: 'error',
            showConfirmButton: false,
            timer: 10000,
          })
        })
        .finally(() => {
          this.duplicatedLoteItems.forEach(item => {
            this.items.push(item)
          })
          this.duplicatedLoteItems = []
          this.waitRefresh = false
        })
    },
    createParentItem(item) {
      /* eslint-disable no-param-reassign */
      return new Promise((resolve, reject) => {
        syslic
          .bidding
          .item
          .addItem(item)
          .then(res => {
            item.id = res.data.id
            this.duplicatedLoteItems.push(item)
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
      /* eslint-enable no-param-reassign */
    },
    cancelLoteCard() {
      this.lote = JSON.parse(JSON.stringify(this.loteBlank))
      this.$refs.refModalAddLote.hide()
    },
    closeCardProduct() {
      return new Promise((resolve, reject) => {
        const promiseList = []
        this.indexItem.forEach((val, index) => {
          if (index in this.$refs.refBiddingProduct) {
            promiseList.push(this.$refs.refBiddingProduct[index].tryClose())
          }
        })

        Promise.all(promiseList)
          .then(() => {
            this.indexItem.forEach((val, index) => {
              if (index in this.$refs.refBiddingProduct) {
                this.$refs.refBiddingProduct[index].close()
              }
            })
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },
    updateOpen(indexOpen) {
      const promiseList = []
      this.indexItem.forEach((val, index) => {
        if (val !== indexOpen) {
          if (index in this.$refs.refBiddingProduct) {
            promiseList.push(this.$refs.refBiddingProduct[index].tryClose())
          }
        }
      })

      Promise.all(promiseList)
        .then(() => {
          this.indexItem.forEach((val, index) => {
            if (val !== indexOpen) {
              if (index in this.$refs.refBiddingProduct) {
                this.$refs.refBiddingProduct[index].close()
              }
            }
          })

          this.$emit('clear-storage')
          this.checkAllProducts = false
          this.handleCheckAllProducts(false)
        })
        .catch(() => {
          this.$refs.refBiddingProduct[indexOpen].close()
        })
    },
    updateOpenLote(indexOpen) {
      this.loteOpen = indexOpen
      this.updateOpen(indexOpen)
      this.showHeader = false
    },
    updateCloseLote() {
      this.loteOpen = ''
      this.showHeader = true
    },
    isAddingProduct(value) {
      this.addingProduct = value
    },
    refreshIndex() {
      this.indexItem = []
      const listIndex = []

      if (this.items.length > 0) {
        this.items.forEach((element, index) => {
          if (!element.parent) {
            listIndex.push(index)
          }
        })
      }

      this.indexItem = listIndex
    },
    refreshTotal() {
      this.isCalculating = true

      let total = 0
      let totalMin = 0

      if (this.items.length > 0) {
        this.items.forEach(val => {
          total = Number(total) + (Number(val.price) * Number(val.quantity))
          totalMin = Number(totalMin) + (Number(val.price_min) * Number(val.quantity))
          this.checkedProducts.set(val.id, false)
        })
      }

      this.total = total
      this.totalMin = totalMin

      this.isCalculating = false
    },
    refreshItems() {
      this.refreshTotal()
      this.refreshIndex()
    },
    deleteItem(index) {
      const itemsCache = this.items
      const deletedItem = this.items[index]

      this.indexItem = []
      this.items = []
      const indexesToRemove = []

      this.waitRefresh = true

      setTimeout(() => {
        itemsCache.splice(index, 1)

        itemsCache.forEach((elem, i) => {
          if (deletedItem.id === itemsCache[i].parent) {
            indexesToRemove.push(i)
          }
          if (Object.hasOwn(itemsCache[i], 'meta')) {
            if (Object.hasOwn(itemsCache[i].meta, 'isOpen')) {
              itemsCache[i].meta.isOpen = false
            }
          }
        })

        // Precisa do reverso, pois se não tiver, o index não fica correto na exclusão
        indexesToRemove.reverse().forEach(i => {
          itemsCache.splice(i, 1)
        })

        this.items = itemsCache
        this.waitRefresh = false
        this.clearItemsIfEmpty()
      }, this.TIMEOUT_REFRESH)
    },
    deleteItems(indexes) {
      this.waitRefresh = true

      const deletedItems = []
      indexes.forEach(i => {
        deletedItems.push(this.items[i])
      })

      const itemsCache = [...this.items]
      this.indexItem = []
      this.items = []
      const indexesToRemove = []

      setTimeout(() => {
        deletedItems.forEach(item => {
          itemsCache.splice(itemsCache.indexOf(item), 1)

          itemsCache.forEach((elem, i) => {
            if (item.id === itemsCache[i].parent) {
              indexesToRemove.push(i)
            }
            if (Object.hasOwn(itemsCache[i], 'meta')) {
              if (Object.hasOwn(itemsCache[i].meta, 'isOpen')) {
                itemsCache[i].meta.isOpen = false
              }
            }
          })
        })

        indexesToRemove.reverse().forEach(i => {
          itemsCache.splice(i, 1)
        })

        this.items = [...itemsCache]
        this.waitRefresh = false
        this.clearItemsIfEmpty()
      }, this.TIMEOUT_REFRESH)
    },
    toggleCard(isOpen) {
      this.$emit('toggleCard', isOpen)
    },
    onProductCheck(id, checked) {
      if (checked) {
        this.checkedProducts.set(id, checked)
      } else {
        this.checkedProducts.delete(id)
      }
      this.updateCheckAllBox()
      this.$emit('store-check', id, checked)
    },
    updateCheckAllBox() {
      if (this.checkedProducts.size === 0) {
        this.checkAllProducts = false
      } else {
        let someChecked = false
        this.checkedProducts.forEach(value => {
          if (value) {
            someChecked = true
          }
        })
        if (!someChecked) {
          this.checkAllProducts = false
        }
      }
    },
    handleCheckAllProducts(val) {
      Array.from(this.checkedProducts.keys()).forEach(key => {
        const item = this.items.find(i => i.id === key)
        if (item && item.parent !== null) {
          this.checkedProducts.set(key, val)
          this.$emit('store-check', key, val)
        }
      })

      this.indexItem.forEach((_, index) => {
        if (this.$refs.refBiddingProduct) {
          if (index in this.$refs.refBiddingProduct) {
            this.$refs.refBiddingProduct[index].changeCheck(val)
          }
        }
      })
    },
    haveGainOrLost() {
      this.itemsToRemove = []
      this.indexToRemove = []

      let haveGainOrLost = false

      this.checkedProducts.forEach((value, key) => {
        if (value && key) {
          this.itemsToRemove.push(key)
        }
      })

      this.indexItem.forEach(i => {
        const item = this.items[i]
        if (this.itemsToRemove.includes(item.id)) {
          this.indexToRemove.push(i)
          if (item.result === 'gain' || item.result === 'lost') {
            haveGainOrLost = true
          }
        }
      })

      if (haveGainOrLost) {
        this.$refs.refModalGainOrLost.show()
      } else {
        this.$refs.refModalConfirmDeleteSelected.show()
      }
    },
    removeItem(id) {
      return new Promise((resolve, reject) => {
        syslic
          .bidding
          .item
          .deleteItem(id)
          .then(() => {
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },
    async removeItems() {
      this.$refs.refRemovingProductModal.show()

      const removeArray = []

      this.itemsToRemove.forEach(id => {
        removeArray.push(() => this.removeItem(id))
      })

      await Promise.all(removeArray.map(fn => fn()))
        .then(() => {
          this.deleteItems(this.indexToRemove)
        })
        .then(() => {
          setTimeout(() => {
            this.checkAllProducts = false
            this.checkedProducts.clear()
            this.$emit('clear-storage')
            this.bidding.items_set = this.items
            this.$refs.refRemovingProductModal.hide()
            this.$refs.refSuccessDeleteModal.show()
          }, this.TIMEOUT_REFRESH)
        })
        .catch(() => {
          this.$refs.refRemovingProductModal.hide()
          this.$swal({
            title: 'Falha ao remover produtos!',
            text: 'Não foi possível remover os produtos selecionados, por favor entre em contato com o administrador do sistema.',
            icon: 'error',
            showConfirmButton: false,
            timer: 10000,
          })
        })
    },
    updateItemType(item, type) {
      this.waitRefresh = true

      const itemIndex = this.items.indexOf(item)
      const itemCache = JSON.parse(JSON.stringify(this.items[itemIndex]))
      itemCache.type = type

      this.items.splice(itemIndex, 1, itemCache)

      setTimeout(() => {
        this.waitRefresh = false
      }, this.TIMEOUT_REFRESH)
    },
    refreshAfterHomologate() {
      this.waitRefresh = true

      this.refreshItems()

      setTimeout(() => {
        this.waitRefresh = false
      }, this.TIMEOUT_REFRESH)
    },
    clearItemsIfEmpty() {
      if (this.items.length === 0) {
        this.$emit('clearItems')
      }
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      mdiPlus,
      mdiAlphaUBoxOutline,
      mdiAlphaCBox,
      mdiAlphaLCircleOutline,
      mdiAlertCircle,
      mdiCheckCircle,
    }
  },
}
</script>

<style lang="scss">

#idModalAddLote .modal-footer {
  display: flex !important;
  flex-direction: row-reverse !important;
  justify-content: end !important;

  .btn{
    min-width: 107px;
  }
}

#biddingCardItemPrice {
  border: 1px solid #ebe9f1;
}

.biddingCardItemBlock .media{
  margin-bottom: 0.5rem;
}

.biddingCardItemBlock .media-body{
  color: #5e5873;
  margin-top: 0.5rem;
}

.biddingCardItemBlock.left {
  border-left: 1px solid #ebe9f1;
}

.biddingCardItemSectionTitle {
  font-weight: 600;
  font-size: 1rem;
  color: #005C96;
  margin-top: 0.75rem;
}

.biddingCardItemSectionContent h5{
  font-weight: 600;
  font-size: 1.15rem;
}

#biddingProductListHeader {
  background-color: #E3EDF3;
  border-bottom: 1px solid #ebe9f1;
}

.biddingProductListItem {
  border-bottom: 1px solid #ebe9f1;
}

.biddingProductListItem.itemOdd {
  background-color: #F6F6F9;
}

#biddingProductEmpty {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

#emptyText {
  color: #a3a3a3;
  font-weight: 500;
  font-size: 1.45rem;
}

#biddingProductList {
  min-height: 28.5rem;
  position: relative;
  padding-bottom: 4.5rem;
}

#biddingProductAddRow {
  position: absolute;
  bottom: 0px;
  right: 0px;
}
</style>

<style lang="scss">
@import "@core/scss/base/core/colors/palette-variables.scss";

#idModalAddProduct .modal-title {
  color: $primary;
  font-weight: 600;
  font-size: 1.75rem;
  text-align: center;
  width: 100%;
}

#idModalAddProduct .modal-header {
  border-radius: 2rem 2rem 0 0;
  padding-top: 1.5rem;
  padding-bottom: 1.25rem;
  background-color: #f6f6f9 !important;
}

#idModalAddProduct .modal-header .close {
  position: absolute;
  right: 2%;
  top: 2%;
  font-size: 3rem;
  padding: 0.4rem 1rem;
}

.addProductBtn {
  background-color: #eaeef5 !important;
  border: 0;
  border-radius: .8rem !important;
  color: $primary !important;
  font-size: 1rem;
  min-width: 90%;
  min-height: 8.5rem;
}

.btn-label {
  font-weight: 500;
  color: #767676;
}

#idModalAddProduct .modal-content {
  border-radius: 2rem !important;
}

@media screen and (max-width: 768px) {
  #idModalAddProduct .modal-header .close {
    top: 0%;
    right: 4%;
  }
}

#idModalConfirmDeleteSelected {
  .modal-body {
    span {
      text-align: center;
      font-size: 1.35rem;
    }

    svg {
      color: #FF7A00;
    }
  }

  .modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;

    padding-bottom: 1rem;

    button {
      min-width: 8rem;
    }
  }
}

.spinner-loading {
  margin-left: 15px;
  margin-right: 15px;
}
</style>
