<template>
  <validation-provider
    ref="inputRef"
    #default="{ errors }"
    name="product"
    :rules="{
      required: requiredField,
    }"
  >
    <b-form-group
      :label="label"
      label-for="product"
    >
      <vue-autosuggest
        v-model="productSearch"
        no-filter
        :suggestions="productsOptions"
        :input-props="{
          id: 'product',
          ref: 'product',
          class: errors.length > 0 ? 'form-control is-invalid' : 'form-control',
          placeholder: readonly ? '' : placeholder,
          readonly: readonly,
        }"
        :limit="limitElements"
        :class="errors.length > 0 ? 'error-select' : ''"
        :get-suggestion-value="getProductSelected"
        @input="onInputProductChange"
        @selected="onSelectedProduct"
        @blur="onBlur"
      >
        <template slot-scope="{suggestion}">
          <span v-if="hasProducts">{{ suggestion.item.name }} - R${{ formatCurrency(suggestion.item.price) }}</span>
          <span
            v-else
            :style="{'heigth': '500px'}"
          >
            .
          </span>
        </template>
        <template slot="after-suggestions">
          <span
            v-if="!hasProducts"
            class="isEmpty d-flex justify-content-center"
          >
            Não há produtos cadastrados.
          </span>
        </template>
      </vue-autosuggest>
      <small
        v-if="errors.length > 0"
        class="text-danger"
      >
        {{ errorLabel }}
      </small>
    </b-form-group>
  </validation-provider>
</template>
<script>
import { VueAutosuggest } from 'vue-autosuggest'
import {
  BFormGroup,
} from 'bootstrap-vue'
import {
  ValidationProvider,
} from 'vee-validate'
import { required } from '@validations'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import syslic from '@/syslic'

export default {
  components: {
    BFormGroup,
    VueAutosuggest,
    ValidationProvider,
  },
  model: {
    prop: 'productValue',
    event: 'productChange',
  },
  props: {
    productValue: {
      type: [Object, String],
      required: true,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    requiredField: {
      type: Boolean,
      required: false,
      default: false,
    },
    filterTypes: {
      type: Array,
      required: false,
      default: () => [],
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Produto',
    },
    label: {
      type: String,
      required: false,
      default: 'Produto',
    },
    errorLabel: {
      type: String,
      required: false,
      default: 'O campo Produto é obrigatório',
    },
  },
  data() {
    const productTemplate = {
      name: '',
      description: '',
      brand: '',
      model: '',
      price: 0,
      anvisa: '',
      code: '',
      link_supplier: '',
      supplier: null,
      supplier_set: null,
      warranty: null,
      warranty_set: null,
      unity: null,
      unity_set: null,
      classifier: null,
      classifier_set: null,
      type: null,
      type_set: null,
      expiration_date: null,
      weight: null,
      lenght: null,
      width: null,
      height: null,
      get_price: 0,
    }

    const productsOptions = []
    const hasProducts = false

    return {
      limitElements: 20,
      productTemplate,
      productsOptions,
      hasProducts,
      required,
      productSearch: '',
    }
  },
  computed: {
    product: {
      get() {
        return this.productValue
      },
      set(value) {
        this.$emit('productChange', value)
      },
    },
  },
  watch: {
    productValue: function updateProduct(product) {
      if (product === '') {
        this.productSearch = ''
      } else {
        this.productSearch = this.getProductSelected({ item: product })
      }
    },
    productSearch: function updateSearch(search) {
      if (search === '') {
        this.product = ''
      }
    },
  },
  created() {
    if (this.productValue) {
      this.productSearch = this.getProductSelected({ item: this.product })
    }

    this.hasProduct()
  },
  methods: {
    hasProduct() {
      syslic
        .product
        .item
        .fetchItemsList({
          search: '',
          p: 1,
          page_size: 5,
          item_type: this.filterTypes,
        })
        .then(response => {
          this.hasProducts = response.results > 0
          this.$emit('ready', true)
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao ler os dados.',
              text: 'Não foi possível ler os dados do servidor, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
          this.$emit('ready', true)
        })
    },
    onSelectedProduct(selected) {
      if (selected === '' || selected === undefined) {
        return
      }
      this.product = selected.item
      this.$emit('selected', selected.item)
    },
    getProductSelected(suggestion) {
      return `${suggestion.item.name}`
    },
    onInputProductChange(text) {
      syslic
        .product
        .item
        .fetchItemsList({
          search: text,
          p: 1,
          page_size: this.limitElements,
          item_type: this.filterTypes,
        })
        .then(response => {
          if (response.results.length > 0) {
            this.hasProducts = true
            this.productsOptions = [{
              data: response.results,
            }]
          } else {
            this.hasProducts = false
            this.productsOptions = [
              { data: [this.productTemplate] },
            ]
          }
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao ler os dados.',
              text: 'Não foi possível ler os dados do servidor, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    clean() {
      this.productSearch = ''
    },
    onBlur() {
      if (this.product) {
        const productSelected = this.getProductSelected({ item: this.product })
        if (this.productSearch !== productSelected) {
          this.product = ''
        }
      } else {
        this.productSearch = ''
      }
    },
    formatCurrency(value) {
      return value.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
.isEmpty {
  position: absolute;
  width: 100%;
  padding-top: 1.5%;
  top: 0;
}
</style>
