<template>
  <div id="biddingEditPage">
    <b-modal
      id="idModalUpdateBid"
      ref="modalUpdateBid"
      no-close-on-backdrop
      no-close-on-esc
      hide-header
      hide-footer
      centered
    >
      <div class="pt-5 pb-4">
        <div class="d-flex justify-content-center">
          <b-spinner
            id="spinnerImport"
            variant="primary"
            type="grow"
          />
        </div>
        <h4
          class="d-flex justify-content-center mt-2 pl-50"
        >
          {{ textLoading }}
        </h4>
      </div>
    </b-modal>
    <div v-show="!isLoaded">
      <spin-loader />
    </div>
    <div v-show="isLoaded">
      <messager
        v-if="!isCreateMode"
        :module="'bidding'"
        :thread="data.id"
        :header="data.client_set.name"
        @ready="syncLoad.ready('messager')"
      />
      <b-row class="justify-content-center">
        <b-col
          cols="12"
          md="11"
        >
          <b-row>
            <b-col
              class="d-flex align-items-center justify-content-end mb-1"
            >
              <b-dropdown
                id="actions"
                variant="primary"
                no-carret
                right
                class="d-flex align-items-center dropdown-icon-wrapper d-inline"
              >
                <template #button-content>
                  <feather-icon
                    icon="SettingsIcon"
                    size="16"
                    class="align-middle"
                  />
                </template>

                <b-dropdown-item
                  id="idFiledBtn"
                  @click="showConfirmFiledBidModal()"
                >
                  <svg-icon
                    type="mdi"
                    size="100"
                    :style="{'height': '24px', 'width': '23px', 'margin-left': '-3px'}"
                    :path="data.is_filed ? mdiArchiveArrowUpOutline : mdiArchiveArrowDownOutline"
                  />
                  <span
                    v-if="!data.is_filed"
                    class="align-middle"
                    :style="{'margin-left': '15px'}"
                  >
                    Arquivar
                  </span>
                  <span
                    v-else
                    class="align-middle"
                    :style="{'margin-left': '15px'}"
                  >
                    Desarquivar
                  </span>
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="data.items_set.length > 0"
                  id="idExportBtn"
                  @click="showModaEffect()"
                >
                  <svg-icon
                    type="mdi"
                    size="100"
                    :style="{'height': '24px', 'width': '23px', 'margin-left': '-3px'}"
                    :path="mdiFileExportOutline"
                  />
                  <span
                    class="align-middle"
                    :style="{'margin-left': '15px'}"
                  >
                    Exportar - Effecti
                  </span>
                </b-dropdown-item>

                <b-dropdown-item
                  id="idClassificationGridBtn"
                  :disabled="!isPlatformSupported"
                  :to="{ name: 'bidding-classification-grid', params: { id: biddingID } } "
                >
                  <svg-icon
                    type="mdi"
                    size="100"
                    :style="{'height': '24px', 'width': '23px', 'margin-left': '-3px'}"
                    :path="mdiPodium"
                  />
                  <span
                    class="align-middle"
                    :style="{'margin-left': '15px'}"
                  >
                    Grade Classificatória
                  </span>
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="!data.is_homologated"
                  id="idHomologationBtn"
                  :disabled="!hasPermissionToUpdate"
                  @click="showModalHomologation()"
                >
                  <svg-icon
                    type="mdi"
                    size="100"
                    :style="{'height': '24px', 'width': '23px', 'margin-left': '-3px'}"
                    :path="mdiFileCertificateOutline"
                  />
                  <span
                    class="align-middle"
                    :style="{'margin-left': '15px'}"
                  >
                    Homologar
                  </span>
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="!data.is_homologated"
                  id="idRemoveBtn"
                  :disabled="!hasPermissionToDelete"
                  @click="showConfirmDeleteBidModal()"
                >
                  <svg-icon
                    type="mdi"
                    size="18"
                    :path="mdiTrashCanOutline"
                  />
                  <span class="align-middle ml-50">Remover</span>
                </b-dropdown-item>
              </b-dropdown>
            </b-col>
          </b-row>
          <b-row v-if="isLoaded">
            <b-col>
              <b-card
                v-if="!isCreateMode"
                no-body
                class="d-flex align-items-stretch margin-bottom-1"
              >
                <b-card-title
                  class="pl-1 m-0"
                >
                  <b-row
                    align-v="center"
                    class="d-flex align-items-stretch"
                  >
                    <b-col
                      cols="12"
                      sm="8"
                    >
                      <b-row
                        v-if="data.client_set"
                        class="mt-2 ml-50"
                      >
                        <b-col>
                          <b-row>
                            <b-col>
                              <span id="biddingHeaderTitle">{{ data.client_set.name }}</span>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <b-row
                        v-else
                        class="mt-50 ml-50"
                      >
                        <b-col>
                          <span id="biddingHeaderTitle">Nova Licitação</span>
                        </b-col>
                      </b-row>
                      <b-row class="mb-1 ml-50">
                        <b-col>
                          <b-button
                            v-if="data.items_set.length > 0 && data.is_homologated"
                            id="btnExportEffect"
                            class="btn-icon pl-0 mr-50"
                            variant="outline-primary"
                            size="sm"
                            @click="showModaEffect()"
                          >
                            <svg-icon
                              class="mr-50"
                              type="mdi"
                              size="21"
                              :path="mdiFileExportOutline"
                            />
                            <span class="d-inline">Exportar - Effecti</span>
                          </b-button>
                          <b-link
                            v-if="data.link_trade"
                            :href="data.link_trade"
                          >
                            <small>
                              <feather-icon
                                icon="Link2Icon"
                                size="18"
                                class="align-middle mr-50"
                              />
                              <span id="biddingLinkTrade">{{ data.link_trade | truncate(30, '...') }}</span>
                            </small>
                          </b-link>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col
                      cols="12"
                      sm="4"
                      class="d-flex align-items-stretch justify-content-end text-right"
                    >
                      <div
                        v-if="data.is_homologated"
                        class="d-flex justify-content-start"
                      >
                        <b-button
                          id="btnUndoHomologation"
                          class="d-flex flex-column align-items-center btn-icon px-1"
                          variant="outline-light"
                          size="sm"
                          :disabled="!hasPermissionToUpdate"
                          @click="showModalUndoHomologation()"
                        >
                          <svg-icon
                            id="icon-btn-homologated"
                            class="m-50"
                            type="mdi"
                            size="40"
                            :path="mdiFileCertificateOutline"
                          />
                          <span class="d-inline mb-50">Licitação</span>
                          <span class="d-inline mb-50">Homologada</span>
                        </b-button>
                      </div>
                    </b-col>
                  </b-row>
                </b-card-title>
              </b-card>
              <b-card
                class="pt-0 pl-0 pr-0 ml-0 mr-0 margin-bottom-1"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="2"
                    class="biddingHeaderBlock biddingHeaderSection"
                  >
                    <span
                      class="biddingHeaderSectionTitle"
                    >
                      UASG/OC:
                    </span>
                    <b-media
                      class="biddingHeaderSectionContent"
                    >
                      <b-row>
                        <b-col>
                          <h5
                            v-if="data.uasg"
                            class="mb-0 d-flex"
                          >
                            {{ data.uasg | truncate(8, '...') }}
                          </h5>
                          <h5
                            v-else
                            class="mb-0 d-flex"
                          >
                            -
                          </h5>
                        </b-col>
                      </b-row>
                    </b-media>
                  </b-col>
                  <b-col
                    cols="12"
                    md="2"
                    class="biddingHeaderBlock biddingHeaderSection"
                  >
                    <span
                      class="biddingHeaderSectionTitle"
                    >
                      Nº PREGÃO:
                    </span>
                    <b-media
                      class="biddingHeaderSectionContent"
                    >
                      <b-row>
                        <b-col>
                          <h5
                            v-if="data.trade_number"
                            class="mb-0 d-flex"
                          >
                            {{ data.trade_number | truncate(8, '...') }}
                          </h5>
                          <h5
                            v-else
                            class="mb-0 d-flex"
                          >
                            -
                          </h5>
                        </b-col>
                      </b-row>
                    </b-media>
                  </b-col>
                  <b-col
                    cols="12"
                    md="2"
                    class="biddingHeaderBlock biddingHeaderSection"
                  >
                    <span
                      class="biddingHeaderSectionTitle"
                    >
                      TIPO:
                    </span>
                    <b-media
                      class="biddingHeaderSectionContent"
                    >
                      <b-row>
                        <b-col>
                          <h5
                            v-if="data.type_set"
                            class="mb-0 d-flex"
                          >
                            {{ data.type_set.name | truncate(10, '...') }}
                          </h5>
                          <h5
                            v-else
                            class="mb-0 d-flex"
                          >
                            -
                          </h5>
                        </b-col>
                      </b-row>
                    </b-media>
                  </b-col>
                  <b-col
                    cols="12"
                    md="2"
                    class="biddingHeaderBlock biddingHeaderSection"
                  >
                    <span
                      class="biddingHeaderSectionTitle"
                    >
                      MODALIDADE:
                    </span>
                    <b-media
                      class="biddingHeaderSectionContent"
                    >
                      <b-row>
                        <b-col>
                          <h5
                            v-if="data.modality_set"
                            class="mb-0 d-flex"
                          >
                            {{ data.modality_set.name | truncate(8, '...') }}
                          </h5>
                          <h5
                            v-else
                            class="mb-0 d-flex"
                          >
                            -
                          </h5>
                        </b-col>
                      </b-row>
                    </b-media>
                  </b-col>
                  <b-col
                    cols="12"
                    md="2"
                    class="biddingHeaderBlock biddingHeaderSection"
                  >
                    <span
                      class="biddingHeaderSectionTitle"
                    >
                      PLATAFORMA:
                    </span>
                    <b-media
                      class="biddingHeaderSectionContent"
                    >
                      <b-row>
                        <b-col>
                          <h5
                            v-if="data.platform_set"
                            class="mb-0 d-flex"
                          >
                            {{ data.platform_set.name | truncate(10, '...') }}
                          </h5>
                          <h5
                            v-else
                            class="mb-0 d-flex"
                          >
                            -
                          </h5>
                        </b-col>
                      </b-row>
                    </b-media>
                  </b-col>
                </b-row>
                <hr class="mt-50 mb-50">
                <b-row>
                  <b-col
                    cols="12"
                    md="2"
                    class="biddingHeaderBlock biddingHeaderSection"
                  >
                    <span
                      class="biddingHeaderSectionTitle"
                    >
                      INTERESSE:
                    </span>
                    <b-media
                      class="biddingHeaderSectionContent"
                    >
                      <b-row>
                        <b-col>
                          <b-badge
                            v-if="data.modality_set"
                            pill
                            class="text-capitalize"
                            :style="{'background-color': data.interest_set.color}"
                          >
                            {{ data.interest_set.name | truncate(10, '...') }}
                          </b-badge>
                          <h5
                            v-else
                            class="mb-0 d-flex"
                          >
                            -
                          </h5>
                        </b-col>
                      </b-row>
                    </b-media>
                  </b-col>
                  <b-col
                    cols="12"
                    md="2"
                    class="biddingHeaderBlock biddingHeaderSection"
                  >
                    <span
                      class="biddingHeaderSectionTitle"
                    >
                      FASE:
                    </span>
                    <b-media
                      class="biddingHeaderSectionContent"
                    >
                      <b-row>
                        <b-col>
                          <b-badge
                            v-if="data.phase_set"
                            pill
                            class="text-capitalize"
                            :style="{'background-color': data.phase_set.color}"
                          >
                            <span class="d-xl-flex d-none">{{ data.phase_set.name | truncate(18, '...') }}</span>
                            <span class="d-lg-flex d-xl-none d-sm-none d-none">{{ data.phase_set.name | truncate(14, '...') }}</span>
                            <span class="d-md-flex d-lg-none d-sm-none d-none">{{ data.phase_set.name | truncate(20, '...') }}</span>
                            <span class="d-md-none">{{ data.phase_set.name | truncate(20, '...') }}</span>
                          </b-badge>
                          <h5
                            v-else
                            class="mb-0 d-flex"
                          >
                            -
                          </h5>
                        </b-col>
                      </b-row>
                    </b-media>
                  </b-col>
                  <b-col
                    cols="12"
                    md="2"
                    class="biddingHeaderBlock biddingHeaderSection"
                  >
                    <span
                      class="biddingHeaderSectionTitle"
                    >
                      STATUS:
                    </span>
                    <b-media
                      class="biddingHeaderSectionContent"
                    >
                      <b-row>
                        <b-col>
                          <b-badge
                            v-if="data.status_set"
                            pill
                            class="text-capitalize"
                            :style="{'background-color': data.status_set.color}"
                          >
                            {{ data.status_set.name | truncate(10, '...') }}
                          </b-badge>
                          <h5
                            v-else
                            class="mb-0 d-flex"
                          >
                            -
                          </h5>
                        </b-col>
                      </b-row>
                    </b-media>
                  </b-col>
                  <b-col
                    cols="12"
                    md="3"
                  >
                    <span
                      class="biddingHeaderSectionTitle"
                    >
                      RESPONSÁVEL:
                    </span>
                    <b-media
                      v-if="data.owner_set"
                      class="align-items-center"
                    >
                      <template #aside>
                        <b-avatar
                          id="biddingOwnerImage"
                          :src="ownerImage"
                          size="2.5rem"
                        />
                      </template>
                      <b-row class="mt-50">
                        <b-col>
                          <h5 class="mb-0 d-flex">
                            {{ data.owner_set.first_name }}
                          </h5>
                        </b-col>
                      </b-row>
                    </b-media>
                    <h5
                      v-else
                      class="mb-0 d-flex"
                    >
                      -
                    </h5>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <validation-observer
                v-slot="{ invalid }"
                ref="refFormObserver"
              >
                <b-form>
                  <b-row class="justify-content-center">
                    <b-col
                      cols="12"
                      md="12"
                    >
                      <div
                        class="d-inline-block w-100"
                      >
                        <b-card no-body>
                          <b-tabs
                            class="biddingTab"
                            card
                            @activate-tab="activeBiddingForm"
                          >
                            <b-tab
                              :active="activeTab === dataTab.bidding"
                              @click="selectedTab(dataTab.bidding)"
                            >
                              <template #title>
                                <b-card-title>
                                  <svg-icon
                                    class="mr-50 biddingIconTitle"
                                    type="mdi"
                                    :path="mdiClipboardTextOutline"
                                  />
                                  <span class="d-inline">Licitação</span>
                                </b-card-title>
                              </template>
                              <b-row>
                                <b-col
                                  cols="12"
                                >
                                  <div class="biddingContentSection">
                                    <b-row>
                                      <b-col
                                        cols="12"
                                        md="4"
                                      >
                                        <client-select
                                          id="client"
                                          v-model="data.client_set"
                                          :readonly="isViewMode"
                                          :required-field="true"
                                          @ready="syncLoad.ready('client')"
                                        />
                                      </b-col>
                                      <b-col
                                        cols="12"
                                        md="4"
                                      >
                                        <platform-select
                                          id="platform"
                                          v-model="data.platform_set"
                                          :readonly="isViewMode"
                                          :required-field="true"
                                          @ready="syncLoad.ready('platform')"
                                        />
                                      </b-col>
                                      <b-col
                                        cols="12"
                                        md="4"
                                      >
                                        <owner-select
                                          id="owner"
                                          v-model="data.owner_set"
                                          :readonly="isViewMode"
                                          :required-field="true"
                                          :only-active="true"
                                          @ready="syncLoad.ready('owner')"
                                        />
                                      </b-col>
                                    </b-row>
                                    <b-row>
                                      <b-col
                                        cols="12"
                                        md="3"
                                      >
                                        <validation-provider
                                          v-slot="{ errors }"
                                          name="date"
                                          :rules="{
                                            required: false,
                                          }"
                                        >
                                          <b-form-group
                                            label="Data da Disputa"
                                            label-for="date"
                                          >
                                            <b-form-datepicker
                                              id="date"
                                              v-model="data.date"
                                              :required="true"
                                              locale="pt-BR"
                                              :class="errors.length > 0 ? 'form-control is-invalid' : 'form-control'"
                                              size="md"
                                              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                              label-no-date-selected="Nenhuma data foi selecionada"
                                              :disabled="isViewMode"
                                              selected-variant="primary"
                                              placeholder="Selecione uma data"
                                              label-help="Use o teclado ou o mouse para navegar pelas datas do calendário"
                                              reset-button
                                              reset-button-variant="info"
                                              label-reset-button="Limpar"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                          </b-form-group>
                                        </validation-provider>
                                      </b-col>
                                      <b-col
                                        cols="12"
                                        md="3"
                                      >
                                        <validation-provider
                                          v-slot="{ errors }"
                                          name="bidding_hour"
                                          :rules="{
                                            required: false,
                                          }"
                                        >
                                          <b-form-group
                                            label="Hora da Disputa"
                                            label-for="bidding_hour"
                                          >
                                            <date-picker
                                              id="bidding_hour"
                                              v-model="data.bidding_hour"
                                              class="w-100 date_field"
                                              :class="errors.length > 0 ? 'is-invalid' : ''"
                                              type="time"
                                              format="HH:mm"
                                              title-format="HH:mm"
                                              time-title-format="HH:mm"
                                              value-type="format"
                                              lang="pt-br"
                                              placeholder="Hora da Disputa"
                                              :editable="isViewMode"
                                              :disabled="isViewMode"
                                              :show-hour="true"
                                              :show-minute="true"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                          </b-form-group>
                                        </validation-provider>
                                      </b-col>
                                      <b-col
                                        cols="12"
                                        md="3"
                                      >
                                        <validation-provider
                                          #default="validationContext"
                                          name="trade_number"
                                          :rules="{
                                            required: true,
                                            regex:/[0-9\-/]+$/
                                          }"
                                        >
                                          <b-form-group
                                            label="Nº Pregão"
                                            label-for="trade_number"
                                          >
                                            <b-form-input
                                              id="trade_number"
                                              v-model="data.trade_number"
                                              :state="isViewMode ? null : getValidationState(validationContext)"
                                              name="trade_number"
                                              :placeholder="isViewMode ? '' : 'Nº Pregão'"
                                              :readonly="isViewMode"
                                            />
                                            <b-form-invalid-feedback>
                                              {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                          </b-form-group>
                                        </validation-provider>
                                      </b-col>
                                      <b-col
                                        cols="12"
                                        md="3"
                                      >
                                        <validation-provider
                                          #default="validationContext"
                                          name="uasg"
                                          :rules="{
                                            required: true,
                                            regex:/^([0-9]+)$/
                                          }"
                                        >
                                          <b-form-group
                                            label="UASG/OC"
                                            label-for="uasg"
                                          >
                                            <b-form-input
                                              id="uasg"
                                              v-model="data.uasg"
                                              :state="isViewMode ? null : getValidationState(validationContext)"
                                              name="uasg"
                                              type="number"
                                              :placeholder="isViewMode ? '' : 'UASG/OC'"
                                              :readonly="isViewMode"
                                            />
                                            <b-form-invalid-feedback>
                                              {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                          </b-form-group>
                                        </validation-provider>
                                      </b-col>
                                    </b-row>
                                    <b-row>
                                      <b-col
                                        cols="12"
                                        md="3"
                                      >
                                        <type-select
                                          id="type"
                                          v-model="data.type_set"
                                          :readonly="isViewMode"
                                          :required-field="true"
                                          @ready="syncLoad.ready('type')"
                                        />
                                      </b-col>
                                      <b-col
                                        cols="12"
                                        md="3"
                                      >
                                        <company-select
                                          id="company"
                                          v-model="data.company_set"
                                          :readonly="isViewMode"
                                          :required-field="true"
                                          @ready="syncLoad.ready('company')"
                                        />
                                      </b-col>
                                      <b-col
                                        cols="12"
                                        md="3"
                                      >
                                        <modality-select
                                          id="modality"
                                          v-model="data.modality_set"
                                          :readonly="isViewMode"
                                          :required-field="true"
                                          @ready="syncLoad.ready('modality')"
                                        />
                                      </b-col>
                                      <b-col
                                        cols="12"
                                        md="3"
                                      >
                                        <region-freight-select
                                          id="freight_group"
                                          v-model="data.freight_group_set"
                                          title="Região de Frete"
                                          :readonly="isViewMode"
                                          :required-field="true"
                                          @ready="syncLoad.ready('freightGroup')"
                                        />
                                      </b-col>
                                    </b-row>
                                  </div>
                                  <b-card-title
                                    class="biddingSectionTitle"
                                  >
                                    <span
                                      class="d-inline ml-1"
                                      :style="{'font-size': '1.18rem'}"
                                    >
                                      Endereços e Datas
                                    </span>
                                  </b-card-title>
                                  <div class="biddingContentSection">
                                    <b-row>
                                      <b-col
                                        cols="12"
                                        md="2"
                                      >
                                        <state-select
                                          id="state"
                                          v-model="data.state_set"
                                          :readonly="isViewMode"
                                          :required-field="false"
                                          @ready="syncLoad.ready('state')"
                                        />
                                      </b-col>
                                      <b-col
                                        cols="12"
                                        md="4"
                                      >
                                        <city-select
                                          id="city"
                                          v-model="data.city_set"
                                          :readonly="isViewMode || !data.state"
                                          :required-field="false"
                                          :state="data.state"
                                          @ready="syncLoad.ready('city')"
                                        />
                                      </b-col>
                                      <b-col
                                        cols="12"
                                        md="3"
                                      >
                                        <dispute-select
                                          id="biddingDispute"
                                          v-model="data.dispute_set"
                                          :readonly="isViewMode"
                                          :required-field="true"
                                          @ready="syncLoad.ready('dispute')"
                                        />
                                      </b-col>
                                      <b-col
                                        cols="12"
                                        md="3"
                                      >
                                        <validation-provider
                                          v-slot="{ errors }"
                                          name="date_capture"
                                          :rules="{
                                            required: false,
                                          }"
                                        >
                                          <b-form-group
                                            label="Data da Captação"
                                            label-for="date_capture"
                                          >
                                            <b-form-datepicker
                                              id="date_capture"
                                              v-model="data.date_capture"
                                              :required="true"
                                              locale="pt-BR"
                                              :class="errors.length > 0 ? 'form-control is-invalid' : 'form-control'"
                                              size="md"
                                              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                              label-no-date-selected="Nenhuma data foi selecionada"
                                              :disabled="isViewMode"
                                              selected-variant="primary"
                                              placeholder="Selecione uma data"
                                              label-help="Use o teclado ou o mouse para navegar pelas datas do calendário"
                                              reset-button
                                              reset-button-variant="info"
                                              label-reset-button="Limpar"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                          </b-form-group>
                                        </validation-provider>
                                      </b-col>
                                    </b-row>
                                    <b-row>
                                      <b-col
                                        cols="12"
                                        md="3"
                                      >
                                        <validation-provider
                                          v-slot="{ errors }"
                                          name="date_proposal"
                                          :rules="{
                                            required: false,
                                          }"
                                        >
                                          <b-form-group
                                            label="Data da Proposta"
                                            label-for="date_proposal"
                                          >
                                            <b-form-datepicker
                                              id="date_proposal"
                                              v-model="data.date_proposal"
                                              :required="true"
                                              locale="pt-BR"
                                              :class="errors.length > 0 ? 'form-control is-invalid' : 'form-control'"
                                              size="md"
                                              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                              label-no-date-selected="Nenhuma data foi selecionada"
                                              :disabled="isViewMode"
                                              selected-variant="primary"
                                              placeholder="Selecione uma data"
                                              label-help="Use o teclado ou o mouse para navegar pelas datas do calendário"
                                              reset-button
                                              reset-button-variant="info"
                                              label-reset-button="Limpar"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                          </b-form-group>
                                        </validation-provider>
                                      </b-col>
                                      <b-col
                                        cols="12"
                                        md="3"
                                      >
                                        <validation-provider
                                          v-slot="{ errors }"
                                          name="hour_proposal"
                                          :rules="{
                                            required: false,
                                          }"
                                        >
                                          <b-form-group
                                            label="Hora da Proposta"
                                            label-for="hour_proposal"
                                          >
                                            <date-picker
                                              id="hour_proposal"
                                              v-model="data.hour_proposal"
                                              class="w-100 date_field"
                                              :class="errors.length > 0 ? 'is-invalid' : ''"
                                              type="time"
                                              format="HH:mm"
                                              title-format="HH:mm"
                                              time-title-format="HH:mm"
                                              value-type="format"
                                              lang="pt-br"
                                              placeholder="Hora da Proposta"
                                              :editable="isViewMode"
                                              :disabled="isViewMode"
                                              :show-hour="true"
                                              :show-minute="true"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                          </b-form-group>
                                        </validation-provider>
                                      </b-col>
                                      <b-col
                                        cols="12"
                                        md="3"
                                      >
                                        <validation-provider
                                          v-slot="{ errors }"
                                          name="date_impugnment"
                                          :rules="{
                                            required: false,
                                          }"
                                        >
                                          <b-form-group
                                            label="Data da Impugnação"
                                            label-for="date_impugnment"
                                          >
                                            <b-form-datepicker
                                              id="date_impugnment"
                                              v-model="data.date_impugnment"
                                              :required="true"
                                              locale="pt-BR"
                                              :class="errors.length > 0 ? 'form-control is-invalid' : 'form-control'"
                                              size="md"
                                              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                              label-no-date-selected="Nenhuma data foi selecionada"
                                              :disabled="isViewMode"
                                              selected-variant="primary"
                                              placeholder="Selecione uma data"
                                              label-help="Use o teclado ou o mouse para navegar pelas datas do calendário"
                                              reset-button
                                              reset-button-variant="info"
                                              label-reset-button="Limpar"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                          </b-form-group>
                                        </validation-provider>
                                      </b-col>
                                      <b-col
                                        cols="12"
                                        md="3"
                                      >
                                        <validation-provider
                                          v-slot="{ errors }"
                                          name="date_clarification"
                                          :rules="{
                                            required: false,
                                          }"
                                        >
                                          <b-form-group
                                            label="Data do Esclarecimento"
                                            label-for="date_clarification"
                                          >
                                            <b-form-datepicker
                                              id="date_clarification"
                                              v-model="data.date_clarification"
                                              :required="true"
                                              locale="pt-BR"
                                              :class="errors.length > 0 ? 'form-control is-invalid' : 'form-control'"
                                              size="md"
                                              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                              label-no-date-selected="Nenhuma data foi selecionada"
                                              :disabled="isViewMode"
                                              selected-variant="primary"
                                              placeholder="Selecione uma data"
                                              label-help="Use o teclado ou o mouse para navegar pelas datas do calendário"
                                              reset-button
                                              reset-button-variant="info"
                                              label-reset-button="Limpar"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                          </b-form-group>
                                        </validation-provider>
                                      </b-col>
                                    </b-row>
                                  </div>
                                  <b-card-title
                                    class="biddingSectionTitle"
                                  >
                                    <span
                                      class="d-inline ml-1"
                                      :style="{'font-size': '1.18rem'}"
                                    >
                                      Links e Observações
                                    </span>
                                  </b-card-title>
                                  <div class="biddingContentSection">
                                    <b-row>
                                      <b-col
                                        cols="12"
                                        md="4"
                                      >
                                        <validation-provider
                                          #default="validationContext"
                                          name="link_pncp"
                                        >
                                          <b-form-group
                                            label="Link PNCP"
                                            label-for="link_pncp"
                                          >
                                            <b-form-input
                                              v-if="!isViewMode || isEmpty(data.link_pncp)"
                                              id="link_pncp"
                                              v-model="data.link_pncp"
                                              :state="isViewMode ? null : getValidationState(validationContext)"
                                              name="link_pncp"
                                              :placeholder="isViewMode ? '' : 'Link PNCP'"
                                              :readonly="isViewMode"
                                            />
                                            <a
                                              v-else
                                              class="form-control link-form-control"
                                              :href="data.link_pncp"
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              {{ data.link_pncp }}
                                            </a>
                                            <b-form-invalid-feedback>
                                              {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                          </b-form-group>
                                        </validation-provider>
                                      </b-col>
                                      <b-col
                                        cols="12"
                                        md="4"
                                      >
                                        <validation-provider
                                          #default="validationContext"
                                          name="link_support"
                                        >
                                          <b-form-group
                                            label="Link Captação do Edital"
                                            label-for="link_support"
                                          >
                                            <b-form-input
                                              v-if="!isViewMode || isEmpty(data.link_support)"
                                              id="link_support"
                                              v-model="data.link_support"
                                              :state="isViewMode ? null : getValidationState(validationContext)"
                                              name="link_support"
                                              :placeholder="isViewMode ? '' : 'Link Captação do Edital'"
                                              :readonly="isViewMode"
                                            />
                                            <a
                                              v-else
                                              class="form-control link-form-control"
                                              :href="data.link_support"
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              {{ data.link_support }}
                                            </a>
                                            <b-form-invalid-feedback>
                                              {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                          </b-form-group>
                                        </validation-provider>
                                      </b-col>
                                      <b-col
                                        cols="12"
                                        md="4"
                                      >
                                        <validation-provider
                                          #default="validationContext"
                                          name="link_trade"
                                        >
                                          <b-form-group
                                            label="Link Pregão"
                                            label-for="link_trade"
                                          >
                                            <b-form-input
                                              v-if="!isViewMode || isEmpty(data.link_trade)"
                                              id="link_trade"
                                              v-model="data.link_trade"
                                              :state="isViewMode ? null : getValidationState(validationContext)"
                                              name="link_trade"
                                              :placeholder="isViewMode ? '' : 'Link Pregão'"
                                              :readonly="isViewMode"
                                            />
                                            <a
                                              v-else
                                              class="form-control link-form-control"
                                              :href="data.link_trade"
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              {{ data.link_trade }}
                                            </a>
                                            <b-form-invalid-feedback>
                                              {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                          </b-form-group>
                                        </validation-provider>
                                      </b-col>
                                    </b-row>
                                    <b-row>
                                      <b-col
                                        cols="12"
                                        md="4"
                                      >
                                        <phase-select
                                          id="status"
                                          v-model="data.phase_set"
                                          :readonly="isViewMode"
                                          :required-field="true"
                                          @ready="syncLoad.ready('phase')"
                                        />
                                      </b-col>
                                      <b-col
                                        cols="12"
                                        md="4"
                                      >
                                        <status-select
                                          id="status"
                                          v-model="data.status_set"
                                          :readonly="isViewMode"
                                          :required-field="true"
                                          :filter-phase="data.phase_set"
                                          @ready="syncLoad.ready('status')"
                                        />
                                      </b-col>
                                      <b-col
                                        cols="12"
                                        md="4"
                                      >
                                        <interest-select
                                          id="interest"
                                          v-model="data.interest_set"
                                          :readonly="isViewMode"
                                          :required-field="true"
                                          @ready="syncLoad.ready('interest')"
                                        />
                                      </b-col>
                                    </b-row>
                                    <b-row>
                                      <b-col
                                        cols="12"
                                        md="12"
                                      >
                                        <validation-provider
                                          #default="validationContext"
                                          name="object_bidding"
                                        >
                                          <b-form-group
                                            label="Objeto"
                                            label-for="object_bidding"
                                          >
                                            <b-form-textarea
                                              id="object_bidding"
                                              v-model="data.object_bidding"
                                              trim
                                              :placeholder="isViewMode ? '' : 'Objeto'"
                                              :readonly="isViewMode"
                                            />

                                            <b-form-invalid-feedback>
                                              {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                          </b-form-group>
                                        </validation-provider>
                                      </b-col>
                                    </b-row>
                                  </div>
                                </b-col>
                              </b-row>
                            </b-tab>
                            <b-tab
                              v-if="!isCreateMode && !hasEmptyFields"
                              :active="activeTab === dataTab.additional_data"
                              no-body
                              @click="selectedTab(dataTab.additional_data)"
                            >
                              <template #title>
                                <b-card-title>
                                  <svg-icon
                                    class="mr-50 biddingIconTitle"
                                    type="mdi"
                                    :path="mdiFileDocumentPlusOutline"
                                  />
                                  <span class="d-inline">Dados Adicionais</span>
                                </b-card-title>
                              </template>
                              <b-card-body
                                class="p-0 mb-3"
                                no-body
                              >
                                <bidding-additional-data
                                  v-model="data"
                                  :readonly="isViewMode"
                                  :is-loaded="readyBidding"
                                  @ready="syncLoad.ready('additionalData')"
                                />
                              </b-card-body>
                            </b-tab>
                            <b-tab
                              v-if="!isCreateMode && !hasEmptyFields"
                              :active="activeTab === dataTab.attachments"
                              @click="selectedTab(dataTab.attachments)"
                            >
                              <template #title>
                                <b-card-title>
                                  <svg-icon
                                    class="mr-50 biddingIconTitle"
                                    type="mdi"
                                    :path="mdiPaperclip"
                                  />
                                  <span class="d-inline">Anexos</span>
                                </b-card-title>
                              </template>
                              <b-card-title
                                class="certificateSectionTitle"
                              >
                                <span
                                  class="d-inline ml-1"
                                  :style="{'font-size': '1.18rem'}"
                                >
                                  Anexos da Licitação
                                </span>
                              </b-card-title>
                              <b-card-body
                                class="p-0"
                                no-body
                              >
                                <bidding-attachment
                                  v-model="data.file_set"
                                  :bidding-id="data.id"
                                  :readonly="isViewMode"
                                  :is-create-mode="isCreateMode"
                                />
                                <div
                                  class="divFooter"
                                />
                              </b-card-body>
                              <div
                                class="divSeparation"
                              />
                              <b-card-title
                                class="attachmentSectionTitle"
                              >
                                <span
                                  class="d-inline ml-1"
                                  :style="{'font-size': '1.18rem'}"
                                >
                                  Documentos da Empresa
                                </span>
                              </b-card-title>
                              <b-card-body
                                class="p-0"
                                no-body
                              >
                                <bidding-company-document
                                  :bidding-id="data.id"
                                  :company-id="data.company ? data.company : ''"
                                  :readonly="isViewMode"
                                />
                                <div
                                  class="divFooter"
                                />
                              </b-card-body>
                              <div
                                class="divSeparation"
                              />
                              <b-card-title
                                class="attachmentSectionTitle mt-0"
                              >
                                <span
                                  class="d-inline ml-1"
                                  :style="{'font-size': '1.18rem'}"
                                >
                                  Atestados da Empresa
                                </span>
                              </b-card-title>
                              <b-card-body
                                class="p-0 mb-3 mt-1"
                                no-body
                              >
                                <bidding-company-certificate
                                  :bidding-id="data.id"
                                  :company-id="data.company ? data.company : ''"
                                  :readonly="isViewMode"
                                />
                              </b-card-body>
                            </b-tab>
                            <b-tab
                              v-if="!isCreateMode && !hasEmptyFields"
                              :active="activeTab === dataTab.products"
                              @click="selectedTab(dataTab.products)"
                            >
                              <template #title>
                                <b-card-title>
                                  <svg-icon
                                    class="mr-50 biddingIconTitle"
                                    type="mdi"
                                    :path="mdiPackageVariant"
                                  />
                                  <span class="d-inline">Produtos</span>
                                </b-card-title>
                              </template>
                              <bidding-product-list
                                ref="refBiddingProductList"
                                :bidding="data"
                                :readonly="isViewMode"
                                @toggleCard="toggleCard"
                                @store-check="onStoreCheck"
                                @clear-storage="clearCheckStorage"
                                @clearItems="clearBiddingItems"
                              />
                            </b-tab>
                            <b-tab
                              v-if="!isCreateMode && !hasEmptyFields"
                              :active="activeTab === dataTab.history"
                              @click="selectedTab(dataTab.history)"
                            >
                              <template #title>
                                <b-card-title>
                                  <svg-icon
                                    class="mr-50 biddingIconTitle"
                                    type="mdi"
                                    :path="mdiClipboardTextClockOutline"
                                  />
                                  <span class="d-inline">Histórico</span>
                                </b-card-title>
                              </template>
                              <b-row
                                v-if="activeTab === dataTab.history"
                              >
                                <b-col class="mt-3 pb-2">
                                  <bidding-history
                                    :bidding-id="data.id"
                                  />
                                </b-col>
                              </b-row>
                            </b-tab>
                          </b-tabs>
                        </b-card>
                      </div>
                      <b-row
                        v-if="(isTabBiddingFormActive || hasEmptyFields) && (activeTab !== dataTab.products || !isItemOpen)"
                        class="mb-2"
                      >
                        <b-col
                          class="d-flex justify-content-end buttonsSaveCancel"
                        >
                          <b-button
                            v-if="activeTab === dataTab.products && (isEditMode || isCreateMode)"
                            class="d-flex align-items-center justify-content-center pendingBtn mr-auto"
                            variant="transparent"
                            :style="{'min-width': '15rem'}"
                            :disabled="!haveChecks"
                            @click="deleteSelected"
                          >
                            <svg-icon
                              class="mr-50"
                              type="mdi"
                              :path="mdiTrashCanOutline"
                            />
                            <span>Apagar Selecionados</span>
                          </b-button>
                          <b-button
                            v-if="isEditMode || isCreateMode"
                            id="btn_save"
                            variant="primary"
                            class="mr-0 mr-sm-1"
                            :disable="invalid"
                            @click="handleSubmit(onSubmit)"
                          >
                            Salvar
                          </b-button>
                          <b-button
                            v-if="isCreateMode"
                            id="btn_cancel"
                            variant="outline-primary"
                            class="mb-sm-0 mr-0 mr-sm-1"
                            :to="{ name: 'bidding' }"
                          >
                            Cancelar
                          </b-button>
                          <b-button
                            v-if="isViewMode || isEditMode"
                            id="btn_go_back"
                            variant="outline-primary"
                            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                            :to=" { name: 'bidding' } "
                          >
                            Voltar
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <b-modal
      id="idModalEffect"
      ref="modalEffect"
      no-close-on-backdrop
      no-close-on-esc
      centered
      cancel-variant="outline-primary"
      @ok="exportProductsCSV()"
    >
      <template #modal-cancel>
        Cancelar
      </template>
      <template #modal-ok>
        Exportar
      </template>
      <b-form
        class="mt-1"
      >
        <b-row>
          <b-col>
            <span
              class="d-flex justify-content-center px-2 mb-1"
            >
              <svg-icon
                class="colorIconAlert"
                type="mdi"
                size="70"
                :path="mdiCommentQuestionOutline"
              />
            </span>
            <span
              class="d-flex justify-content-center px-2 fixLayoutTextModal"
            >
              Deseja exportar os dados para o modelo Effecti?
            </span>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <b-modal
      id="idModalEffectSpinner"
      ref="modalEffectSpinner"
      no-close-on-backdrop
      no-close-on-esc
      centered
      hide-footer
    >
      <b-form
        class="mt-1"
      >
        <b-row>
          <b-col>
            <span
              class="d-flex justify-content-center px-2 mb-1"
            >
              <svg-icon
                :style="{'color': '#FFD15C'}"
                type="mdi"
                size="70"
                :path="mdiFolderOpenOutline"
              />
            </span>
            <span
              class="d-flex justify-content-center px-2 fixLayoutTextModal"
            >
              Exportando Dados
            </span>
            <span
              class="d-flex justify-content-center px-2 mb-1"
            >
              <b-spinner
                class="my-2"
                style="width: 5rem; height: 5rem; color: #28C76F;"
                label="Busy"
              />
            </span>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <b-modal
      id="idModalEffectSuccess"
      ref="modalEffectSuccess"
      class="modalStyle"
      no-close-on-backdrop
      no-close-on-esc
      centered
      hide-footer
    >
      <b-form
        class="mt-1"
      >
        <b-row>
          <b-col>
            <span
              class="d-flex justify-content-center px-2 mb-1 layoutModalSuccess"
            >
              <svg-icon
                type="mdi"
                size="70"
                :path="mdiContentSaveCheckOutline"
              />
            </span>
            <span
              class="d-flex justify-content-center px-2 my-1 fixLayoutTextModal"
            >
              Modelo Exportado com Sucesso!
            </span>
            <span
              class="d-flex justify-content-center px-2 mt-3 mb-2 fixLayoutTextModal"
            >
              <b-button
                id="hide-btn"
                variant="primary"
                style="min-width: 112px;"
                @click="closeModalEffectSuccess()"
              >
                Ok
              </b-button>
            </span>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <b-modal
      id="idModalEffectUnsuccess"
      ref="modalEffectUnsuccess"
      class="modalStyle"
      no-close-on-backdrop
      no-close-on-esc
      centered
      hide-footer
    >
      <b-form
        class="mt-1"
      >
        <b-row>
          <b-col>
            <span
              class="d-flex justify-content-center px-2 mb-1 layoutModalUnsuccess"
            >
              <svg-icon
                type="mdi"
                size="70"
                :path="mdiContentSaveAlertOutline"
              />
            </span>
            <span
              class="d-flex justify-content-center px-2 my-1 fixLayoutTextModal"
            >
              Falha ao exportar dados para o modelo!
            </span>
            <span
              class="d-flex justify-content-center px-2 mt-3 mb-2 fixLayoutTextModal"
            >
              <b-button
                id="hide-btn"
                variant="primary"
                style="min-width: 112px;"
                @click="closeModalEffectUnsuccess()"
              >
                Ok
              </b-button>
            </span>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <b-modal
      id="idModalHomologate"
      ref="modalHomologate"
      class="modalStyle"
      no-close-on-backdrop
      no-close-on-esc
      centered
      hide-footer
    >
      <b-form
        class="mt-1"
      >
        <b-row>
          <b-col>
            <span
              class="d-flex justify-content-center px-2 mb-1 layoutModalAlert"
            >
              <svg-icon
                type="mdi"
                size="70"
                :path="mdiAlertCircle"
              />
            </span>
            <span
              class="d-flex justify-content-center px-2 my-1 fixLayoutTextModal"
            >
              Deseja homologar essa licitação para o andamento do pedido?
            </span>
            <span
              class="d-flex justify-content-center px-2 mt-3 mb-2 fixLayoutTextModal"
            >
              <b-button
                id="hide-btn"
                variant="primary"
                class="mx-50"
                style="min-width: 112px;"
                @click="canHomologateBidding()"
              >
                Sim
              </b-button>
              <b-button
                id="hide-btn"
                variant="outline-primary"
                class="mx-50"
                style="min-width: 112px;"
                @click="closeModalHomologate()"
              >
                Não
              </b-button>
            </span>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <b-modal
      id="confirmDeleteBidModal"
      ref="confirmDeleteBidModal"
      class="modalStyle"
      no-close-on-backdrop
      no-close-on-esc
      centered
      hide-footer
    >
      <b-form
        class="mt-1"
      >
        <b-row>
          <b-col>
            <span
              class="d-flex justify-content-center px-2 mb-1 layoutModalAlert"
            >
              <svg-icon
                type="mdi"
                size="70"
                :path="mdiAlertCircle"
              />
            </span>
            <span
              class="d-flex justify-content-center fixLayoutTitleModal"
            >
              Atenção!
            </span>
            <span
              class="d-flex justify-content-center my-1 fixLayoutTextModal"
            >
              Você realmente quer remover essa licitação? <br> Essa ação NÃO pode ser desfeita!
            </span>
            <span
              class="d-flex justify-content-center px-2 mt-3 mb-2 fixLayoutTextModal"
            >
              <b-button
                id="hide-btn"
                variant="primary"
                class="mx-50"
                style="min-width: 112px;"
                @click="removeBid()"
              >
                Sim
              </b-button>
              <b-button
                id="hide-btn"
                variant="outline-primary"
                class="mx-50"
                style="min-width: 112px;"
                @click="closeConfirmDeleteBidModal()"
              >
                Não
              </b-button>
            </span>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <b-modal
      id="idModalUndoHomologate"
      ref="modalUndoHomologate"
      class="modalStyle"
      no-close-on-backdrop
      no-close-on-esc
      centered
      hide-footer
    >
      <b-form
        class="mt-1"
      >
        <b-row>
          <b-col>
            <span
              class="d-flex justify-content-center px-2 mb-1 layoutModalAlert"
            >
              <svg-icon
                type="mdi"
                size="70"
                :path="mdiAlertCircle"
              />
            </span>
            <span
              class="d-flex justify-content-center px-2 my-1 fixLayoutTextModal"
            >
              Essa licitação está fechada em homologação, deseja reabrir?
            </span>
            <span
              class="d-flex justify-content-center px-2 mt-3 mb-2 fixLayoutTextModal"
            >
              <b-button
                id="hide-btn"
                variant="primary"
                class="mx-50"
                style="min-width: 112px;"
                @click="canUndoHomologateBidding()"
              >
                Sim
              </b-button>
              <b-button
                id="hide-btn"
                variant="outline-primary"
                class="mx-50"
                style="min-width: 112px;"
                @click="closeModalUndoHomologate()"
              >
                Não
              </b-button>
            </span>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <b-modal
      id="idModalHomologateRejected"
      ref="modalHomologateRejected"
      class="modalStyle"
      no-close-on-backdrop
      no-close-on-esc
      centered
      hide-footer
    >
      <b-form
        class="mt-1 py-2"
      >
        <b-row>
          <b-col>
            <span
              class="d-flex justify-content-center px-2 mb-1 layoutModalAlert"
            >
              <svg-icon
                type="mdi"
                size="70"
                :path="mdiAlertCircle"
              />
            </span>
            <span
              class="d-flex justify-content-center fixLayoutTitleModal"
            >
              Não é possível homologar a licitação
            </span>
            <span
              class="d-flex justify-content-center px-2 my-1 fixLayoutTextModal"
            >
              Um ou mais itens não estão marcados com status de ganho ou perdido.
            </span>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <b-modal
      id="idModalLostHomologateRejected"
      ref="modalLostHomologateRejected"
      class="modalStyle"
      no-close-on-backdrop
      no-close-on-esc
      centered
      hide-footer
    >
      <b-form
        class="mt-1 py-2"
      >
        <b-row>
          <b-col>
            <span
              class="d-flex justify-content-center px-2 mb-1 layoutModalAlert"
            >
              <svg-icon
                type="mdi"
                size="70"
                :path="mdiAlertCircle"
              />
            </span>
            <span
              class="d-flex justify-content-center px-2 my-1 layoutTextModalAlert"
            >
              A licitação não pode ser homologada com todos os produtos marcados como perdido.
            </span>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <b-modal
      id="idModalNoItemHomologateRejected"
      ref="modalNoItemHomologateRejected"
      class="modalStyle"
      no-close-on-backdrop
      no-close-on-esc
      centered
      hide-footer
    >
      <b-form
        class="mt-1 py-2"
      >
        <b-row>
          <b-col>
            <span
              class="d-flex justify-content-center px-2 mb-1 layoutModalAlert"
            >
              <svg-icon
                type="mdi"
                size="70"
                :path="mdiAlertCircle"
              />
            </span>
            <span
              class="d-flex justify-content-center px-2 my-1 layoutTextModalAlert"
            >
              Esta licitação não possui itens cadastrados, por esta razão não poderá ser homologada.
            </span>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <b-modal
      id="idModalUndoHomologateRejected"
      ref="modalUndoHomologateRejected"
      class="modalStyle"
      no-close-on-backdrop
      no-close-on-esc
      centered
      hide-footer
    >
      <b-form
        class="mt-1 py-2"
      >
        <b-row>
          <b-col>
            <span
              class="d-flex justify-content-center px-2 mb-1 layoutModalAlert"
            >
              <svg-icon
                type="mdi"
                size="70"
                :path="mdiAlertCircle"
              />
            </span>
            <span
              class="d-flex justify-content-center px-2 my-1 layoutTextModalAlert"
            >
              Não é possível realizar esta ação, a licitação está vinculada a um contrato.
            </span>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <b-modal
      id="idModalHomologateSuccess"
      ref="modalHomologateSuccess"
      class="modalStyle"
      no-close-on-backdrop
      no-close-on-esc
      centered
      hide-footer
    >
      <b-form
        class="mt-1 py-2"
      >
        <b-row>
          <b-col>
            <span
              class="d-flex justify-content-center px-2 mb-1 layoutModalSuccess"
            >
              <svg-icon
                type="mdi"
                size="70"
                :path="mdiCheckDecagram"
              />
            </span>
            <span
              class="d-flex justify-content-center px-2 my-1 fixLayoutTextModal"
            >
              Licitação homologada com sucesso!
            </span>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <b-modal
      id="idModalUndoHomologateSuccess"
      ref="modalUndoHomologateSuccess"
      class="modalStyle"
      no-close-on-backdrop
      no-close-on-esc
      centered
      hide-footer
    >
      <b-form
        class="mt-1 py-2"
      >
        <b-row>
          <b-col>
            <span
              class="d-flex justify-content-center px-2 mb-1 layoutModalSuccess"
            >
              <svg-icon
                type="mdi"
                size="70"
                :path="mdiCheckCircle"
              />
            </span>
            <span
              class="d-flex justify-content-center px-2 my-1 fixLayoutTextModal"
            >
              Licitação reaberta com sucesso!
            </span>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <b-modal
      id="confirmFiledBidModal"
      ref="confirmFiledBidModal"
      class="modalStyle"
      no-close-on-backdrop
      no-close-on-esc
      centered
      hide-footer
    >
      <b-form
        class="mt-1"
      >
        <b-row>
          <b-col>
            <span
              class="d-flex justify-content-center px-2 mb-1 layoutModalAlert"
            >
              <svg-icon
                type="mdi"
                size="70"
                :path="data.is_filed ? mdiArchiveArrowUpOutline : mdiArchiveArrowDownOutline"
              />
            </span>
            <span
              class="d-flex justify-content-center fixLayoutTitleModal"
            >
              Atenção!
            </span>
            <span
              v-if="data.is_filed"
              class="d-flex justify-content-center my-1 fixLayoutTextModal"
            >
              Você realmente deseja desarquivar essa licitação?
            </span>
            <span
              v-else
              class="d-flex justify-content-center my-1 fixLayoutTextModal"
            >
              Você realmente deseja arquivar essa licitação?
            </span>
            <span
              class="d-flex justify-content-center px-2 mt-3 mb-2 fixLayoutTextModal"
            >
              <b-button
                id="hide-btn"
                variant="primary"
                class="mx-50"
                style="min-width: 112px;"
                @click="filedBid()"
              >
                Sim
              </b-button>
              <b-button
                id="hide-btn"
                variant="outline-primary"
                class="mx-50"
                style="min-width: 112px;"
                @click="closeConfirmFiledBidModal()"
              >
                Não
              </b-button>
            </span>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormDatepicker,
  BCard,
  BCardBody,
  BCardTitle,
  BButton,
  BAvatar,
  BMedia,
  BDropdown,
  BDropdownItem,
  BTabs,
  BTab,
  BBadge,
  BLink,
  BModal,
  BSpinner,
  BFormTextarea,
} from 'bootstrap-vue'

import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'

import {
  required,
  confirmed,
} from '@validations'

import {
  useToast,
} from 'vue-toastification/composition'

import {
  mdiClipboardTextOutline,
  mdiPaperclip,
  mdiFileDocumentPlusOutline,
  mdiPackageVariant,
  mdiClipboardTextClockOutline,
  mdiChevronRight,
  mdiFileDocumentOutline,
  mdiPlus,
  mdiAlertCircle,
  mdiCheckCircle,
  mdiCheckDecagram,
  mdiFileExportOutline,
  mdiFileCertificateOutline,
  mdiCommentQuestionOutline,
  mdiFolderOpenOutline,
  mdiContentSaveCheckOutline,
  mdiContentSaveAlertOutline,
  mdiTrashCanOutline,
  mdiPodium,
  mdiArchiveArrowUpOutline,
  mdiArchiveArrowDownOutline,
} from '@mdi/js'

import exportFromJSON from 'export-from-json'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/pt-br'
import DatePicker from 'vue2-datepicker'
import SvgIcon from '@jamescoyle/vue-icon'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import SpinLoader from '@/components/spinloader/SpinLoader.vue'
import router from '@/router'
import syslic from '@/syslic'
import SyncLoad from '@/components/utils/syncLoad'
import truncate from '@/components/utils/filters/truncate'
import ChangeDetector from '@/components/utils/changeDetector'
import CompanySelect from '@/components/company/CompanySelect.vue'
import ClientSelect from '@/components/client/ClientSelect.vue'
import InterestSelect from '@/components/bidding/InterestSelect.vue'
import ModalitySelect from '@/components/bidding/ModalitySelect.vue'
import PlatformSelect from '@/components/bidding/PlatformSelect.vue'
import TypeSelect from '@/components/bidding/TypeSelect.vue'
import StatusSelect from '@/components/bidding/StatusSelect.vue'
import PhaseSelect from '@/components/bidding/PhaseSelect.vue'
import OwnerSelect from '@/components/user/OwnerSelect.vue'
import BiddingProductList from '@/views/bidding/components/BiddingProductList.vue'
import BiddingAttachment from '@/views/bidding/components/BiddingAttachment.vue'
import StateSelect from '@/components/address/StateSelect.vue'
import CitySelect from '@/components/address/CitySelect.vue'
import DisputeSelect from '@/components/bidding/DisputeSelect.vue'
import RegionFreightSelect from '@/components/transport/RegionFreightSelect.vue'
import Messager from '@/components/messager/Messager.vue'
import BiddingAdditionalData from './components/BiddingAdditionalData.vue'
import BiddingHistory from './history/BiddingHistory.vue'
import BiddingCompanyCertificate from './components/BiddingCompanyCertificate.vue'
import BiddingCompanyDocument from './components/BiddingCompanyDocument.vue'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BCard,
    BCardBody,
    BCardTitle,
    BButton,
    BAvatar,
    BMedia,
    BDropdown,
    BDropdownItem,
    BFormInvalidFeedback,
    BFormDatepicker,
    BTabs,
    BTab,
    BBadge,
    BLink,
    BModal,
    BSpinner,
    BFormTextarea,
    DatePicker,
    SvgIcon,
    ValidationProvider,
    ValidationObserver,
    SpinLoader,
    CompanySelect,
    ClientSelect,
    InterestSelect,
    ModalitySelect,
    PlatformSelect,
    TypeSelect,
    StatusSelect,
    PhaseSelect,
    OwnerSelect,
    BiddingProductList,
    BiddingAttachment,
    StateSelect,
    CitySelect,
    DisputeSelect,
    RegionFreightSelect,
    Messager,
    BiddingAdditionalData,
    BiddingHistory,
    BiddingCompanyCertificate,
    BiddingCompanyDocument,
  },
  filters: {
    truncate,
  },
  data() {
    let biddingID = ''

    const data = {
      additional_address: '',
      additional_address_type: '',
      additional_address_type_set: {
        name: '',
      },
      additional_city: '',
      additional_city_set: {
        name: '',
      },
      additional_complement: '',
      additional_country: '',
      additional_country_set: {
        name: '',
      },
      additional_neighborhood: '',
      additional_neighborhood_type: '',
      additional_neighborhood_type_set: {
        name: '',
      },
      additional_number: '',
      additional_state: '',
      additional_state_set: {
        name: '',
      },
      additional_zip_code: '',
      client: '',
      client_set: {
        name: '',
      },
      company: '',
      company_set: '',
      crier: '',
      type: '',
      type_set: '',
      modality: '',
      modality_set: '',
      interest: '',
      interest_set: '',
      platform: '',
      platform_set: '',
      status: '',
      status_set: '',
      owner: '',
      owner_set: '',
      trade_number: '',
      uasg: '',
      link_trade: '',
      link_support: '',
      link_pncp: '',
      date: null,
      freight_group: '',
      freight_group_set: '',
      dispute: '',
      dispute_set: '',
      items_set: [],
      file_set: [],
      city: '',
      city_set: '',
      state: '',
      state_set: '',
      country: 1,
      country_set: '',
      proposal_validity: '',
      phone_number: '',
      email: '',
      exclusive_me_epp: false,
      price_registry: false,
      observation: '',
      object_bidding: '',
      requirements: [],
    }

    const dataTab = {
      bidding: 1,
      additional_data: 2,
      attachments: 3,
      products: 4,
      history: 5,
    }

    const ownerImage = ''

    const isItemOpen = false

    const checkedProductsStorage = new Map()

    const changeDetector = new ChangeDetector()

    let activeTab = dataTab.bidding
    let isViewMode = false
    let isEditMode = false
    let isCreateMode = false
    const isFirstUpload = true
    const readyBidding = false
    const isImported = false
    const hasEmptyFields = false
    const haveChecks = false

    if (router.currentRoute.path.match(/.*\/edit\/.*/)) {
      isEditMode = true
    } else if (router.currentRoute.path.match(/.*\/create/)) {
      isCreateMode = true
    } else {
      isViewMode = true
    }

    if ((isViewMode || isEditMode) && !hasEmptyFields) {
      biddingID = router.currentRoute.params.id
      data.id = router.currentRoute.params.id
      activeTab = router.currentRoute.params.tab
    }

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(this.user)

    const isLoaded = false

    const textLoading = ''

    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('bidding')
    syncLoad.addTrigger('company')
    syncLoad.addTrigger('interest')
    syncLoad.addTrigger('modality')
    syncLoad.addTrigger('platform')
    syncLoad.addTrigger('type')
    syncLoad.addTrigger('status')
    syncLoad.addTrigger('phase')
    syncLoad.addTrigger('client')
    syncLoad.addTrigger('owner')
    syncLoad.addTrigger('city')
    syncLoad.addTrigger('state')
    syncLoad.addTrigger('dispute')
    syncLoad.addTrigger('freightGroup')

    if (!isCreateMode && !isViewMode) {
      syncLoad.addTrigger('hasEmptyFields')
    }

    if (!isCreateMode) {
      syncLoad.addTrigger('messager')
    }

    if (!isCreateMode && !hasEmptyFields) {
      syncLoad.addTrigger('additionalData')
    }

    syncLoad.onReady(() => {
      this.isLoaded = true
    })

    const isTabBiddingFormActive = true

    return {
      biddingID,
      required,
      confirmed,
      checkedProductsStorage,
      isViewMode,
      isEditMode,
      isCreateMode,
      isFirstUpload,
      readyBidding,
      isImported,
      hasEmptyFields,
      haveChecks,
      refFormObserver,
      getValidationState,
      isLoaded,
      textLoading,
      syncLoad,
      data,
      activeTab,
      ownerImage,
      isItemOpen,
      isTabBiddingFormActive,
      dataTab,
      changeDetector,
    }
  },
  computed: {
    hasPermissionToRead() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.bidding.can_read
    },
    hasPermissionToWrite() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.bidding.can_write
    },
    hasPermissionToUpdate() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.bidding.can_edit
    },
    hasPermissionToDelete() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.bidding.can_delete
    },
    isPlatformSupported() {
      if (!this.data.platform || !this.data.platform_set) {
        return false
      }

      const SUPPORTED_PLATFORMS = ['BNC', 'BLL', 'COMPRASNET', 'LICITAÇÕES-E']
      const platform = this.data.platform_set.name.toUpperCase()

      return SUPPORTED_PLATFORMS.includes(platform)
    },
  },
  watch: {
    'data.owner_set': function updateOwner(obj) {
      if (obj) {
        this.data.owner = obj.id

        syslic
          .user
          .getUserImage(this.data.owner_set.get_uuid)
          .then(response => {
            this.ownerImage = response
          })
      }
    },
    'data.client_set': function updateClient(obj) {
      if (obj) {
        this.data.client = obj.id
      }
      if (!this.isFirstUpload) {
        if (this.data.client_set.state) {
          this.data.state = this.data.client_set.state
          this.data.state_set = this.data.client_set.state_set
          if (this.data.client_set.city) {
            this.data.city = this.data.client_set.city
            this.data.city_set = this.data.client_set.city_set
          }
        } else {
          this.data.state = ''
          this.data.state_set = ''
          this.data.city = ''
          this.data.city_set = ''
        }

        if (this.data.client_set.region_freight_set) {
          this.data.freight_group_set = this.data.client_set.region_freight_set
        } else {
          this.data.freight_group_set = ''
        }
      } else {
        this.isFirstUpload = false
      }
    },
    'data.company_set': function updateCompany(obj) {
      if (obj) {
        this.data.company = obj.id
        if (!this.data.is_homologated) {
          this.data.items_set.forEach((element, index) => {
            this.data.items_set[index].tax = obj.tax_aliquot
            this.data.items_set[index].margin_min = obj.margin_min

            if (this.hasEmptyFields) {
              this.data.items_set[index].fixed_cost = obj.fixed_cost
            }
          })
        }
      }
    },
    'data.interest_set': function updateInterest(obj) {
      if (obj) {
        this.data.interest = obj.id
      }
    },
    'data.modality_set': function updateModality(obj) {
      if (obj) {
        this.data.modality = obj.id
      }
    },
    'data.platform_set': function updatePlatform(obj) {
      if (obj) {
        this.data.platform = obj.id
      }
    },
    'data.type_set': function updateType(obj) {
      if (obj) {
        this.data.type = obj.id
      }
    },
    'data.status_set': function updateStatus(obj) {
      if (obj) {
        this.data.status = obj.id
      }
    },
    'data.phase_set': function updatePhase(obj) {
      if (obj) {
        this.data.phase = obj.id
      }
    },
    'data.city_set': function updateCity(obj) {
      if (obj) {
        this.data.city = obj.id
      }
    },
    'data.state_set': function updateState(obj) {
      if (obj) {
        this.data.state = obj.id
      }
    },
    'data.dispute_set': function updateDispute(obj) {
      if (obj) {
        this.data.dispute = obj.id
      }
    },
    'data.freight_group_set': function updateFreightGroup(obj) {
      if (obj) {
        this.data.freight_group = obj.id
        if (this.hasEmptyFields) {
          this.data.items_set.forEach((element, index) => {
            this.data.items_set[index].freight = obj.value
          })
        }
      }
    },
    'data.date': function updateDate(obj) {
      if (obj === '') {
        this.data.date = null
      }
    },
    'data.date_capture': function updateDateCapture(obj) {
      if (obj === '') {
        this.data.date_capture = null
      }
    },
    'data.date_proposal': function updateDateProposal(obj) {
      if (obj === '') {
        this.data.date_proposal = null
      }
    },
    'data.date_impugnment': function updateDateImpugnment(obj) {
      if (obj === '') {
        this.data.date_impugnment = null
      }
    },
    'data.date_clarification': function updateDateClarification(obj) {
      if (obj === '') {
        this.data.date_clarification = null
      }
    },
  },
  created() {
    this.fetchData()
    this.changeDetector.bind(this, ['data'])
  },
  methods: {
    handleSubmit(callback) {
      let isOk = true
      this.$refs.refFormObserver.validate()
        .then(() => {
          const errorsKeys = Object.keys(this.$refs.refFormObserver.errors)
          Object.keys(this.data).forEach(key => {
            if (errorsKeys.includes(key)) {
              if (this.$refs.refFormObserver.errors[key].length > 0) {
                isOk = false
              }
            }
          })
          if (isOk) {
            callback()
          }
        })
    },
    fetchData() {
      if (this.isCreateMode) {
        this.syncLoad.ready('bidding')
        this.readyBidding = true
      } else {
        syslic
          .bidding
          .fetchBidding(this.data.id)
          .then(response => {
            this.data = response.data
            if (this.isEditMode) {
              this.fetchImportStatus()
            }
            if (this.data.is_homologated && this.isEditMode) {
              router.push({ name: 'bidding-view', params: { id: this.data.id, tab: this.activeTab } })
            }
            return syslic.user.getUserImage(this.data.owner_set.get_uuid)
          })
          .then(response => {
            this.ownerImage = response
            this.syncLoad.ready('bidding')
            this.readyBidding = true
            this.changeDetector.reset()
          })
          .catch(error => {
            if (error.status === 404) {
              router.push({ name: 'error-404' })
            }
            this.syncLoad.ready('bidding')
            this.readyBidding = true
          })
      }
    },
    fetchImportStatus() {
      const params = {
        bidding: this.data.id,
      }

      syslic
        .pncp
        .fetchBiddingsImported(params)
        .then(response => {
          const { data } = response
          this.isImported = data.count > 0
          this.checkFieldsIsFilled()
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Não foi possível verificar se esta licitação foi importada.',
              text: 'Não foi possível requisitar o status de importação desta licitação',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
          this.checkFieldsIsFilled()
        })
    },
    checkFieldsIsFilled() {
      if (this.isImported) {
        if (!(this.data.company && this.data.owner && this.data.freight_group)) {
          this.hasEmptyFields = true
          this.$swal({
            title: 'Esta licitação foi importada',
            text: 'É preciso que todos os campos estejam preenchidos para poder editá-la.',
            icon: 'info',
            showConfirmButton: false,
            timer: 4000,
          })
        }
      }

      // add syncLoad of additionalData to fix reactivity error
      this.syncLoad.ready('additionalData')
      this.syncLoad.ready('hasEmptyFields')

      return this.hasEmptyFields
    },
    create() {
      this.$refs.modalUpdateBid.show()
      this.textLoading = 'Criando...'
      syslic
        .bidding
        .addBidding(this.data)
        .then(response => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Licitação adicionada com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          router.push({ name: 'bidding-view', params: { id: response.data.id } }).catch(() => {})
        })
        .catch(error => {
          let textError = 'Não foi possível adicionar a licitação, por favor entre em contato com o administrador do sistema.'

          if (error.data.includes('Key (uasg, trade_number, account_id)')) {
            textError = 'Não foi possivel adicionar uma nova licitação porque já existe uma licitação cadastrada com o mesmo Nº Pregão e UASG/OC.'
          } else if ('non_field_errors' in error.data) {
            if (error.data.non_field_errors[0] === 'The fields trade_number, uasg must make a unique set.') {
              textError = 'Não foi possivel adicionar uma nova licitação porque já existe uma licitação cadastrada com o mesmo Nº Pregão e UASG/OC.'
            }
          }

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao adicionar uma nova licitação.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
          this.$refs.modalUpdateBid.hide()
        })
    },
    update() {
      if (!this.isCreateMode && !this.hasEmptyFields) {
        this.$refs.refBiddingProductList.closeCardProduct()
          .then(() => {
            this.$refs.modalUpdateBid.show()
            this.textLoading = 'Atualizando...'

            return syslic.bidding.updateBidding(this.data.id, this.data)
          })
          .then(() => {
            this.data.items_set.forEach(obj => {
              if (this.hasEmptyFields) {
                /* eslint-disable no-param-reassign */
                obj.freight = parseFloat(this.data.freight_group_set.value)
                obj.fixed_cost = parseFloat(this.data.company_set.fixed_cost)
                /* eslint-enable no-param-reassign */
              }

              const item = JSON.parse(JSON.stringify(obj))

              item.freight = parseFloat(obj.freight)
              item.fixed_cost = parseFloat(obj.fixed_cost)
            })

            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Licitação atualizada com sucesso.',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
            if (this.hasEmptyFields) {
              this.hasEmptyFields = false
            } else {
              this.disableEdition()
            }
            this.$refs.modalUpdateBid.hide()
            this.changeDetector.reset()
          })
          .catch(error => {
            let textError = 'Não foi possível atualizar a licitação, por favor entre em contato com o administrador do sistema.'

            if ('data' in error) {
              if (error.data.includes('Key (uasg, trade_number, account_id)')) {
                textError = 'Não foi possivel atualizar a licitação porque já existe uma licitação cadastrada com o mesmo Nº Pregão e UASG/OC.'
              } else if ('non_field_errors' in error.data) {
                if (error.data.non_field_errors[0] === 'The fields trade_number, uasg must make a unique set.') {
                  textError = 'Não foi possivel atualizar a licitação porque já existe uma licitação cadastrada com o mesmo Nº Pregão e UASG/OC.'
                }
              }
            }

            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Falha ao atualizar a licitação.',
                text: textError,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                timeout: false,
              },
            })
            this.$refs.modalUpdateBid.hide()
          })
      } else {
        this.$refs.modalUpdateBid.show()
        this.textLoading = 'Atualizando...'
        syslic
          .bidding
          .updateBidding(this.data.id, this.data)
          .then(() => {
            const promiseList = []
            if (this.hasEmptyFields) {
              this.data.items_set.forEach((element, index) => {
                const newPromise = new Promise(resolve => {
                  syslic
                    .bidding
                    .item
                    .updateItem(this.data.items_set[index].id, this.data.items_set[index])
                    .then(() => {
                      resolve()
                    })
                })
                promiseList.push(newPromise)
              })
            } else {
              const dummyPromise = new Promise(resolve => {
                resolve()
              })
              promiseList.push(dummyPromise)
            }

            return Promise.allSettled(promiseList)
          })
          .then(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Licitação atualizada com sucesso.',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
            if (this.hasEmptyFields) {
              this.hasEmptyFields = false
            } else {
              this.disableEdition()
            }
            this.$refs.modalUpdateBid.hide()
            this.changeDetector.reset()
          })
          .catch(error => {
            let textError = 'Não foi possível atualizar a licitação, por favor entre em contato com o administrador do sistema.'

            if ('non_field_errors' in error.data) {
              if (error.data.non_field_errors[0] === 'The fields trade_number, uasg must make a unique set.') {
                textError = 'Não foi possivel atualizar a licitação porque já existe uma licitação cadastrada com o mesmo Nº Pregão e UASG/OC.'
              }
            }

            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Falha ao atualizar a licitação.',
                text: textError,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                timeout: false,
              },
            })
            this.$refs.modalUpdateBid.hide()
          })
      }
    },
    onSubmit() {
      if (this.isCreateMode) {
        this.create()
      } else if (this.isEditMode) {
        this.update()
      }
    },
    delete() {
      syslic
        .bidding
        .deleteBidding(this.data.id)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Licitação removida com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          router.push({ name: 'bidding' })
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao remover licitação.',
              text: 'Não foi possível remover a licitação do servidor, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    removeBid() {
      this.delete()
    },
    selectedTab(value) {
      this.activeTab = value
    },
    enableEdition() {
      router.push({ name: 'bidding-edit', params: { id: this.data.id, tab: this.activeTab } }).catch(() => {})
    },
    disableEdition() {
      router.push({ name: 'bidding-view', params: { id: this.data.id, tab: this.activeTab } }).catch(() => {})
    },
    activeBiddingForm(index) {
      if (index === 0 || index === 1 || index === 2 || index === 3) {
        this.isTabBiddingFormActive = true
      } else {
        this.isTabBiddingFormActive = false
      }
    },
    showModaEffect() {
      this.$refs.modalEffect.show()
    },
    closeModalEffectUnsuccess() {
      this.$refs.modalEffectUnsuccess.hide()
    },
    closeModalEffectSuccess() {
      this.$refs.modalEffectSuccess.hide()
    },
    exportProductsCSV() {
      this.$refs.modalEffectSpinner.show()

      syslic
        .bidding
        .fetchBidding(this.data.id)
        .then(response => {
          const dataBidding = response.data
          const dataExport = []

          const filteredItems = dataBidding.items_set.filter(item => item.type !== 'lote')

          filteredItems.forEach(element => {
            const itemEffect = {
              Item: element.number,
              Nome: element.name,
              'Descrição do Edital': element.description,
              Marca: '',
              Fabricante: '',
              Modelo: '',
              Unidade: '',
              QT: element.quantity,
              'Valor Minimo Unitário': element.price_min,
              'Valor Estimado Unitário': element.price,
              Total: this.formatCurrency(element.quantity * element.price),
              Lote: '',
            }

            if (element.product_list[0] && element.product_list[0].product_set) {
              const product = element.product_list[0].product_set
              if (product.brand) itemEffect.Marca = product.brand
              if (product.supplier_set && product.supplier_set.name) itemEffect.Fabricante = product.supplier_set.name
              if (product.model) itemEffect.Modelo = product.model
              if (product.unity_set && product.unity_set.symbol) itemEffect.Unidade = product.unity_set.symbol
            }

            if (element.parent) {
              itemEffect.Lote = element.parent_set.name
            }

            dataExport.push(itemEffect)
          })

          const fileName = 'exportação_effect'
          const exportType = exportFromJSON.types.csv
          exportFromJSON({ data: dataExport, fileName, exportType })

          setTimeout(() => {
            this.$refs.modalEffectSpinner.hide()
            this.$refs.modalEffectSuccess.show()
          }, 1000)
        })
        .catch(() => {
          setTimeout(() => {
            this.$refs.modalEffectSpinner.hide()
            this.$refs.modalEffectUnsuccess.show()
          }, 1000)

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao exportar dados da licitação.',
              text: '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    makeHomogation(isHomologating, value) {
      syslic
        .bidding
        .homologation
        .updateHomologation(this.data.id, value)
        .then(() => {
          if (isHomologating) {
            this.closeModalHomologate()
            this.$refs.modalHomologateSuccess.show()
          } else {
            this.closeModalUndoHomologate()
            this.$refs.modalUndoHomologateSuccess.show()
          }
          this.fetchData()
          this.$refs.refBiddingProductList.refreshAfterHomologate()

          if (this.hasPermissionToUpdate) {
            this.enableEdition()
          }
        })
        .catch(() => {
          if (isHomologating) {
            this.closeModalHomologate()
            const textError = 'Não foi possível homologar a licitação, por favor entre em contato com o administrador do sistema.'

            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Falha ao homologar a licitação.',
                text: textError,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                timeout: false,
              },
            })
          } else {
            this.closeModalUndoHomologate()
            const textError = 'Não foi possível desfazer a homologação da licitação, por favor entre em contato com o administrador do sistema.'

            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Falha ao desfazer a homologação da licitação.',
                text: textError,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                timeout: false,
              },
            })
          }
          this.fetchData()
        })
    },
    canHomologateBidding() {
      const items = this.data.items_set
      let haveItemPedding = false
      let isAllLost = true

      if (items.length > 0) {
        items.forEach(element => {
          if (!element.parent_set) {
            if (element.result === 'pending') {
              haveItemPedding = true
            }
            if (element.result !== 'lost') {
              isAllLost = false
            }
          }
        })

        if (!haveItemPedding && !isAllLost) {
          this.makeHomogation(true, true)
        } else {
          this.closeModalHomologate()

          if (haveItemPedding) {
            this.$refs.modalHomologateRejected.show()
          } else {
            this.$refs.modalLostHomologateRejected.show()
          }
        }
      } else {
        this.closeModalHomologate()
        this.$refs.modalNoItemHomologateRejected.show()
      }
    },
    canUndoHomologateBidding() {
      if (this.data.contract.length > 0) {
        this.closeModalUndoHomologate()
        this.$refs.modalUndoHomologateRejected.show()
      } else {
        this.makeHomogation(false, false)
      }
    },
    showModalHomologation() {
      if (this.changeDetector.hasChanges()) {
        return
      }
      this.$refs.modalHomologate.show()
    },
    closeModalHomologate() {
      this.$refs.modalHomologate.hide()
    },
    showConfirmDeleteBidModal() {
      this.$refs.confirmDeleteBidModal.show()
    },
    closeConfirmDeleteBidModal() {
      this.$refs.confirmDeleteBidModal.hide()
    },
    showModalUndoHomologation() {
      this.$refs.modalUndoHomologate.show()
    },
    closeModalUndoHomologate() {
      this.$refs.modalUndoHomologate.hide()
    },
    formatCurrency(value) {
      return value.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    isEmpty(string) {
      return string === ''
    },
    toggleCard(isOpen) {
      this.isItemOpen = isOpen
    },
    updateHaveChecks() {
      this.haveChecks = Array.from(this.checkedProductsStorage.values()).some(value => value === true)
    },
    onStoreCheck(id, checked) {
      if (checked) {
        this.checkedProductsStorage.set(id, checked)
      } else {
        this.checkedProductsStorage.delete(id)
      }
      this.updateHaveChecks()
    },
    deleteSelected() {
      this.$refs.refBiddingProductList.haveGainOrLost()
    },
    clearCheckStorage() {
      this.checkedProductsStorage.clear()
      this.updateHaveChecks()
    },
    clearBiddingItems() {
      this.data.items_set = []
      this.changeDetector.reset()
    },
    showConfirmFiledBidModal() {
      this.$refs.confirmFiledBidModal.show()
    },
    closeConfirmFiledBidModal() {
      this.$refs.confirmFiledBidModal.hide()
    },
    filedBid() {
      const dataValue = {
        is_filed: !this.data.is_filed,
      }
      syslic
        .bidding
        .toFileBidding(this.data.id, dataValue)
        .then(() => {
          this.closeConfirmFiledBidModal()
          const text = this.data.is_filed ? 'desarquivada' : 'arquivada'

          this.toast({
            component: ToastificationContent,
            props: {
              title: `Licitação ${text} com sucesso.`,
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.fetchData()
        })
        .catch(() => {
          this.closeConfirmFiledBidModal()
          const text = this.data.is_filed ? 'desarquivar' : 'arquivar'

          const textError = `Não foi possível ${text} a licitação do servidor, por favor entre em contato com o administrador do sistema.`
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao remover licitação.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      mdiClipboardTextOutline,
      mdiPaperclip,
      mdiFileDocumentPlusOutline,
      mdiPackageVariant,
      mdiClipboardTextClockOutline,
      mdiChevronRight,
      mdiFileDocumentOutline,
      mdiPlus,
      mdiAlertCircle,
      mdiCheckCircle,
      mdiCheckDecagram,
      mdiFileExportOutline,
      mdiFileCertificateOutline,
      mdiCommentQuestionOutline,
      mdiFolderOpenOutline,
      mdiContentSaveCheckOutline,
      mdiContentSaveAlertOutline,
      mdiTrashCanOutline,
      mdiPodium,
      mdiArchiveArrowUpOutline,
      mdiArchiveArrowDownOutline,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/base/core/colors/palette-variables.scss";

#biddingEditPage {
  transform: none !important;
}

.margin-bottom-1 {
  margin-bottom: 1rem !important;
}

.card-title {
  color: $primary
}

#biddingHeaderTitle {
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: uppercase;
}

#biddingOwnerImage {
  margin-top: 0.5rem;
}

#biddingLinkTrade {
  color: #9F9DA8;
  font-weight: 500;
}

.biddingOwnerField {
  margin-top: 0.5rem;
}

.biddingHeaderBlock {
  padding-top: 0.20rem;
  padding-bottom: 0.20rem;
}

.biddingHeaderBlock .media{
  margin-bottom: 0.5rem;
}

.biddingHeaderBlock .media-body{
  color: #5e5873;
  margin-top: 0.5rem;
}

.biddingHeaderBlock.left {
  border-left: 1px solid #ebe9f1;
}

.biddingHeaderSectionTitle {
  font-weight: 700;
  font-size: 0.9rem;
  color: #005C96;
  margin-top: 0.75rem;
}

.biddingHeaderSectionContent h5{
  font-weight: 600;
  font-size: 1.1rem;
}

.biddingIconTitle {
  width: 21px;
  height: 21px;
}

.biddingTab .card-header {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.biddingTab .nav-link {
  padding-left: 2rem;
  padding-right: 2rem;
}

.biddingTab .nav-link h4 {
  color: #6e6b7b;
  font-size: 1rem;
}

.biddingTab .nav-link.active {
  background-color: #e2edf3;
}

.biddingTab .nav-link.active h4 {
  color: $primary;
}

.biddingTab .nav-link:hover {
  box-shadow: 0 0 11px rgba(33,33,33,.2);
}

#idBtnShowDetail {
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid #ebe9f1;
}

#idBtnShowDetail:hover,
#idBtnShowDetail:focus {
  animation: pulse 1s;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
}

.biddingTab .tab-pane.card-body {
  padding: 0rem;
}

.biddingTab .tab-content {
  margin-top: -2rem !important;
}

.date_field input{
  min-height: 2.75rem;
}

.date_field input:hover {
  border-color: #ccc;
}

.date_field input:focus {
  border-color: $primary;
}

.date_field.is-invalid input{
  border-color: #ea5455 !important;
}

.date_field .mx-input:disabled,
.mx-input.disabled,
.link-form-control  {
  color: #6e6b7b;
  background-color: #EAEEF5;
  border: transparent !important;
}

.colorIconAlert {
  color: #FF7A00;
}

#idModalEffect .vc-slider {
  width: 100% !important;
}

#idModalEffect .modal-footer {
  display: flex !important;
  flex-direction: row-reverse;
  justify-content: center;

  .btn{
    min-width: 107px;
  }
}

.modalStyle .modal-footer {
  display: flex !important;
  flex-direction: row-reverse;

  justify-content: center;

  padding-bottom: 40px;
}

#btnExportEffect {
  border: none !important;
  span {
    color: #9F9DA8;
  }
}

#btnUndoHomologation {
  background-color: $success_2;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;

  #icon-btn-homologated {
    background-color: white;
    border-radius: 100px;
    padding: 0.3em;

    path {
      color: $success_2;
    }
  }
}

#btnUndoHomologation:hover {
  #icon-btn-homologated path {
    color: $gray-900;
  }
}

.fixLayoutTextModal {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
}

.fixLayoutTitleModal {
  text-align: center;
  font-size: 24px;
  font-weight: bolder;
}

.layoutModalSuccess {
  text-align: center;
  svg {
    color: $success_2;
  }
}

.layoutModalUnsuccess {
  text-align: center;
  svg {
    color: #ea5455;
  }
}

.layoutModalAlert {
  text-align: center;
  svg {
    color: #FF7A00;
  }
}

.layoutTextModalAlert {
  text-align: center;
  font-weight: 500;
}

.link-form-control{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.link-form-control:hover{
  color: inherit;
}

.biddingSectionTitle {
  background-color: $light-blue;
  padding: 0.5rem;
  margin-bottom: 0rem !important;
}

.biddingContentSection {
  padding: 2rem;
}

.b-form-datepicker .form-control {
  white-space: nowrap !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

.buttonsSaveCancel{
  .btn{
    min-width: 107px;
  }
}

.certificateSectionTitle {
  background-color: $light-blue;
  padding: 0.5rem;
  margin-bottom: 0rem !important;
  margin-top: 1rem !important;
}

.attachmentSectionTitle {
  background-color: $light-blue;
  padding: 0.5rem;
  margin-bottom: 0rem !important;
  box-shadow: 0 -10px 20x 10px #22292f1a;
}

.divFooter {
  height: 0.5rem;
  box-shadow: 0 20px 1px 0 #22292f1a;

}

.divSeparation {
  background-color: #EAEAEC;
  height: 1rem;
  margin-top: 20px;

}

#idModalUpdateBid {
  #idModalUpdateBid___BV_modal_content_{
    border-radius:20px !important;
  }
}

.pendingBtn {
  background-color: #FC7033 !important;
  outline: none !important;
  border: none !important;
  color: $white !important;

  &:hover {
    box-shadow: 0 .4rem .8rem #FC703385 !important;
  }
 }
</style>
