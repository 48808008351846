var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-provider',{ref:"inputRef",attrs:{"name":"product","rules":{
    required: _vm.requiredField,
  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.label,"label-for":"product"}},[_c('vue-autosuggest',{class:errors.length > 0 ? 'error-select' : '',attrs:{"no-filter":"","suggestions":_vm.productsOptions,"input-props":{
        id: 'product',
        ref: 'product',
        class: errors.length > 0 ? 'form-control is-invalid' : 'form-control',
        placeholder: _vm.readonly ? '' : _vm.placeholder,
        readonly: _vm.readonly,
      },"limit":_vm.limitElements,"get-suggestion-value":_vm.getProductSelected},on:{"input":_vm.onInputProductChange,"selected":_vm.onSelectedProduct,"blur":_vm.onBlur},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var suggestion = ref.suggestion;
return [(_vm.hasProducts)?_c('span',[_vm._v(_vm._s(suggestion.item.name)+" - R$"+_vm._s(_vm.formatCurrency(suggestion.item.price)))]):_c('span',{style:({'heigth': '500px'})},[_vm._v(" . ")])]}}],null,true),model:{value:(_vm.productSearch),callback:function ($$v) {_vm.productSearch=$$v},expression:"productSearch"}},[_c('template',{slot:"after-suggestions"},[(!_vm.hasProducts)?_c('span',{staticClass:"isEmpty d-flex justify-content-center"},[_vm._v(" Não há produtos cadastrados. ")]):_vm._e()])],2),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errorLabel)+" ")]):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }