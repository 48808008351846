<template>
  <div>
    <div>
      <b-row class="justify-content-center">
        <b-col
          cols="12"
          md="12"
          lg="12"
        >
          <div>
            <b-table
              class="position-relative"
              primary-key="id"
              responsive
              show-empty
              striped
              hover
              empty-text="Não há atestados vinculados."
              :items="certificates"
              :fields="tableColumns"
              :sort-by.sync="sortBy"
              :sort-desc.sync="isSortDirDesc"
              :busy="isLoadingBid"
              @row-clicked="viewCertificate"
            >
              <template #cell(field_id)="field">
                <span class="font-weight-bold d-block text-nowrap">
                  #{{ field.item.document_set.id }}
                </span>
              </template>

              <template #cell(field_client)="field">
                <span class="font-weight-bold text-nowrap">
                  {{ field.item.document_set.client_set.name }}
                </span>
              </template>

              <template #cell(field_status)="field">
                <div
                  class="d-block text-nowrap"
                >
                  <b-badge
                    pill
                    class="text-capitalize"
                    :style="{'background-color': field.item.document_set.status_set.color, 'min-width': '110px'}"
                  >
                    {{ field.item.document_set.status_set.name }}
                  </b-badge>
                </div>
              </template>

              <template #cell(field_end_authentication)="field">
                <span class="font-weight-bold text-nowrap">
                  {{ field.item.document_set.end_authentication }}
                </span>
              </template>

              <template #cell(field_actions)="field">
                <div v-if="!readonly">
                  <b-button
                    class="btn-icon rounded-circle"
                    variant="flat-info"
                    size="md"
                    @click="showConfirmOrCancelDelete(field.item)"
                  >
                    <svg-icon
                      type="mdi"
                      size="18"
                      :path="mdiTrashCanOutline"
                    />
                  </b-button>
                </div>
              </template>
            </b-table>

            <div class="mx-2 mb-2">
              <b-row>
                <b-col
                  cols="12"
                  class="d-flex align-items-center justify-content-center"
                >
                  <b-pagination
                    v-model="currentPageBid"
                    :total-rows="totalCertificates"
                    :per-page="perPage"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>

                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <validation-observer
      v-slot="{ handleSubmit, invalid }"
      ref="refFormObserver"
    >
      <b-modal
        id="idModalAddCertificate"
        ref="modalAddCertificate"
        class="modalCertificate"
        no-close-on-backdrop
        no-close-on-esc
        size="xl"
        centered
        :ok-disabled="invalid"
        hide-footer
        @ok="handleSubmit(save)"
        @cancel="cancel()"
        @hidden="cancel()"
      >
        <template #modal-title>
          Buscar Atestados
        </template>
        <div class="mr-2 mb-1">
          <b-row>
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <b-form-group
                label="Buscar por Nome"
                label-for="idSearch"
                class="w-100 mb-0"
              >
                <b-form-input
                  id="idSearch"
                  v-model.lazy="searchQuery"
                  placeholder="Digite..."
                  type="text"
                  class="d-inline-block"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div>
          <b-table
            class="position-relative"
            primary-key="id"
            responsive
            show-empty
            striped
            hover
            empty-text="Não há atestados cadastrados."
            :items="companyCertificates"
            :fields="tableColumnsModal"
            :sort-by.sync="sortBy"
            :sort-desc.sync="isSortDirDesc"
          >
            <template #cell(field_id)="field">
              <span class="font-weight-bold d-block text-nowrap">
                #{{ field.item.id }}
              </span>
            </template>

            <template #cell(field_client)="field">
              <span class="font-weight-bold text-nowrap">
                {{ field.item.client_set.name }}
              </span>
            </template>

            <template #cell(field_status)="field">
              <div
                class="d-block text-nowrap"
              >
                <b-badge
                  pill
                  class="text-capitalize"
                  :style="{'background-color': field.item.status_set.color, 'min-width': '110px'}"
                >
                  {{ field.item.status_set.name }}
                </b-badge>
              </div>
            </template>

            <template #cell(field_end_authentication)="field">
              <span class="font-weight-bold text-nowrap">
                {{ field.item.end_authentication }}
              </span>
            </template>

            <template #cell(field_item_annotation)="field">
              <span class="font-weight-bold text-nowrap">
                {{ field.item.annotation }}
              </span>
            </template>

            <template #cell(field_actions)="field">
              <b-form-checkbox
                v-model="checkList[field.item.id]"
                name="checkbox-select"
                @input="checkboxChange(field.item)"
              />
            </template>
          </b-table>

          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                class="d-flex align-items-center justify-content-center"
              >
                <b-pagination
                  v-model="currentPageComp"
                  :total-rows="totalItems"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>
            </b-row>
          </div>
          <b-row class="mt-3 mb-1">
            <b-col class="d-flex justify-content-end footer-certificate-modal">
              <div class="buttonsFooter">
                <b-button
                  variant="primary"
                  :disabled="invalid || !hasPermissionToUpdate"
                  @click="save()"
                >
                  Salvar
                </b-button>
                <b-button
                  class="ml-1"
                  variant="outline-primary"
                  @click="cancel()"
                >
                  Cancelar
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-modal>
    </validation-observer>
    <validation-observer
      v-slot="{ handleSubmit, invalid }"
      ref="refFormObserver"
    >
      <b-modal
        ref="modalViewCertificate"
        class="modalCertificate"
        no-close-on-backdrop
        no-close-on-esc
        size="lg"
        centered
        :ok-disabled="invalid"
        hide-footer
        @ok="handleSubmit(save)"
        @cancel="cancel()"
        @hidden="cancel()"
      >
        <template #modal-title>
          Visualização do Atestado
        </template>
        <b-form
          class="mt-1"
        >
          <b-row>
            <b-col
              cols="12"
              md="4"
            >
              <client-select
                id="client"
                v-model="value.client_set"
                :readonly="true"
                :required-field="true"
                @ready="syncLoad.ready('client')"
              />
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <certificate-status-select
                v-model="value.status_set"
                :required-field="true"
                :readonly="true"
                @ready="syncLoad.ready('status')"
              />
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="end_authentication"
              >
                <b-form-group
                  label="Final da Autenticação"
                  label-for="end_authentication"
                >
                  <b-form-input
                    id="end_authentication"
                    v-model="value.end_authentication"
                    name="certificate"
                    placeholder="Final da Autenticação"
                    :readonly="true"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <validation-provider
                #default="validationContext"
                name="annotation"
              >
                <b-form-group
                  label="Anotações"
                  label-for="annotation"
                >
                  <b-form-textarea
                    id="annotation"
                    v-model="value.annotation"
                    trim
                    rows="7"
                    :placeholder="'Anotações'"
                    :readonly="true"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <div
            v-if="value.files_set.length > 0"
          >
            <b-row
              v-for="(file, index) in value.files_set"
              :key="index"
            >
              <b-col
                cols="12"
                class="px-0"
              >
                <div
                  class="d-flex justify-content-between"
                  :style="index % 2 == 0 ? {'background-color': '#F6F6F9'} : {}"
                >
                  <div
                    class="d-flex align-items-center ml-50"
                  >
                    <b-button
                      class="btn-icon rounded-circle"
                      variant="flat-primary"
                      size="md"
                      @click="downloadFile(file)"
                    >
                      <feather-icon
                        icon="DownloadIcon"
                        size="16"
                      />
                    </b-button>
                    <span class="d-flex align-items-center">{{ file.file_name }}</span>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
          <div
            v-else
            class="font-italic p-1"
          >
            Não existe arquivo para ser baixado.
          </div>
        </b-form>
      </b-modal>
    </validation-observer>
    <div
      v-if="!readonly"
      class="d-flex justify-content-end"
    >
      <b-button
        v-b-tooltip.hover
        v-b-modal.idModalAddCertificate
        class="btn-icon rounded-circle mx-3"
        variant="primary"
        size="md"
        :disabled="readonly"
        title="Buscar Atestado"
      >
        <svg-icon
          type="mdi"
          :path="mdiFileSearchOutline"
        />
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BBadge,
  BForm,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BFormInvalidFeedback,
  BFormCheckbox,
  BRow,
  BCol,
  BButton,
  BTable,
  BPagination,
  BModal,
  VBModal,
  VBTooltip,
} from 'bootstrap-vue'

import {
  mdiFileSearchOutline,
  mdiTrashCanOutline,
} from '@mdi/js'

import {
  useToast,
} from 'vue-toastification/composition'

import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'

import {
  required,
  confirmed,
} from '@validations'

import SvgIcon from '@jamescoyle/vue-icon'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import syslic from '@/syslic'
import SyncLoad from '@/components/utils/syncLoad'
import ClientSelect from '@/components/client/ClientSelect.vue'
import CertificateStatusSelect from '@/views/company/company-certificates/components/CertificateStatusSelect.vue'

export default {
  components: {
    BBadge,
    BForm,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BFormInvalidFeedback,
    BFormCheckbox,
    BRow,
    BCol,
    BButton,
    BTable,
    BPagination,
    BModal,
    ClientSelect,
    CertificateStatusSelect,
    SvgIcon,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    VBModal,
    'b-tooltip': VBTooltip,
  },
  props: {
    biddingId: {
      type: [Number, String],
      required: true,
      default: null,
    },
    companyId: {
      type: [Number, String],
      required: true,
      default: null,
    },
    readonly: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    const perPage = 10
    const totalItems = 0
    const totalCertificates = 0
    const currentPageComp = 1
    const currentPageBid = 1
    const searchQuery = ''
    const sortBy = 'name'
    const isSortDirDesc = false
    const isLoadingBid = true
    const isLoadingCom = true

    const tableColumns = [
      {
        key: 'field_id',
        label: 'ID',
        thClass: 'tableHeader',
      },
      {
        key: 'field_client',
        label: 'Orgão',
        thClass: 'tableHeader',
      },
      {
        key: 'field_status',
        label: 'Status',
        thClass: 'tableHeader',
      },
      {
        key: 'field_end_authentication',
        label: 'Final da Autenticação',
        thClass: 'tableHeader',
      },
    ]
    const fieldActions = {
      key: 'field_actions',
      label: '',
      thClass: 'tableHeader',
    }

    if (!this.readonly) {
      tableColumns.push(fieldActions)
    }

    const tableColumnsModal = [
      {
        key: 'field_id',
        label: 'ID',
      },
      {
        key: 'field_client',
        label: 'Orgão',
      },
      {
        key: 'field_status',
        label: 'Status',
      },
      {
        key: 'field_end_authentication',
        label: 'Final da Autenticação',
      },
      {
        key: 'field_actions',
        label: '',
      },
    ]

    const companyCertificates = []

    const certificates = []

    const checkList = []
    const selectedList = []

    const value = {
      annotation: '',
      client: '',
      client_set: '',
      company: this.companyId,
      company_set: '',
      end_authentication: '',
      files_set: '',
      id: '',
      status: '',
      status_set: '',
    }

    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('certificate')
    syncLoad.addTrigger('companyCertificate')

    syncLoad.onReady(() => {
      this.isLoadingBid = false
      this.isLoadingCom = false
      this.$emit('ready', true)
    })

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(this.value)

    return {
      required,
      confirmed,
      refFormObserver,
      getValidationState,
      perPage,
      totalItems,
      totalCertificates,
      currentPageComp,
      currentPageBid,
      searchQuery,
      sortBy,
      isSortDirDesc,
      isLoadingCom,
      isLoadingBid,
      tableColumns,
      fieldActions,
      tableColumnsModal,
      companyCertificates,
      certificates,
      value,
      checkList,
      selectedList,
      syncLoad,
    }
  },
  computed: {
    hasPermissionToRead() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.company.can_read
    },
    hasPermissionToWrite() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.company.can_write
    },
    hasPermissionToUpdate() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.company.can_edit
    },
    hasPermissionToDelete() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.company.can_delete
    },
  },
  watch: {
    companyId: 'fetchCertificatesOfCompany',
    currentPageComp: 'fetchCertificatesOfCompany',
    currentPageBid: 'fetchData',
    searchQuery: function updateSearchQuery(value) {
      this.currentPageComp = 1
      this.searchQuery = value
      this.fetchCertificatesOfCompany()
    },
  },
  created() {
    this.fetchData()
    this.fetchCertificatesOfCompany()
  },
  methods: {
    fetchCertificatesOfCompany() {
      if (this.companyId) {
        this.isLoadingCom = true
        syslic
          .company
          .certificate
          .fetchAllCertificate({
            p: this.currentPageComp,
            page_size: this.perPage,
            search: this.searchQuery,
            company: this.companyId,
          })
          .then(response => {
            this.totalItems = response.count
            this.companyCertificates = response.results
            this.syncLoad.ready('companyCertificate')
            this.isLoadingCom = false
          })
          .catch(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Falha ao ler os dados.',
                text: 'Não foi possível ler os dados dos atestados de capacidade técnica da empresa, por favor entre em contato com o administrador do sistema.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                timeout: false,
              },
            })
            this.syncLoad.ready('companyCertificate')
            this.isLoadingCom = false
          })
      }
    },
    fetchData() {
      const params = {
        p: this.currentPageBid,
        page_size: 10,
        search: this.searchQuery,
        bidding: this.biddingId,
      }
      this.isLoadingBid = true

      syslic
        .bidding
        .file
        .fetchCompanyCertificates(params)
        .then(response => {
          this.totalCertificates = response.data.count
          this.certificates = response.data.results
          this.syncLoad.ready('certificate')
          this.isLoadingBid = false
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao ler os dados.',
              text: 'Não foi possível ler os dados dos atestados de capacidade técnica, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
          this.syncLoad.ready('certificate')
          this.isLoadingBid = false
        })
    },
    deleteCertificate(item) {
      syslic
        .bidding
        .file
        .deleteCompanyCertificate(item.id)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Atestado removido com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.currentPageBid = 1
          this.fetchData()
        })
        .catch(() => {
          const textError = 'Não foi possível remover este atestado de capacidade técnica, por favor entre em contato com o administrador do sistema.'

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao remover atestado de capacidade técnica.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    showConfirmOrCancelDelete(item) {
      this.$bvModal
        .msgBoxConfirm('Por favor, confirme que você deseja remover este atestado.', {
          id: 'confirmation_box',
          title: 'Confirmar?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteCertificate(item)
          }
        })
    },
    addCertificates() {
      /* eslint arrow-body-style: ["off"] */
      const promiseList = []
      const listFiltered = this.selectedList.filter(element1 => {
        return !this.certificates.some(element2 => element2.document_set.id === element1.id)
      })
      listFiltered.forEach(element => {
        const params = {
          document: element.id,
          bidding: this.biddingId,
        }
        const promise = new Promise(resolve => {
          syslic
            .bidding
            .file
            .addCompanyCertificate(params)
            .then(() => {
              resolve()
            })
        })
        promiseList.push(promise)
      })
      const promise = new Promise(() => {
        Promise.allSettled(promiseList)
          .then(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Atestado adicionado com sucesso.',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
            this.fetchData()
          })
          .catch(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Falha adicionar atestado.',
                text: 'Não foi possível adicionar o atestado, por favor entre em contato com o administrador do sistema.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                timeout: false,
              },
            })
          })
      })
      return promise
    },
    viewCertificate(item) {
      this.value = item.document_set
      this.$refs.modalViewCertificate.show()
    },
    save() {
      this.addCertificates()
      this.$refs.modalAddCertificate.hide()
      this.clearChecks()
    },
    cancel() {
      this.$refs.modalAddCertificate.hide()
      this.value = {
        annotation: '',
        client: '',
        client_set: '',
        company: this.companyId,
        company_set: '',
        end_authentication: '',
        files_set: '',
        id: '',
        status: '',
        status_set: '',
      }
    },
    checkboxChange(val) {
      if (this.checkList[val.id]) {
        this.selectedList[val.id] = val
      } else {
        this.selectedList.splice(val.id, 1)
      }
    },
    clearChecks() {
      this.checkList = []
    },
    downloadFile(file) {
      syslic
        .company
        .certificateFile
        .fetchCertificateFile(file.id)
        .then(response => {
          const linkSource = `data:application/file;base64,${response.data.file}`
          const downloadLink = document.createElement('a')
          const fileName = file.file_name

          downloadLink.href = linkSource
          downloadLink.download = fileName
          downloadLink.click()
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha para realizar o download do documento.',
              text: 'Não foi possível realizar o download, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      mdiFileSearchOutline,
      mdiTrashCanOutline,
    }
  },
}
</script>

<style lang="scss" scoped>
.smallField {
  width: 5px !important;
}

.per-page-selector {
  width: 90px;
}

.dropdownfix .dropdown.show{
  position: fixed;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "@core/scss/base/core/colors/palette-variables.scss";

.card-title {
  color: $primary
}

.modalCertificate .vc-slider {
  width: 100% !important;
}

.modalCertificate .modal-footer {
  display: flex !important;
  flex-direction: row-reverse;
  justify-content: end;
}

.buttonsFooter .btn {
  min-width: 107px;
}

.footer-certificate-modal {
  display: flex;
  width: 100%;

  justify-content: space-between;
}

.tableHeader {
  background-color: #ffffff !important;
  border-bottom: 0 !important;
}

.tableHeader * {
  color: #5e5873
}
</style>
