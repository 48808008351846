<template>
  <div>
    <div class="mx-2 my-2 pt-50 px-50">
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="payment_term"
            :rules="{
              required: false,
            }"
          >
            <b-form-group
              label="Prazo de Pagamento"
              label-for="payment_term"
            >
              <b-form-input
                id="payment_term"
                v-model="bidding.payment_term"
                :state="readonly ? null : getValidationState(validationContext)"
                name="payment_term"
                :placeholder="'Prazo de Pagamento'"
                :readonly="readonly"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="warranty_term"
            :rules="{
              required: false,
            }"
          >
            <b-form-group
              label="Prazo de Garantia"
              label-for="warranty_term"
            >
              <b-form-input
                id="warranty_term"
                v-model="bidding.warranty_term"
                :state="readonly ? null : getValidationState(validationContext)"
                name="warranty_term"
                :placeholder="'Prazo de Garantia'"
                :readonly="readonly"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="deadline"
            :rules="{
              required: false,
            }"
          >
            <b-form-group
              label="Prazo de Entrega/Execução"
              label-for="deadline"
            >
              <b-form-input
                id="deadline"
                v-model="bidding.deadline"
                :state="readonly ? null : getValidationState(validationContext)"
                name="deadline"
                :placeholder="'Prazo de Entrega/Execução'"
                :readonly="readonly"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="proposal_validity"
          >
            <b-form-group
              label="Validade da Proposta"
              label-for="proposal_validity"
            >
              <b-form-input
                id="proposal_validity"
                v-model="bidding.proposal_validity"
                :state="readonly ? null : getValidationState(validationContext)"
                name="proposal_validity"
                :placeholder="'Validade da Proposta'"
                :readonly="readonly"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="crier"
          >
            <b-form-group
              label="Pregoeiro"
              label-for="crier"
            >
              <b-form-input
                id="crier"
                v-model="bidding.crier"
                :state="readonly ? null : getValidationState(validationContext)"
                name="crier"
                :placeholder="'Pregoeiro'"
                :readonly="readonly"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="d-flex align-items-center"
        >
          <b-form-group
            label="Exclusivo ME/EPP"
            label-for="exclusive_me_epp"
          >
            <b-form-checkbox
              v-model="bidding.exclusive_me_epp"
              class="mt-50"
              switch
              :disabled="readonly"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="phone_number"
            :rules="{
              required: false,
              min: 14,
            }"
          >
            <b-form-group
              label="Telefone"
              label-for="phone_number"
            >
              <b-form-input
                id="phone_number"
                v-model="bidding.phone_number"
                v-mask="getMask('phone_number')"
                :state="readonly ? null : getValidationState(validationContext)"
                name="phone_number"
                :placeholder="'Telefone'"
                :readonly="readonly"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="email"
          >
            <b-form-group
              label="E-mail"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="bidding.email"
                :state="readonly ? null : getValidationState(validationContext)"
                name="email"
                :placeholder="'email@email.com'"
                :readonly="readonly"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="d-flex align-items-center"
        >
          <b-form-group
            label="Registro de Preço"
            label-for="price_registry"
          >
            <b-form-checkbox
              v-model="bidding.price_registry"
              class="mt-50"
              switch
              :disabled="readonly"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <div>
      <b-card-title
        class="p-50 mx-0 mb-0"
        :style="{'background-color': '#E3EDF3'}"
      >
        <span
          class="d-inline ml-1"
          :style="{'font-size': '1.18rem'}"
        >
          Endereço de Entrega/Execução
        </span>
      </b-card-title>
      <address-edit-fields
        :address="bidding"
        class="mx-50"
        :is-view-mode="readonly"
        :required-fields="[]"
        :enabled-fields="enabledFieldsAddress"
      />
    </div>
    <div>
      <b-card-title
        class="p-50 mx-0"
        :style="{'background-color': '#E3EDF3'}"
      >
        <span
          class="d-inline ml-1"
          :style="{'font-size': '1.18rem'}"
        >
          Exigências
        </span>
      </b-card-title>
      <b-row
        v-if="isRequirementLoad"
        class="mx-1 px-50"
      >
        <b-col
          v-for="(requirement, index) in bidding.requirements"
          :key="requirement.id"
          cols="12"
          md="4"
        >
          <b-form-checkbox
            :key="requirement.id"
            v-model="bidding.requirements[index].visible"
            :value="true"
            :unchecked-value="false"
            class="mb-1"
            :disabled="readonly"
          >
            {{ bidding.requirements[index].requirement }}
          </b-form-checkbox>
        </b-col>
      </b-row>
    </div>
    <hr>
    <div class="mx-2 px-50">
      <b-row>
        <b-col>
          <validation-provider
            #default="validationContext"
            name="observation"
          >
            <b-form-group
              label="Observações"
              label-for="observation"
            >
              <b-form-textarea
                id="observation"
                v-model="bidding.observation"
                :state="readonly ? null : getValidationState(validationContext)"
                trim
                :placeholder="readonly ? '' : 'Observações'"
                :readonly="readonly"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormTextarea,
  BCardTitle,
} from 'bootstrap-vue'

import {
  ValidationProvider,
} from 'vee-validate'

import {
  useToast,
} from 'vue-toastification/composition'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import AddressEditFields from '../../../components/address/AddressEditFields.vue'
import syslic from '@/syslic'

export default {
  components: {
    BRow,
    BCol,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormTextarea,
    BCardTitle,
    ValidationProvider,
    AddressEditFields,
  },
  model: {
    prop: 'biddingValue',
    event: 'biddingChange',
  },
  props: {
    biddingValue: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: true,
    },
    isLoaded: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation(this.bidding)

    const enabledFieldsAddress = [
      'zip_code',
      'address',
      'number',
      'complement',
      'neighborhood',
      'state',
      'city',
      'country',
    ]

    const isRequirementLoad = false

    return {
      refFormObserver,
      getValidationState,
      enabledFieldsAddress,
      isRequirementLoad,
    }
  },
  computed: {
    bidding: {
      get() {
        return this.biddingValue
      },
      set(value) {
        this.$emit('biddingChange', value)
      },
    },
    hasPermissionToWrite() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.bidding_settings.can_write
    },
  },
  watch: {
    isLoaded: function updateRequirements(value) {
      if (value) {
        this.fetchRequirements()
      }
    },
  },
  methods: {
    fetchRequirements() {
      syslic
        .bidding
        .requirements
        .fetchAllRequirements()
        .then(response => {
          this.loadRequirements(response.results)
          this.$emit('ready', true)
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao ler os dados.',
              text: 'Não foi possível ler os dados do servidor, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
          this.$emit('ready', true)
        })
    },
    getMask(field) {
      let mask = '(##) ####-####'
      if (this.bidding[field]) {
        if (this.bidding[field].length > 14) {
          mask = '(##) #####-####'
        }
      }

      return mask
    },
    loadRequirements(requirements) {
      const reqList = []
      let reqListAux = []
      if (this.bidding.requirements) {
        reqListAux = this.bidding.requirements
      }
      if (requirements !== undefined) {
        requirements.forEach(element => {
          const req = { requirement: element.name, visible: false }
          reqList.push(req)
          if (!this.hasRequirement(req, this.bidding.requirements)) {
            reqListAux.push(req)
          }
        })
      }

      reqListAux.forEach((element, index) => {
        if (!this.hasRequirement(element, reqList)) {
          if (element.visible === false) {
            reqListAux.splice(index, 1)
          }
        }
      })

      this.bidding.requirements = reqListAux
      this.isRequirementLoad = true
    },
    hasRequirement(req, listRequirements) {
      let hasReq = false
      if (listRequirements !== undefined) {
        listRequirements.forEach(element => {
          if (req.requirement === element.requirement) {
            hasReq = true
          }
        })
      }
      return hasReq
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
    }
  },
}
</script>

<style scoped lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

#observation {
  min-height: 13.2rem;
}
</style>
