<template>
  <div>
    <div
      v-if="!isOpen"
    >
      <b-card
        class="mb-0 biddingProductCard"
        :class="isEven ? 'itemEven' : 'itemOdd'"
        no-body
      >
        <b-card-body
          class="pt-50 pb-50"
        >
          <b-row>
            <b-col
              cols="12"
              md="1"
              class="d-flex justify-content-start align-self-center"
            >
              <div>
                <svg-icon
                  v-if="item.result !== 'pending'"
                  :class="['d-inline-block', 'mr-50', item.result == 'gain' ? 'biddingGainOk' : 'biddingGainFail']"
                  type="mdi"
                  :path="mdiTrophyOutline"
                />
                <svg-icon
                  v-if="isItemOk"
                  class="d-inline-block biddingMarginOk mr-50"
                  type="mdi"
                  :path="mdiPackageVariantClosedCheck"
                />
                <svg-icon
                  v-else
                  class="d-inline-block biddingMarginFail mr-50"
                  type="mdi"
                  :path="mdiPackageVariantClosedRemove"
                />
                <svg-icon
                  v-if="item.type === 'compound'"
                  class="d-inline-block biddingIconType mr-50"
                  type="mdi"
                  :path="mdiAlphaCBox"
                />
                <svg-icon
                  v-if="item.type === 'compound'"
                  :id="`buttonInformationCompound${item.id}`"
                  class="d-inline-block biddingIconType cursor-pointer"
                  type="mdi"
                  :path="mdiTagMultipleOutline"
                />
                <svg-icon
                  v-if="item.type === 'unit'"
                  class="d-inline-block biddingIconType ml-50"
                  type="mdi"
                  :path="mdiAlphaUBoxOutline"
                />
                <b-popover
                  :target="`buttonInformationCompound${item.id}`"
                  triggers="hover"
                  placement="bottomright"
                >
                  <div
                    v-if="item.product_list.length > 0"
                  >
                    <div>
                      <span
                        class="font-weight-bolder"
                      >
                        Marca:
                      </span>

                      <template v-if="item.product_list[0].product_set.brand">
                        <span>
                          {{ item.product_list[0].product_set.brand }}
                        </span>
                      </template>

                      <template v-else>
                        <span>-</span>
                      </template>
                    </div>

                    <div>
                      <span
                        class="font-weight-bolder"
                      >
                        Modelo:
                      </span>
                      <template v-if="item.product_list[0].product_set.model">
                        <span>
                          {{ item.product_list[0].product_set.model }}
                        </span>
                      </template>

                      <template v-else>
                        <span>-</span>
                      </template>
                    </div>
                  </div>
                  <div v-else>
                    <span>Não existem itens atribuídos para este produto.</span>
                  </div>
                </b-popover>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="11"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="1"
                  class="biddingCardItemBlock biddingCardItemSection"
                >
                  <div>
                    <span
                      class="biddingCardItemSectionTitle"
                    >
                      Nº ITEM:
                    </span>
                    <b-media
                      class="biddingCardItemSectionContent"
                    >
                      <b-row>
                        <b-col>
                          <h5
                            v-if="item.number"
                            class="mb-0 d-flex"
                          >
                            {{ item.number }}
                          </h5>
                          <h5 v-else>
                            -
                          </h5>
                        </b-col>
                      </b-row>
                    </b-media>
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  md="2"
                  class="biddingCardItemBlock biddingCardItemSection"
                >
                  <div
                    class="ml-sm-1"
                  >
                    <span
                      class="biddingCardItemSectionTitle"
                    >
                      NOME DO PRODUTO:
                    </span>
                    <b-media
                      class="biddingCardItemSectionContent"
                    >
                      <b-row>
                        <b-col>
                          <h5
                            v-if="item.name"
                            class="mb-0 d-flex"
                          >
                            {{ item.name }}
                          </h5>
                          <h5 v-else>
                            -
                          </h5>
                        </b-col>
                      </b-row>
                    </b-media>
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  md="2"
                  class="biddingCardItemBlock biddingCardItemSection"
                >
                  <div
                    class="ml-sm-1"
                  >
                    <span
                      class="biddingCardItemSectionTitle"
                    >
                      QUANTIDADE:
                    </span>
                    <b-media
                      class="biddingCardItemSectionContent"
                    >
                      <b-row>
                        <b-col>
                          <h5
                            class="mb-0 d-flex"
                          >
                            {{ item.quantity }}
                          </h5>
                        </b-col>
                      </b-row>
                    </b-media>
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  md="2"
                  class="biddingCardItemBlock biddingCardItemSection"
                >
                  <div
                    class="ml-sm-1"
                  >
                    <span
                      class="biddingCardItemSectionTitle"
                    >
                      VALOR UNITÁRIO MÍNIMO:
                    </span>
                    <b-media
                      class="biddingCardItemSectionContent"
                    >
                      <b-row>
                        <b-col>
                          <h5
                            class="mb-0 d-flex"
                          >
                            R$ {{ item.price_min | formatCurrency() }}
                          </h5>
                        </b-col>
                      </b-row>
                    </b-media>
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  md="2"
                  class="biddingCardItemBlock biddingCardItemSection"
                >
                  <div
                    class="ml-sm-1"
                  >
                    <span
                      class="biddingCardItemSectionTitle"
                    >
                      VALOR TOTAL MÍNIMO:
                    </span>
                    <b-media
                      class="biddingCardItemSectionContent"
                    >
                      <b-row>
                        <b-col>
                          <h5
                            class="mb-0 d-flex"
                          >
                            R$ {{ (item.price_min * item.quantity) | formatCurrency() }}
                          </h5>
                        </b-col>
                      </b-row>
                    </b-media>
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  md="2"
                  class="biddingCardItemBlock biddingCardItemSection"
                >
                  <div
                    class="ml-sm-1"
                  >
                    <span
                      class="biddingCardItemSectionTitle"
                    >
                      {{ item.result === 'gain'? 'VALOR GANHO': 'VALOR ESTIMADO' }}
                    </span>
                    <b-media
                      class="biddingCardItemSectionContent"
                    >
                      <b-row>
                        <b-col>
                          <h5
                            class="mb-0 d-flex"
                          >
                            R$ {{ item.price | formatCurrency() }}
                          </h5>
                        </b-col>
                      </b-row>
                    </b-media>
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  md="1"
                  class="p-0 d-flex justify-content-end"
                >
                  <div
                    class="d-flex align-items-center justify-content-center"
                  >
                    <b-dropdown
                      v-if="!readonly"
                      id="idDropdownMenu"
                      variant="link"
                      pill
                      no-caret
                      :dropleft="true"
                      :boundary="'scrollParent'"
                      :right="$store.state.appConfig.isRTL"
                    >

                      <template #button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="align-middle text-body"
                          style="color: #005C96"
                        />
                      </template>

                      <b-dropdown-item
                        @click="openModalItemConvert()"
                      >
                        <svg-icon
                          type="mdi"
                          :path="mdiSwapHorizontalCircleOutline"
                        />
                        <span class="align-middle ml-50">Alterar Tipo do Item</span>
                      </b-dropdown-item>

                      <b-dropdown-item
                        @click="openModalItemResult()"
                      >
                        <svg-icon
                          type="mdi"
                          :path="mdiListStatus"
                        />
                        <span class="align-middle ml-50">Editar Status do Item</span>
                      </b-dropdown-item>

                      <b-dropdown-item
                        @click="verifyItemIsGain()"
                      >
                        <svg-icon
                          type="mdi"
                          :path="mdiTrashCanOutline"
                        />
                        <span class="align-middle ml-50">Remover</span>
                      </b-dropdown-item>
                    </b-dropdown>

                    <b-button
                      class="btn-icon rounded-circle"
                      variant="flat-primary"
                      size="sm"
                      @click="toggleCard()"
                    >
                      <svg-icon
                        type="mdi"
                        :path="mdiChevronRight"
                      />
                    </b-button>

                    <div
                      v-if="!readonly && !lote"
                      class="d-flex align-items-center justify-content-center ml-1"
                    >
                      <b-form-checkbox
                        :id="`checkboxItem${item.id}`"
                        v-model="itemCheck"
                        @input="handleCheckbox"
                      />
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </div>
    <div v-else>
      <validation-observer
        v-slot="{ handleSubmit, }"
        ref="refFormObserver"
      >
        <b-form>
          <b-card
            class="mb-0 biddingProductCard"
            :class="isEven ? 'itemEven' : 'itemOdd'"
            no-body
          >
            <b-card-body
              class="pt-1 pb-0"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="1"
                  class="d-flex px-50"
                >
                  <div
                    class="d-inline-block"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="number_item"
                      :rules="{
                        required: true,
                        min: 1,
                      }"
                    >
                      <b-form-group
                        label="Nº Item"
                        label-for="number_item"
                        label-class="biddingFormLabel"
                      >
                        <cleave
                          id="number_item"
                          v-model="item.number"
                          class="form-control"
                          :class="validationContext.errors.length > 0 ? 'form-control is-invalid' : 'form-control'"
                          :options="options.number"
                          :placeholder="readonly ? '' : 'Nº Item'"
                          :readonly="isReadonly"
                        />
                        <small
                          v-if="validationContext.errors.length > 0"
                          class="text-danger"
                        >
                          Campo obrigatório
                        </small>
                      </b-form-group>
                    </validation-provider>
                  </div>
                </b-col>
                <b-col
                  class="px-50"
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="validationContext"
                    name="item_edital"
                    rules="required"
                  >
                    <b-form-group
                      label="Nome do Produto"
                      label-for="item_edital"
                    >
                      <b-form-input
                        id="item_edital"
                        v-model="item.name"
                        placeholder="Nome do Produto"
                        :state="readonly ? null : (validationContext.errors.length > 0 ? false : null)"
                        :readonly="isReadonly"
                      />
                      <small
                        v-if="validationContext.errors.length > 0"
                        class="text-danger"
                      >
                        O campo Nome do Produto é obrigatório
                      </small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  class="px-50"
                  cols="12"
                  md="1"
                >
                  <validation-provider
                    #default="validationContext"
                    name="quantity"
                    :rules="{
                      required: true,
                      min: 1,
                    }"
                  >
                    <b-form-group
                      label="Quantidade"
                      label-for="quantity"
                      label-class="biddingFormLabel"
                    >
                      <cleave
                        id="quantity"
                        v-model="item.quantity"
                        class="form-control"
                        :class="validationContext.errors.length > 0 ? 'form-control is-invalid' : 'form-control'"
                        :options="options.number"
                        :placeholder="readonly ? '' : 'Qtd'"
                        :readonly="isReadonly"
                      />
                      <small
                        v-if="validationContext.errors.length > 0"
                        class="text-danger"
                      >
                        Campo obrigatório
                      </small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="2"
                  class="biddingCardItemBlock biddingCardItemSection"
                >
                  <div
                    class="ml-sm-2"
                  >
                    <span
                      class="biddingCardItemSectionTitle"
                    >
                      VALOR UNITÁRIO MÍNIMO:
                    </span>
                    <b-media
                      class="biddingCardItemSectionContent"
                    >
                      <b-row>
                        <b-col>
                          <h5
                            class="mb-0 d-flex"
                          >
                            R$ {{ item.price_min | formatCurrency() }}
                          </h5>
                        </b-col>
                      </b-row>
                    </b-media>
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  md="2"
                  class="biddingCardItemBlock biddingCardItemSection"
                >
                  <div
                    class="ml-sm-1"
                  >
                    <span
                      class="biddingCardItemSectionTitle"
                    >
                      VALOR TOTAL MÍNIMO:
                    </span>
                    <b-media
                      class="biddingCardItemSectionContent"
                    >
                      <b-row>
                        <b-col>
                          <h5
                            class="mb-0 d-flex"
                          >
                            R$ {{ (item.price_min * item.quantity) | formatCurrency() }}
                          </h5>
                        </b-col>
                      </b-row>
                    </b-media>
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  md="2"
                  class="biddingCardItemBlock biddingCardItemSection"
                >
                  <div
                    class="ml-sm-1"
                  >
                    <span
                      class="biddingCardItemSectionTitle"
                    >
                      {{ item.result === 'gain'? 'VALOR GANHO': 'VALOR ESTIMADO' }}
                    </span>
                    <b-media
                      class="biddingCardItemSectionContent"
                    >
                      <b-row>
                        <b-col>
                          <h5
                            class="mb-0 d-flex"
                          >
                            R$ {{ item.price | formatCurrency() }}
                          </h5>
                        </b-col>
                      </b-row>
                    </b-media>
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  md="1"
                  class="text-right pr-50"
                >
                  <b-button
                    v-if="!isOpen && !readonly"
                    class="btn-icon rounded-circle"
                    variant="flat-info"
                    size="sm"
                    @click="remove()"
                  >
                    <svg-icon
                      type="mdi"
                      size="18"
                      :path="mdiTrashCanOutline"
                    />
                  </b-button>

                  <b-button
                    class="btn-icon rounded-circle"
                    variant="flat-primary"
                    size="sm"
                    @click="toggleCard()"
                  >
                    <svg-icon
                      v-if="isOpen"
                      type="mdi"
                      :path="mdiChevronDown"
                    />
                    <svg-icon
                      v-else
                      type="mdi"
                      :path="mdiChevronRight"
                    />
                  </b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  class="pl-50 pr-50"
                  cols="12"
                  md="7"
                >
                  <b-row>
                    <b-col>
                      <validation-provider
                        #default="validationContext"
                        name="description"
                      >
                        <b-form-group
                          label="Descrição do Produto no Edital"
                          label-for="description"
                        >
                          <b-form-textarea
                            id="description"
                            v-model="item.description"
                            class="biddingProductDescription"
                            :state="readonly ? null : getValidationState(validationContext)"
                            trim
                            :placeholder="readonly ? '' : 'Descrição'"
                            :readonly="isReadonly"
                          />

                          <small class="text-danger">
                            {{ validationContext.errors[0] }}
                          </small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <b-col>
                      <b-table
                        class="position-relative"
                        primary-key="id"
                        responsive
                        show-empty
                        striped
                        hover
                        empty-text="Não há itens cadastrados."
                        :items="item.product_list"
                        :fields="tableColumns"
                      >
                        <template #cell(name)="field">
                          <span class="font-weight-bold text-nowrap">
                            {{ field.item.product_set.name }}
                          </span>
                        </template>

                        <template #cell(quantity)="field">
                          <span class="text-nowrap">
                            {{ field.item.quantity }}
                          </span>
                        </template>

                        <template #cell(price)="field">
                          <span class="text-nowrap">
                            R$ {{ field.item.price | formatCurrency() }}
                          </span>
                        </template>

                        <template
                          v-if="!isReadonly"
                          #cell(field_actions)="field"
                        >
                          <div>
                            <b-button
                              class="btn-icon rounded-circle"
                              variant="flat-info"
                              size="md"
                              @click="deleteItem(field.index)"
                            >
                              <svg-icon
                                type="mdi"
                                size="18"
                                :path="mdiTrashCanOutline"
                              />
                            </b-button>
                          </div>
                        </template>
                      </b-table>
                    </b-col>
                  </b-row>
                  <b-row v-if="!isReadonly">
                    <b-col
                      class="d-flex justify-content-center align-self-center"
                    >
                      <b-button
                        v-b-tooltip.hover
                        class="btn-icon rounded-circle m-1"
                        variant="primary"
                        size="md"
                        title="Adicionar item"
                        @click="openItem()"
                      >
                        <svg-icon
                          type="mdi"
                          :path="mdiPlus"
                        />
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-row
                    v-if="item.product_list.length > 0"
                    class="mt-1"
                  >
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <b-form-group
                        label="Marca"
                        label-for="brand"
                        class="font-weight-bolder"
                      >
                        <b-form-input
                          id="brand"
                          v-model="item.product_list[0].product_set.brand"
                          placeholder=""
                          :readonly="true"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <b-form-group
                        label="Modelo"
                        label-for="model"
                        class="font-weight-bolder"
                      >
                        <b-form-input
                          id="model"
                          v-model="item.product_list[0].product_set.model"
                          placeholder=""
                          :readonly="true"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col
                  cols="12"
                  md="5"
                >
                  <b-row>
                    <b-col
                      cols="12"
                    >
                      <b-card
                        id="biddingCardItemPrice"
                        no-body
                        class="biddingProductCard mb-1"
                        :class="isEven ? 'itemEven' : 'itemOdd'"
                      >
                        <b-card-body
                          class="pt-1"
                        >
                          <b-row>
                            <b-col
                              class="px-50"
                              cols="12"
                              sm="10"
                              xl="11"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                name="unitary_value"
                                :rules="{
                                  required: true,
                                }"
                              >
                                <b-form-group
                                  label="Valor Unitário"
                                  label-for="unitary_value"
                                >
                                  <b-input-group
                                    prepend="R$"
                                    :class="[isReadonly ? 'input-readonly': '', errors.length > 0 ? 'is-invalid' : '']"
                                  >
                                    <cleave
                                      id="unitary_value"
                                      v-model="item.price"
                                      name="unitary_value"
                                      :class="errors.length > 0 ? 'form-control is-invalid' : 'form-control'"
                                      :options="options.price"
                                      placeholder="0,00"
                                      :readonly="isReadonly"
                                      @blur="adjustItemDecimalPlaces('price', 2)"
                                    />
                                  </b-input-group>
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </b-form-group>
                              </validation-provider>
                            </b-col>
                            <b-col
                              cols="12"
                              sm="2"
                              xl="1"
                              class="d-flex justify-content-start align-self-center"
                            >
                              <div>
                                <svg-icon
                                  v-if="isItemOk"
                                  class="d-inline-block biddingMarginOk"
                                  type="mdi"
                                  :path="mdiPackageVariantClosedCheck"
                                />
                                <svg-icon
                                  v-else
                                  class="d-inline-block biddingMarginFail"
                                  type="mdi"
                                  :path="mdiPackageVariantClosedRemove"
                                />
                              </div>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col
                              class="px-50"
                              cols="6"
                            >
                              <b-form-group
                                label="Custo do Produto"
                                label-for="cost_product"
                              >
                                <b-input-group
                                  prepend="R$"
                                  class="input-readonly"
                                >
                                  <b-form-input
                                    id="margin"
                                    v-model="cost"
                                    placeholder=""
                                    :readonly="true"
                                  />
                                </b-input-group>
                              </b-form-group>
                            </b-col>
                            <b-col
                              class="px-50"
                              cols="12"
                              md="6"
                            >
                              <b-form-group
                                label="% Frete FOB"
                                label-for="fob_freight"
                              >
                                <b-input-group
                                  append="%"
                                  :class="isReadonly ? 'input-readonly': ''"
                                >
                                  <cleave
                                    id="fob_freight"
                                    v-model="item.fob_freight"
                                    name="fob_freight"
                                    class="form-control"
                                    :options="options.percentage"
                                    placeholder="0,00"
                                    :readonly="isReadonly"
                                    @blur="adjustItemDecimalPlaces('fob_freight', 2)"
                                  />
                                </b-input-group>
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <hr class="mt-50 mb-50">
                          <b-row>
                            <b-col
                              class="px-50"
                              cols="12"
                              md="6"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                name="freight"
                                rules="required"
                              >
                                <b-form-group
                                  label="% Frete"
                                  label-for="freight"
                                >
                                  <b-input-group
                                    append="%"
                                    :class="isReadonly ? 'input-readonly': ''"
                                  >
                                    <cleave
                                      id="freight"
                                      v-model="item.freight"
                                      name="freight"
                                      :class="errors.length > 0 ? 'form-control is-invalid' : 'form-control'"
                                      :options="options.percentage"
                                      placeholder="0,00"
                                      :readonly="isReadonly"
                                      @blur="adjustItemDecimalPlaces('freight', 2)"
                                    />
                                  </b-input-group>
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </b-form-group>
                              </validation-provider>
                            </b-col>
                            <b-col
                              class="px-50"
                              cols="12"
                              md="6"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                name="fixed_cost"
                                rules="required"
                              >
                                <b-form-group
                                  label="% Custo Fixo"
                                  label-for="fixed_cost"
                                >
                                  <b-input-group
                                    append="%"
                                    :class="isReadonly ? 'input-readonly': ''"
                                  >
                                    <cleave
                                      id="fixed_cost"
                                      v-model="item.fixed_cost"
                                      name="fixed_cost"
                                      :class="errors.length > 0 ? 'form-control is-invalid' : 'form-control'"
                                      :options="options.percentage"
                                      placeholder="0,00"
                                      :readonly="isReadonly"
                                      @blur="adjustItemDecimalPlaces('fixed_cost', 2)"
                                    />
                                  </b-input-group>
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </b-form-group>
                              </validation-provider>
                            </b-col>
                          </b-row>
                          <hr class="mt-50 mb-50">
                          <b-row>
                            <b-col
                              class="px-50"
                              cols="12"
                              md="6"
                            >
                              <b-form-group
                                label="Frete Unitário"
                                label-for="freight_calc"
                              >
                                <b-input-group
                                  prepend="R$"
                                  class="input-readonly"
                                >
                                  <cleave
                                    v-if="!isCalculating"
                                    id="freight_calc"
                                    v-model="calculated.freight"
                                    name="freight_calc"
                                    class="form-control"
                                    :options="options.price"
                                    placeholder="0,00"
                                    :readonly="true"
                                  />
                                  <div v-else>
                                    <b-spinner
                                      class="spinner-loading"
                                      variant="info"
                                      type="grow"
                                      small
                                    />
                                  </div>
                                </b-input-group>
                              </b-form-group>
                            </b-col>
                            <b-col
                              class="px-50"
                              cols="12"
                              md="6"
                            >
                              <b-form-group
                                label="Frete Total"
                                label-for="freight_total_calc"
                              >
                                <b-input-group
                                  prepend="R$"
                                  class="input-readonly"
                                >
                                  <cleave
                                    v-if="!isCalculating"
                                    id="freight_total_calc"
                                    v-model="calculated.freight_total"
                                    name="freight_total_calc"
                                    class="form-control"
                                    :options="options.price"
                                    placeholder="0,00"
                                    :readonly="true"
                                  />
                                  <div v-else>
                                    <b-spinner
                                      class="spinner-loading"
                                      variant="info"
                                      type="grow"
                                      small
                                    />
                                  </div>
                                </b-input-group>
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col
                              class="px-50"
                              cols="12"
                              md="6"
                            >
                              <b-form-group
                                label="Frete FOB"
                                label-for="fob_freight_calc"
                              >
                                <b-input-group
                                  prepend="R$"
                                  class="input-readonly"
                                >
                                  <cleave
                                    v-if="!isCalculating"
                                    id="fob_freight_calc"
                                    v-model="calculated.fob_freight"
                                    name="fob_freight_calc"
                                    class="form-control"
                                    :options="options.price"
                                    placeholder="0,00"
                                    :readonly="true"
                                  />
                                  <div v-else>
                                    <b-spinner
                                      class="spinner-loading"
                                      variant="info"
                                      type="grow"
                                      small
                                    />
                                  </div>
                                </b-input-group>
                              </b-form-group>
                            </b-col>
                            <b-col
                              class="px-50"
                              cols="12"
                              md="6"
                            >
                              <b-form-group
                                label="Frete FOB Total"
                                label-for="fob_freight_total_calc"
                              >
                                <b-input-group
                                  prepend="R$"
                                  class="input-readonly"
                                >
                                  <cleave
                                    v-if="!isCalculating"
                                    id="fob_freight_total_calc"
                                    v-model="calculated.fob_freight_total"
                                    name="fob_freight_total_calc"
                                    class="form-control"
                                    :options="options.price"
                                    placeholder="0,00"
                                    :readonly="true"
                                  />
                                  <div v-else>
                                    <b-spinner
                                      class="spinner-loading"
                                      variant="info"
                                      type="grow"
                                      small
                                    />
                                  </div>
                                </b-input-group>
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col
                              class="px-50"
                              cols="12"
                              md="6"
                            >
                              <b-form-group
                                label="Custo Fixo Unitário"
                                label-for="fixed_cost_calc"
                              >
                                <b-input-group
                                  prepend="R$"
                                  class="input-readonly"
                                >
                                  <cleave
                                    v-if="!isCalculating"
                                    id="fixed_cost_calc"
                                    v-model="calculated.fixed_cost"
                                    name="fixed_cost_calc"
                                    class="form-control"
                                    :options="options.price"
                                    placeholder="0,00"
                                    :readonly="true"
                                  />
                                  <div v-else>
                                    <b-spinner
                                      class="spinner-loading"
                                      variant="info"
                                      type="grow"
                                      small
                                    />
                                  </div>
                                </b-input-group>
                              </b-form-group>
                            </b-col>
                            <b-col
                              class="px-50"
                              cols="12"
                              md="6"
                            >
                              <b-form-group
                                label="Custo Fixo Total"
                                label-for="fixed_cost_total_calc"
                              >
                                <b-input-group
                                  prepend="R$"
                                  class="input-readonly"
                                >
                                  <cleave
                                    v-if="!isCalculating"
                                    id="fixed_cost_total_calc"
                                    v-model="calculated.fixed_cost_total"
                                    name="fixed_cost_total_calc"
                                    class="form-control"
                                    :options="options.price"
                                    placeholder="0,00"
                                    :readonly="true"
                                  />
                                  <div v-else>
                                    <b-spinner
                                      class="spinner-loading"
                                      variant="info"
                                      type="grow"
                                      small
                                    />
                                  </div>
                                </b-input-group>
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col
                              class="px-50"
                              cols="12"
                              md="6"
                            >
                              <b-form-group
                                label="Impostos"
                                label-for="tax_calc"
                              >
                                <b-input-group
                                  prepend="R$"
                                  class="input-readonly"
                                >
                                  <cleave
                                    v-if="!isCalculating"
                                    id="tax_calc"
                                    v-model="calculated.tax"
                                    name="tax_calc"
                                    class="form-control"
                                    :options="options.price"
                                    placeholder="0,00"
                                    :readonly="true"
                                  />
                                  <div v-else>
                                    <b-spinner
                                      class="spinner-loading"
                                      variant="info"
                                      type="grow"
                                      small
                                    />
                                  </div>
                                </b-input-group>
                              </b-form-group>
                            </b-col>
                            <b-col
                              class="px-50"
                              cols="12"
                              md="6"
                            >
                              <b-form-group
                                label="Imposto Total"
                                label-for="tax_calc"
                              >
                                <b-input-group
                                  prepend="R$"
                                  class="input-readonly"
                                >
                                  <cleave
                                    v-if="!isCalculating"
                                    id="tax_calc"
                                    v-model="calculated.tax_total"
                                    name="tax_calc"
                                    class="form-control"
                                    :options="options.price"
                                    placeholder="0,00"
                                    :readonly="true"
                                  />
                                  <div v-else>
                                    <b-spinner
                                      class="spinner-loading"
                                      variant="info"
                                      type="grow"
                                      small
                                    />
                                  </div>
                                </b-input-group>
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col
                              class="px-50"
                              cols="12"
                              md="6"
                            >
                              <b-form-group
                                label="Custo Unitário"
                                label-for="cost_unitary"
                              >
                                <b-input-group
                                  prepend="R$"
                                  class="input-readonly"
                                >
                                  <cleave
                                    v-if="!isCalculating"
                                    id="cost_unitary"
                                    v-model="calculated.cost_unitary"
                                    name="cost_unitary"
                                    class="form-control"
                                    :options="options.price"
                                    placeholder="0,00"
                                    :readonly="true"
                                  />
                                  <div v-else>
                                    <b-spinner
                                      class="spinner-loading"
                                      variant="info"
                                      type="grow"
                                      small
                                    />
                                  </div>
                                </b-input-group>
                              </b-form-group>
                            </b-col>
                            <b-col
                              class="px-50"
                              cols="12"
                              md="6"
                            >
                              <b-form-group
                                label="Custo Total"
                                label-for="cost_total"
                              >
                                <b-input-group
                                  prepend="R$"
                                  class="input-readonly"
                                >
                                  <cleave
                                    v-if="!isCalculating"
                                    id="cost_total"
                                    v-model="calculated.cost_total"
                                    name="cost_total"
                                    class="form-control"
                                    :options="options.price"
                                    placeholder="0,00"
                                    :readonly="true"
                                  />
                                  <div v-else>
                                    <b-spinner
                                      class="spinner-loading"
                                      variant="info"
                                      type="grow"
                                      small
                                    />
                                  </div>
                                </b-input-group>
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col
                              class="px-50"
                              cols="12"
                              md="6"
                            >
                              <b-form-group
                                label="Preço Minimo Unitário"
                                label-for="price_min_unitary"
                              >
                                <b-input-group
                                  prepend="R$"
                                  class="input-readonly"
                                >
                                  <cleave
                                    v-if="!isCalculating"
                                    id="price_min_unitary"
                                    v-model="calculated.price_unitary"
                                    name="price_min_unitary"
                                    class="form-control"
                                    :options="options.price"
                                    placeholder="0,00"
                                    :readonly="true"
                                  />
                                  <div v-else>
                                    <b-spinner
                                      class="spinner-loading"
                                      variant="info"
                                      type="grow"
                                      small
                                    />
                                  </div>
                                </b-input-group>
                              </b-form-group>
                            </b-col>
                            <b-col
                              class="px-50"
                              cols="12"
                              md="6"
                            >
                              <b-form-group
                                label="Preço Minimo Total"
                                label-for="price_min_total"
                              >
                                <b-input-group
                                  prepend="R$"
                                  class="input-readonly"
                                >
                                  <cleave
                                    v-if="!isCalculating"
                                    id="price_min_total"
                                    v-model="calculated.price_total"
                                    name="price_min_total"
                                    class="form-control"
                                    :options="options.price"
                                    placeholder="0,00"
                                    :readonly="true"
                                  />
                                  <div v-else>
                                    <b-spinner
                                      class="spinner-loading"
                                      variant="info"
                                      type="grow"
                                      small
                                    />
                                  </div>
                                </b-input-group>
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col
                              class="px-50"
                              cols="12"
                              md="6"
                            >
                              <b-form-group
                                label="Lucro Unitário"
                                label-for="profit_min_unitary"
                              >
                                <b-input-group
                                  prepend="R$"
                                  class="input-readonly"
                                >
                                  <cleave
                                    v-if="!isCalculating"
                                    id="profit_min_unitary"
                                    v-model="calculated.profit_unitary"
                                    name="profit_min_unitary"
                                    class="form-control"
                                    :options="options.price"
                                    placeholder="0,00"
                                    :readonly="true"
                                  />
                                  <div v-else>
                                    <b-spinner
                                      class="spinner-loading"
                                      variant="info"
                                      type="grow"
                                      small
                                    />
                                  </div>
                                </b-input-group>
                              </b-form-group>
                            </b-col>
                            <b-col
                              class="px-50"
                              cols="12"
                              md="6"
                            >
                              <b-form-group
                                label="Lucro Total"
                                label-for="profit_min_total"
                              >
                                <b-input-group
                                  prepend="R$"
                                  class="input-readonly"
                                >
                                  <cleave
                                    v-if="!isCalculating"
                                    id="profit_min_total"
                                    v-model="calculated.profit_total"
                                    name="profit_min_total"
                                    class="form-control"
                                    :options="options.price"
                                    placeholder="0,00"
                                    :readonly="true"
                                  />
                                  <div v-else>
                                    <b-spinner
                                      class="spinner-loading"
                                      variant="info"
                                      type="grow"
                                      small
                                    />
                                  </div>
                                </b-input-group>
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col
                              class="px-50"
                              cols="12"
                              md="6"
                            >
                              <b-form-group
                                label="Margem de Lucro Mínima"
                                label-for="margin"
                              >
                                <b-input-group
                                  append="%"
                                  class="input-readonly"
                                >
                                  <b-form-input
                                    v-if="!isCalculating"
                                    id="margin"
                                    v-model="calculated.margin"
                                    placeholder=""
                                    :readonly="true"
                                  />
                                  <div v-else>
                                    <b-spinner
                                      class="spinner-loading"
                                      variant="info"
                                      type="grow"
                                      small
                                    />
                                  </div>
                                </b-input-group>
                              </b-form-group>
                            </b-col>
                            <b-col
                              class="px-50"
                              cols="12"
                              md="6"
                            >
                              <b-form-group
                                label="Margem de Lucro Estimada"
                                label-for="estimated_margin"
                              >
                                <b-input-group
                                  append="%"
                                  class="input-readonly"
                                >
                                  <b-form-input
                                    v-if="!isCalculating"
                                    id="estimated_margin"
                                    v-model="calculated.estimated_margin"
                                    placeholder=""
                                    :readonly="true"
                                  />
                                  <div v-else>
                                    <b-spinner
                                      class="spinner-loading"
                                      variant="info"
                                      type="grow"
                                      small
                                    />
                                  </div>
                                </b-input-group>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-card-body>
                      </b-card>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row
                v-if="!isReadonly"
                class="mb-1 mt-1"
              >
                <b-col
                  class="d-flex justify-content-end buttonsSaveCancel"
                >
                  <b-button
                    id="btn_save"
                    variant="primary"
                    class="mr-0 mr-sm-1"
                    @click="handleSubmit(save)"
                  >
                    Salvar
                  </b-button>
                  <b-button
                    id="btn_cancel"
                    variant="outline-primary"
                    class="mb-sm-0 mr-0 mr-sm-1"
                    @click="cancel()"
                  >
                    Cancelar
                  </b-button>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-form>
      </validation-observer>
    </div>
    <validation-observer
      v-slot="{ handleSubmit, invalid }"
      ref="refFormObserverModal"
    >
      <b-modal
        id="idModalAddProductCompound"
        ref="refModalAddProductCompound"
        size="lg"
        no-close-on-backdrop
        no-close-on-esc
        centered
        cancel-variant="outline-primary"
        :ok-disabled="invalid"
        @ok="handleSubmit(addItem)"
        @cancel="cancelAddItem()"
        @hidden="cancelAddItem()"
      >
        <template #modal-title>
          Inclusão de Produto Composto
        </template>
        <template #modal-cancel>
          Cancelar
        </template>
        <template #modal-ok>
          Adicionar
        </template>
        <b-form
          class="mt-1"
        >
          <b-row
            class="mt-1"
          >
            <b-col
              cols="12"
              md="12"
            >
              <b-row class="mt-1">
                <b-col>
                  <product-select
                    v-model="itemModal.product_set"
                    :readonly="readonly"
                    :required-field="true"
                    label="Nome do Produto do Fornecedor"
                    error-label="O campo Item Fornecedor é obrigatório"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="quantity"
                    :rules="{
                      required: true,
                      min: 1,
                    }"
                  >
                    <b-form-group
                      label="Quantidade"
                      label-for="quantity"
                    >
                      <cleave
                        id="quantity"
                        v-model="itemModal.quantity"
                        class="form-control"
                        :class="validationContext.errors.length > 0 ? 'form-control is-invalid' : 'form-control'"
                        :options="options.number"
                        :placeholder="readonly ? '' : 'Qtd'"
                        :readonly="readonly"
                      />
                      <small
                        v-if="validationContext.errors.length > 0"
                        class="text-danger"
                      >
                        Campo obrigatório
                      </small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="product_price"
                    :rules="{
                      required: true,
                    }"
                  >
                    <b-form-group
                      label="Preço"
                      label-for="product_price"
                    >
                      <b-input-group
                        prepend="R$"
                      >
                        <cleave
                          id="product_price"
                          v-model="itemModal.price"
                          name="product_price"
                          class="form-control"
                          :options="options.price"
                          placeholder="0,00"
                          :readonly="readonly"
                        />
                      </b-input-group>
                      <small
                        v-if="validationContext.errors.length > 0"
                        class="text-danger"
                      >
                        Campo obrigatório
                      </small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group
                    label="Descrição do Produto do Fornecedor"
                    label-for="description_supplier"
                  >
                    <b-form-textarea
                      id="description_suppier"
                      v-model="itemModal.product_set.description"
                      class="biddingProductDescription"
                      :placeholder="readonly ? '' : 'Descrição do Produto do Fornecedor'"
                      :readonly="true"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="Marca"
                    label-for="brand"
                  >
                    <b-form-input
                      id="brand"
                      v-model="itemModal.product_set.brand"
                      placeholder=""
                      :readonly="true"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="Modelo"
                    label-for="model"
                  >
                    <b-form-input
                      id="model"
                      v-model="itemModal.product_set.model"
                      placeholder=""
                      :readonly="true"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
    </validation-observer>
    <div>
      <item-result-modal
        ref="refItemResultModal"
        v-model="item"
        @save="saveModalItemResult()"
      />
    </div>
    <b-modal
      id="idModalItemIsGain"
      ref="modalItemIsGain"
      class="modalStyle"
      no-close-on-backdrop
      no-close-on-esc
      centered
      hide-footer
    >
      <b-form
        class="py-2"
      >
        <b-row>
          <b-col>
            <span
              class="d-flex justify-content-center px-2 mb-1 layoutModalAlert"
            >
              <svg-icon
                type="mdi"
                size="80"
                :path="mdiAlertCircle"
              />
            </span>
            <span
              class="d-flex justify-content-center px-2 my-1 fixLayoutTextModal"
            >
              Não é possível excluir este produto, pois ele está marcado como GANHO!
            </span>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <b-modal
      id="idModalConfirmConvert"
      ref="refModalConfirmConvert"
      no-close-on-backdrop
      no-close-on-esc
      centered
      cancel-variant="outline-primary"
      @ok="convertToUnitary()"
      @cancel="closeConfirmConvert()"
      @hidden="closeConfirmConvert()"
    >

      <template #modal-title>
        Confirmar?
      </template>

      <template #modal-ok>
        Confirmar
      </template>
      <template #modal-cancel>
        Cancelar
      </template>

      <b-row
        align-h="center"
      >
        <b-col
          cols="12"
          md="12"
        >
          <div
            class="d-flex flex-column align-items-center justify-content-center"
          >
            <svg-icon
              class="mb-1"
              size="60"
              type="mdi"
              :path="mdiSwapHorizontalCircleOutline"
            />
            <span
              class="font-weight-bold"
            >
              Este é um produto COMPOSTO, deseja alterar o tipo para produto UNITÁRIO? (Disponível se possuir apenas um item)
            </span>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardBody,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormTextarea,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BMedia,
  BButton,
  BModal,
  BTable,
  BSpinner,
  VBTooltip,
  BPopover,
  VBModal,
} from 'bootstrap-vue'

import {
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate'

import {
  mdiTrashCanOutline,
  mdiTrophyOutline,
  mdiPackageVariantClosedCheck,
  mdiPackageVariantClosedRemove,
  mdiAlphaUBoxOutline,
  mdiAlphaCBox,
  mdiTagMultipleOutline,
  mdiChevronDown,
  mdiChevronRight,
  mdiPlus,
  mdiListStatus,
  mdiAlertCircle,
  mdiSwapHorizontalCircleOutline,
} from '@mdi/js'

import {
  useToast,
} from 'vue-toastification/composition'

import Cleave from 'vue-cleave-component'
import SvgIcon from '@jamescoyle/vue-icon'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import ProductSelect from '@/components/product/ProductSelect.vue'
import ItemResultModal from '@/views/bidding/components/ItemResultModal.vue'
import formatCurrency from '@/components/utils/filters/formatCurrency'
import formatNumber from '@/components/utils/filters/formatNumber'
import syslic from '@/syslic'

export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BDropdown,
    BDropdownItem,
    BFormTextarea,
    BMedia,
    BButton,
    BModal,
    BTable,
    BSpinner,
    BPopover,
    ValidationObserver,
    ValidationProvider,
    Cleave,
    SvgIcon,
    ProductSelect,
    ItemResultModal,
  },
  filters: {
    formatCurrency,
  },
  directives: {
    VBModal,
    'b-tooltip': VBTooltip,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    bidding: {
      type: Object,
      required: true,
    },
    lote: {
      type: [String, Number],
      required: false,
      default: null,
    },
    itemList: {
      type: Array,
      required: true,
    },
    itemIndex: {
      type: Number,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: true,
    },
    isEven: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  emits: ['open', 'isAdding'],

  data() {
    const options = {
      price: {
        numeral: true,
        numeralDecimalScale: 2,
        numeralDecimalMark: ',',
        delimiter: '.',
        numeralPositiveOnly: true,
      },
      percentage: {
        numeral: true,
        numeralDecimalScale: 2,
        numeralDecimalMark: ',',
        delimiter: '.',
        numeralPositiveOnly: true,
      },
      number: {
        numeral: true,
        numeralDecimalScale: 0,
        numeralDecimalMark: '',
        delimiter: '',
        numeralPositiveOnly: true,
      },
    }

    const tableColumns = [
      {
        key: 'name',
        label: 'Lista de Itens',
        thClass: 'productHeader',
      },
      {
        key: 'quantity',
        label: 'Quantidade',
        thClass: 'productHeader',
      },
      {
        key: 'price',
        label: 'Preço Unitário',
        thClass: 'productHeader',
      },
      {
        key: 'field_actions',
        label: '',
        thClass: 'productHeader',
        thStyle: { width: '5%' },
      },
    ]

    const isCalculating = false

    const calculated = {
      freight: 0,
      fob_freight: 0,
      tax: 0,
      margin: 0,
      estimated_margin: 0,
      fixed_cost: 0,
      cost_unitary: 0,
      price_unitary: 0,
      profit_unitary: 0,
      cost_total: 0,
      freight_total: 0,
      fob_freight_total: 0,
      tax_total: 0,
      price_total: 0,
      profit_total: 0,
      total: 0,
    }

    const itemBlank = {
      product: '',
      product_set: {
        name: '',
        price: '',
        description: '',
        brand: '',
        model: '',
      },
      quantity: '',
      price: '',
    }

    const itemModal = JSON.parse(JSON.stringify(itemBlank))

    const isItemOk = false
    const isItemCompose = false
    const isOpen = false

    const itemCheck = false

    const itemOrig = {}

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      options,
      tableColumns,
      isCalculating,
      calculated,
      isItemOk,
      isItemCompose,
      isOpen,
      itemCheck,
      itemOrig,
      itemModal,
      itemBlank,
      refFormObserver,
      getValidationState,
    }
  },
  computed: {
    cost: {
      get() {
        return formatNumber(this.item.cost, 2)
      },
      set() {
      },
    },
    isReadonly() {
      return this.item.result !== 'pending' || this.readonly
    },
  },
  watch: {
    'bidding.freight_group_set': function updateClient() {
      if (this.bidding.freight_group_set) {
        this.item.freight = formatNumber(this.bidding.freight_group_set.value, 2)
      }
      this.refresh()
    },
    'bidding.company_set': function updateCOmpany() {
      if (this.bidding.company_set) {
        this.item.margin_min = this.bidding.company_set.margin_min
        this.item.tax = this.bidding.company_set.tax_aliquot
        this.item.fixed_cost = formatNumber(this.bidding.company_set.fixed_cost, 2)
      }
      this.refresh()
    },
    'item.cost': function updateCost() {
      this.refresh()
    },
    'item.quantity': function updateQuantity() {
      this.refresh()
    },
    'item.price': function updatePrice() {
      this.refresh()
    },
    'item.freight': function updateFreight() {
      this.refresh()
    },
    'item.fob_freight': function updateFobFreight() {
      this.refresh()
    },
    'item.fixed_cost': function updateFixedCost() {
      this.refresh()
    },
    'item.tax': function updateTax() {
      this.refresh()
    },
    'item.meta.isOpen': function updateOpen(val) {
      if (val) {
        this.open()
      } else {
        this.close()
      }
    },
    'itemModal.product_set': function updateProductModal() {
      this.itemModal.price = this.itemModal.product_set.price
      this.itemModal.product = this.itemModal.product_set.id
    },
  },
  created() {
    this.initValues()

    this.refresh()

    if (Object.hasOwn(this.item, 'meta')) {
      if (Object.hasOwn(this.item.meta, 'isOpen')) {
        if (this.item.meta.isOpen) {
          this.open()
        }
      }
    }

    if (!Object.hasOwn(this.item, 'id')) {
      this.open()
    }

    this.itemOrig = JSON.parse(JSON.stringify(this.item))
  },
  methods: {
    initValues() {
      if (this.bidding.freight_group_set) {
        if (this.item.freight === '') {
          this.item.freight = formatNumber(this.bidding.freight_group_set.value, 2)
        }
      }

      if (this.bidding.company_set) {
        this.item.margin_min = this.bidding.company_set.margin_min
        this.item.tax = this.bidding.company_set.tax_aliquot

        if (this.item.fixed_cost === '') {
          this.item.fixed_cost = formatNumber(this.bidding.company_set.fixed_cost, 2)
        }
      }

      if (this.item.product_list) {
        if (this.item.cost === '') {
          let value = 0
          this.item.product_list.forEach(element => {
            value += parseFloat(element.price).toFixed(2) * Number(element.quantity)
          })
          this.item.cost = parseFloat(value).toFixed(2)
        }
      }
    },
    refresh() {
      let isOk = true

      if (!this.bidding.freight_group_set) {
        isOk = false
      }

      if (!this.bidding.company_set) {
        isOk = false
      }

      if (this.item.product_list) {
        let value = 0
        this.item.product_list.forEach(element => {
          value += parseFloat(element.price).toFixed(2) * Number(element.quantity)
        })
        this.item.cost = parseFloat(value).toFixed(2)
      } else {
        isOk = false
      }

      if (isOk) {
        this.refreshValues()
      }
    },
    refreshEstimatedMargin(freight, tax, fixedCost, cost, price) {
      let margin = 0
      if (Number(freight) !== 0 && !Number.isNaN(Number(freight))
          && Number(tax) !== 0 && !Number.isNaN(Number(tax))
          && Number(fixedCost) !== 0 && !Number.isNaN(Number(fixedCost))
          && Number(cost) !== 0 && !Number.isNaN(Number(cost))
          && Number(price) !== 0 && !Number.isNaN(Number(price))) {
        margin = (((1 - ((Number(freight) + Number(tax) + Number(fixedCost)) / 100)) - (Number(cost) / Number(price))) * 100)
      }
      this.calculated.estimated_margin = margin
    },
    refreshValues() {
      this.isCalculating = true

      const quantity = Number(this.item.quantity)
      const cost = parseFloat(this.item.cost).toFixed(2)
      const price = parseFloat(this.item.price).toFixed(2)
      const freight = parseFloat(this.item.freight).toFixed(2)
      const fobFreight = parseFloat(this.item.fob_freight).toFixed(2)
      const tax = parseFloat(this.item.tax).toFixed(2)
      const fixedCost = parseFloat(this.item.fixed_cost).toFixed(2)
      const marginMin = parseFloat(this.item.margin_min).toFixed(2)

      let marginBrute = (1 / (1 - ((Number(freight) + Number(tax) + Number(fixedCost) + Number(marginMin)) / 100)))

      if (Number(fobFreight) !== 0 && !Number.isNaN(Number(fobFreight))) {
        marginBrute = ((1 + (Number(fobFreight) / 100)) / (1 - ((Number(freight) + Number(tax) + Number(fixedCost) + Number(marginMin)) / 100)))
      }

      this.refreshEstimatedMargin(freight, tax, fixedCost, cost, price)

      this.calculated.price_unitary = cost * marginBrute
      this.calculated.fob_freight = cost * (Number(fobFreight) / 100)
      this.calculated.cost_unitary = this.calculated.price_unitary - (this.calculated.price_unitary * (Number(marginMin) / 100))
      this.calculated.freight = this.calculated.price_unitary * (Number(freight) / 100)
      this.calculated.tax = this.calculated.price_unitary * (Number(tax) / 100)
      this.calculated.fixed_cost = this.calculated.price_unitary * (Number(fixedCost) / 100)
      this.calculated.profit_unitary = (this.calculated.price_unitary * (Number(marginMin) / 100))

      if (Number(this.calculated.price_unitary) !== 0 && !Number.isNaN(Number(this.calculated.price_unitary))
          && Number(this.calculated.profit_unitary) !== 0 && !Number.isNaN(Number(this.calculated.profit_unitary))) {
        this.calculated.margin = (this.calculated.profit_unitary / this.calculated.price_unitary) * 100
      } else {
        this.calculated.margin = 0
      }

      this.calculated.cost_total = this.calculated.cost_unitary * quantity
      this.calculated.freight_total = this.calculated.freight * quantity
      this.calculated.fob_freight_total = this.calculated.fob_freight * quantity
      this.calculated.tax_total = this.calculated.tax * quantity
      this.calculated.fixed_cost_total = this.calculated.fixed_cost * quantity
      this.calculated.price_total = this.calculated.price_unitary * quantity
      this.calculated.profit_total = this.calculated.profit_unitary * quantity
      this.calculated.total = price * quantity

      if ((price < this.calculated.price_unitary) || (this.calculated.price_unitary === 0 && price === 0)) {
        this.isItemOk = false
      } else {
        this.isItemOk = true
      }

      this.item.price_min = parseFloat(this.calculated.price_unitary).toFixed(2)

      Object.keys(this.calculated).forEach(key => {
        const value = parseFloat(this.calculated[key]).toFixed(2)
        this.calculated[key] = value.toString().replace('.', ',')
      })

      this.isCalculating = false
    },
    adjustItemDecimalPlaces(field, places) {
      const decimalReference = 10 ** places
      const num = this.item[field]

      if (num !== '') {
        const result = ((num * decimalReference) / decimalReference).toFixed(places)
        this.item[field] = result
      }
    },
    toggleCard() {
      if (!this.isOpen) {
        this.item.price = formatNumber(this.item.price, 2)
        this.item.price_min = formatNumber(this.item.price_min, 2)
        this.item.cost = formatNumber(this.item.cost, 2)
        this.item.freight = formatNumber(this.item.freight, 2)
        this.item.fob_freight = formatNumber(this.item.fob_freight, 2)
        this.item.fixed_cost = formatNumber(this.item.fixed_cost, 2)
        this.open()
      } else {
        this.item.quantity = Number(this.item.quantity)
        this.item.number = Number(this.item.number)
        this.showConfirmationClose()
      }
    },
    open() {
      this.$emit('toggleCard', true)
      this.isOpen = true
      this.$emit('open', this.index)
      this.$emit('isAdding', true)
    },
    tryClose() {
      const promise = new Promise((resolve, reject) => {
        if (!this.readonly) {
          if (this.isOpen) {
            const oldVal = JSON.stringify(this.itemOrig)
            const newVal = JSON.stringify(this.item)

            if (oldVal !== newVal) {
              this.$bvModal
                .msgBoxConfirm('Existem alterações não salvas no produto, caso você prossiga elas serão perdidas, deseja prosseguir?', {
                  id: 'confirmation_box',
                  title: 'Deseja prosseguir?',
                  size: 'sm',
                  okVariant: 'primary',
                  okTitle: 'Sim',
                  cancelTitle: 'Não',
                  cancelVariant: 'outline-primary',
                  hideHeaderClose: false,
                  centered: true,
                })
                .then(value => {
                  if (value) {
                    resolve()
                  } else {
                    reject()
                  }
                })
            } else {
              resolve()
            }
          } else {
            resolve()
          }
        } else {
          resolve()
        }
      })
      return promise
    },
    showConfirmationClose() {
      if (!this.readonly) {
        if (this.isOpen) {
          const oldVal = JSON.stringify(this.itemOrig)
          const newVal = JSON.stringify(this.item)

          if (oldVal !== newVal) {
            this.$bvModal
              .msgBoxConfirm('Existem alterações não salvas neste produto, caso você prossiga elas serão perdidas, deseja prosseguir?', {
                id: 'confirmation_box',
                title: 'Deseja prosseguir?',
                size: 'sm',
                okVariant: 'primary',
                okTitle: 'Sim',
                cancelTitle: 'Não',
                cancelVariant: 'outline-primary',
                hideHeaderClose: false,
                centered: true,
              })
              .then(value => {
                if (value) {
                  this.$emit('toggleCard', false)
                  this.close()
                  this.$emit('isAdding', false)
                }
              })
          } else {
            this.$emit('toggleCard', false)
            this.close()
            this.$emit('isAdding', false)
          }
        }
      } else {
        this.close()
      }
    },
    close() {
      if (Object.hasOwn(this.item, 'id')) {
        Object.keys(this.itemOrig).forEach(key => {
          this.item[key] = JSON.parse(JSON.stringify(this.itemOrig[key]))
        })
      } else {
        this.itemList.splice(this.itemIndex, 1)
      }
      this.isOpen = false
    },
    create() {
      syslic
        .bidding
        .item
        .addItem(this.item)
        .then(response => {
          this.item.id = response.data.id
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Produto da licitação adicionado com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.itemOrig = JSON.parse(JSON.stringify(response.data))
          this.$emit('toggleCard', false)
          this.$emit('isAdding', false)
          this.close()
        })
        .catch(err => {
          let textError = 'Não foi possível adicionar o produto da licitação, por favor entre em contato com o administrador do sistema.'
          let style = 'danger'

          if (err.data.length) {
            if (err.data[0].includes('The fields bidding, number must make a unique set.')) {
              textError = 'Não foi possível adicionar este produto, pois já existe um outro produto cadastrado com o mesmo número nesta licitação.'
              style = 'warning'
            }
            if (err.data[0].includes('duplicate key value violates unique constraint')) {
              textError = 'Não é possível criar um produto composto com itens repetidos na lista! Agrupe os itens iguais.'
              style = 'warning'
            }
          }

          this.$swal({
            title: 'Atenção!',
            text: textError,
            icon: style,
            showConfirmButton: false,
            timer: 10000,
          })
        })
    },
    update() {
      syslic
        .bidding
        .item
        .updateItem(this.item.id, this.item)
        .then(response => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Produto da licitação atualizada com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.itemOrig = JSON.parse(JSON.stringify(response.data))
          this.$emit('toggleCard', false)
          this.$emit('isAdding', false)
          this.close()
        })
        .catch(err => {
          let textError = 'Não foi possível atualizar o produto da licitação, por favor entre em contato com o administrador do sistema.'
          let style = 'danger'

          if (err.data.length) {
            if (err.data[0].includes('The fields bidding, number must make a unique set.')) {
              textError = 'Não foi possível atualizer este produto, pois já existe um outro produto cadastrado com o mesmo número nesta licitação.'
              style = 'warning'
            }
          }

          this.$swal({
            title: 'Atenção!',
            text: textError,
            icon: style,
            showConfirmButton: false,
            timer: 10000,
          })
        })
    },
    convertToUnitary() {
      if (this.item.product_list.length > 1) {
        this.$swal({
          title: 'Atenção!',
          text: 'Não é possível converter o tipo para unitário, pois o produto possui mais de um item na sua composição.',
          icon: 'warning',
          showConfirmButton: false,
          timer: 10000,
        })
        return
      }

      const data = {
        type: 'unit',
      }

      syslic
        .bidding
        .item
        .convertItemType(this.item.id, data)
        .then(response => {
          this.$swal({
            title: '',
            text: 'Tipo do produto atualizado com sucesso.',
            icon: 'success',
            showConfirmButton: false,
            timer: 4000,
          })
          this.item.type = response.data.type
          this.$emit('type-convert', this.item, response.data.type)
        })
        .catch(() => {
          this.$swal({
            title: 'Atenção!',
            text: 'Não foi possível alterar o tipo do produto, por favor entre em contato com o administrador do sistema.',
            icon: 'error',
            showConfirmButton: false,
            timer: 10000,
          })
        })
    },
    remove() {
      syslic
        .bidding
        .item
        .deleteItem(this.item.id)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Produto da licitação removido com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.$emit('delete', this.itemIndex)
        })
        .catch(() => {
          this.$swal({
            title: 'Atenção!',
            text: 'Não foi possível remover o produto da licitação, por favor entre em contato com o administrador do sistema.',
            icon: 'error',
            showConfirmButton: false,
            timer: 10000,
          })
        })
    },
    verifyItemIsGain() {
      if (this.item.result === 'gain') {
        this.$refs.modalItemIsGain.show()
      } else {
        this.showConfirmOrCancelDelete()
      }
    },
    showConfirmOrCancelDelete() {
      this.$bvModal
        .msgBoxConfirm('Você realmente quer remover este produto da licitação?', {
          id: 'confirmation_box',
          title: 'Confirmar?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.remove()
          }
        })
    },
    save() {
      if (Object.hasOwn(this.item, 'id')) {
        this.update()
      } else {
        this.create()
      }
    },
    cancel() {
      this.showConfirmationClose()
    },
    openItem() {
      this.itemModal = JSON.parse(JSON.stringify(this.itemBlank))
      this.$refs.refModalAddProductCompound.show()
    },
    addItem() {
      this.item.product_list.push(this.itemModal)
      this.itemModal = JSON.parse(JSON.stringify(this.itemBlank))
      this.updateFobFreightField()
      this.refresh()
      this.$refs.refModalAddProductCompound.hide()
    },
    deleteItem(index) {
      this.item.product_list.splice(index, 1)
      this.updateFobFreightField()
      this.refresh()
    },
    cancelAddItem() {
      this.itemModal = JSON.parse(JSON.stringify(this.itemBlank))
      this.$refs.refModalAddProductCompound.hide()
    },
    openModalItemResult() {
      this.$refs.refItemResultModal.openModal()
    },
    saveModalItemResult() {
      this.itemOrig = JSON.parse(JSON.stringify(this.item))
      this.changeCheck(false)
    },
    changeCheck(val) {
      this.itemCheck = val
    },
    handleCheckbox() {
      this.$emit('product-check', this.item.id, this.itemCheck)
    },
    updateFobFreightField() {
      if (this.item.product_list.length > 0) {
        const firstItem = this.item.product_list[0]

        if (firstItem.product_set.supplier_set) {
          if (firstItem.product_set.supplier_set.region_freight_set) {
            const fob = firstItem.product_set.supplier_set.region_freight_set.value
            this.item.fob_freight = formatNumber(fob, 2)
          } else {
            this.item.fob_freight = 0
          }
        }
      }
    },
    openModalItemConvert() {
      this.$refs.refModalConfirmConvert.show()
    },
    closeConfirmConvert() {
      this.$refs.refModalConfirmConvert.hide()
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      mdiTrophyOutline,
      mdiPackageVariantClosedCheck,
      mdiPackageVariantClosedRemove,
      mdiChevronDown,
      mdiChevronRight,
      mdiAlphaUBoxOutline,
      mdiAlphaCBox,
      mdiTagMultipleOutline,
      mdiPlus,
      mdiListStatus,
      mdiAlertCircle,
      mdiTrashCanOutline,
      mdiSwapHorizontalCircleOutline,
    }
  },
}
</script>
<style lang="scss" scoped>
@import "@core/scss/base/core/colors/palette-variables.scss";

#biddingCardItemPrice {
  border: 1px solid #ebe9f1;
}

.biddingCardItemBlock .media{
  margin-bottom: 0.5rem;
}

.biddingCardItemBlock .media-body{
  color: #5e5873;
  margin-top: 0.5rem;
}

.biddingCardItemBlock.left {
  border-left: 1px solid #ebe9f1;
}

.biddingCardItemSectionTitle {
  font-weight: 600;
  font-size: 0.8rem;
  color: #005C96;
  margin-top: 0.75rem;
}

.biddingCardItemSectionContent h5{
  font-weight: 400;
  font-size: 1.15rem;
}

.biddingProductCard.itemOdd {
  background-color: #F6F6F9 !important;
}

.biddingMarginOk {
  color: #16A32D;
}

.biddingMarginFail {
  color: #F3271A;
}

.biddingGainOk {
  color: #16A32D;
}
.biddingGainFail {
  color: #F3271A;
}

.biddingIconType {
  color: $primary;
}

.biddingProductDescription {
  min-height: 15.25rem;
}
</style>

<style lang="scss">
@import "@core/scss/base/core/colors/palette-variables.scss";
.productHeader {
  background-color: transparent !important;
  border-bottom: 0 !important;
  border-top-color: transparent !important;
}

.productHeader * {
  color: $primary;
}

.buttonInformation {
  border: none !important;
}

.biddingFormLabel {
  width: max-content;
}

#idModalAddProductCompound .modal-footer {
  display: flex !important;
  flex-direction: row-reverse !important;
  justify-content: end !important;
  background-color: #fff !important;

  .btn{
    min-width: 107px;
  }
}

.buttonsSaveCancel{
  .btn{
    min-width: 107px;
  }
}

.layoutModalAlert {
  text-align: center;
  svg {
    color: #FF7A00;
  }
}

.fixLayoutTextModal {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
}

.spinner-loading {
  margin-left: 15px;
  margin-right: 15px;
}
</style>
