<template>
  <div>
    <div v-show="isLoading && isOpenModal">
      <spin-loader />
    </div>
    <div v-show="!isLoading">
      <validation-observer
        v-slot="{ handleSubmit, invalid }"
        ref="refFormObserver"
      >
        <b-modal
          id="idModalItemResult"
          ref="modalItemResult"
          no-close-on-backdrop
          no-close-on-esc
          centered
          size="lg"
          cancel-variant="outline-primary"
          footer-bg-variant="light"
          :ok-disabled="invalid"
          @ok="handleSubmit(save)"
          @cancel="cancel()"
          @hidden="cancel()"
        >
          <template #modal-title>
            Editar Status do Item
          </template>
          <template #modal-cancel>
            Cancelar
          </template>
          <template #modal-ok>
            Salvar
          </template>
          <b-form
            class="mt-1"
          >
            <b-row>
              <b-col
                cols="12"
                md="5"
              >
                <item-status-select
                  v-model="data.result"
                  :required-field="true"
                  @ready="syncLoad.ready('itemStatus')"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
              >
                <b-form-group
                  label="Observações"
                  label-for="observation"
                >
                  <b-form-textarea
                    id="observation"
                    v-model="data.observation"
                    trim
                    :placeholder="readonly ? '' : 'Observações'"
                    :readonly="readonly"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-modal>
      </validation-observer>
    </div>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormTextarea,
} from 'bootstrap-vue'

import {
  ValidationObserver,
} from 'vee-validate'

import formValidation from '@core/comp-functions/forms/form-validation'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import SpinLoader from '@/components/spinloader/SpinLoader.vue'
import syslic from '@/syslic'
import SyncLoad from '@/components/utils/syncLoad'
import ItemStatusSelect from '@/components/bidding/ItemStatusSelect.vue'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormTextarea,
    ValidationObserver,
    SpinLoader,
    ItemStatusSelect,
  },
  model: {
    prop: 'itemResultValue',
    event: 'itemResultValueChange',
  },
  props: {
    itemResultValue: {
      type: [Object, String],
      required: false,
      default: null,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    const isOpenModal = false
    const isLoading = true

    const statusItemInitial = ''
    const observationInitial = ''

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(this.data)

    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('itemStatus')

    syncLoad.onReady(() => {
      this.isLoading = false
    })

    return {
      refFormObserver,
      getValidationState,
      isOpenModal,
      isLoading,
      statusItemInitial,
      observationInitial,
      syncLoad,
    }
  },
  computed: {
    data: {
      get() {
        return this.itemResultValue
      },
      set(value) {
        this.$emit('itemResultValueChange', value)
      },
    },
  },
  watch: {},
  methods: {
    openModal() {
      this.statusItemInitial = this.data.result
      this.observationInitial = this.data.observation
      this.isOpenModal = true
      this.$refs.modalItemResult.show()
    },
    hasProduct() {
      if (this.data.product_list.length > 0 && (this.data.product_list[0].product !== '')) {
        return true
      }

      return false
    },
    save() {
      if (this.hasProduct()) {
        this.statusItemInitial = this.data.result
        this.observationInitial = this.data.observation

        const itemResult = {
          observation: this.data.observation,
          result: this.data.result,
        }
        syslic
          .bidding
          .item
          .updateItemResult(this.data.id, itemResult)
          .then(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Status do Item atualizado com sucesso.',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
            this.$emit('save')
          })
          .catch(() => {
            const textError = 'Não foi possível editar o status do item, por favor entre em contato com o administrador do sistema.'
            const style = 'error'

            this.$swal({
              title: 'Atenção!',
              text: textError,
              icon: style,
              showConfirmButton: false,
              timer: 10000,
            })
          })
      } else {
        const textError = 'Existem dados não cadastrados dentro do item!'
        const style = 'error'

        this.$swal({
          title: 'Atenção!',
          text: textError,
          icon: style,
          showConfirmButton: false,
          timer: 10000,
        })
      }
    },
    cancel() {
      this.isOpenModal = false
      this.data.result = this.statusItemInitial
      this.data.observation = this.observationInitial
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
    }
  },
}
</script>
<style lang="scss">
@import "@core/scss/base/core/colors/palette-variables.scss";

 #idModalItemResult {
  .modal-header .modal-title{
    font-size: 1.2em;
    color: $primary !important;
    font-weight: bolder;
  }
 }

 #idModalItemResult .modal-footer {
  display: flex !important;
  flex-direction: row-reverse !important;
  justify-content: end !important;
  background-color: #fff !important;

  .btn{
    min-width: 107px;
  }
}
</style>
