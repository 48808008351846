import { render, staticRenderFns } from "./BiddingAdditionalData.vue?vue&type=template&id=30f1c4cb&scoped=true&"
import script from "./BiddingAdditionalData.vue?vue&type=script&lang=js&"
export * from "./BiddingAdditionalData.vue?vue&type=script&lang=js&"
import style0 from "./BiddingAdditionalData.vue?vue&type=style&index=0&id=30f1c4cb&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30f1c4cb",
  null
  
)

export default component.exports