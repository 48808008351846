<template>
  <div>
    <div v-show="isLoading">
      <spin-loader />
    </div>
    <div>
      <history-list
        :history-list="historyList"
        :labels="labelList"
      />
    </div>
  </div>
</template>
<script>
import {
  mdiTrophyVariantOutline,
  mdiTrophyBroken,
  mdiGavel,
  mdiFileEditOutline,
  mdiFileCertificateOutline,
  mdiPaperclipPlus,
  mdiPaperclipRemove,
  mdiPackageVariantPlus,
  mdiPackageVariantRemove,
  mdiPencilOutline,
} from '@mdi/js'

import {
  useToast,
} from 'vue-toastification/composition'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import syslic from '@/syslic'
import SyncLoad from '@/components/utils/syncLoad'
import SpinLoader from '@/components/spinloader/SpinLoader.vue'
import HistoryList from '@/components/history/HistoryList.vue'

export default {
  components: {
    SpinLoader,
    HistoryList,
  },
  props: {
    biddingId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    const isLoading = true
    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('history')

    syncLoad.onReady(() => {
      this.isLoading = false
    })

    const historyList = []

    const labelList = [
      {
        type: '~',
        model: 'Bidding',
        label: 'Licitação Homologada',
        icon: mdiFileCertificateOutline,
        validate: event => {
          if (Object.keys(event.diff).length < 3 && Object.keys(event.diff).length !== 0) {
            if (event.diff.is_homologated === true) {
              return true
            }
          }
          return false
        },
      },
      {
        type: '~',
        model: 'Bidding',
        label: 'Licitação Reaberta',
        icon: mdiGavel,
        validate: event => {
          if (Object.keys(event.diff).length < 3 && Object.keys(event.diff).length !== 0) {
            if (event.diff.is_homologated === false) {
              return true
            }
          }
          return false
        },
      },
      {
        type: '+',
        model: 'Bidding',
        label: 'Criação da Licitação',
        icon: mdiGavel,
      },
      {
        type: '-',
        model: 'Bidding',
        label: 'Remoção da Licitação',
        icon: mdiPencilOutline,
      },
      {
        type: '~',
        model: 'Bidding',
        label: 'Alteração na Licitação',
        icon: mdiFileEditOutline,
        validate: event => {
          if (Object.keys(event.diff).length !== 0 && Object.keys(event.diff).length !== 47) {
            return true
          }
          return false
        },
      },
      {
        type: '+',
        model: 'BiddingImported',
        label: 'Importação da Licitação',
        icon: mdiGavel,
      },
      {
        type: '-',
        model: 'BiddingImported',
        label: 'Remoção da Licitação',
        icon: mdiFileEditOutline,
      },
      {
        type: '~',
        model: 'BiddingImported',
        label: 'Alteração na Licitação',
        icon: mdiGavel,
        validate: event => {
          if (Object.keys(event.diff).length !== 0) {
            return true
          }
          return false
        },
      },
      {
        type: '+',
        model: 'BiddingFile',
        label: 'Criação do Anexo',
        icon: mdiPaperclipPlus,
      },
      {
        type: '-',
        model: 'BiddingFile',
        label: 'Remoção do Anexo',
        icon: mdiPaperclipRemove,
      },
      {
        type: '~',
        model: 'BiddingItem',
        label: 'Produto ganho',
        icon: mdiTrophyVariantOutline,
        validate: event => {
          if (Object.keys(event.diff).length < 3 && Object.keys(event.diff).length !== 0) {
            if ('result' in event.diff) {
              if (event.diff.result === 'gain') {
                return true
              }
            }
          }
          return false
        },
      },
      {
        type: '~',
        model: 'BiddingItem',
        label: 'Produto Perdido',
        icon: mdiTrophyBroken,
        validate: event => {
          if (Object.keys(event.diff).length < 3 && Object.keys(event.diff).length !== 0) {
            if ('result' in event.diff) {
              if (event.diff.result === 'lost') {
                return true
              }
            }
          }
          return false
        },
      },
      {
        type: '~',
        model: 'BiddingItem',
        label: 'Produto Pendente',
        icon: mdiPencilOutline,
        validate: event => {
          if (Object.keys(event.diff).length < 3 && Object.keys(event.diff).length !== 0) {
            if ('result' in event.diff) {
              if (event.diff.result === 'pending') {
                return true
              }
            }
          }
          return false
        },
      },
      {
        type: '+',
        model: 'BiddingItem',
        label: 'Inclusão do Item',
        icon: mdiPackageVariantPlus,
      },
      {
        type: '-',
        model: 'BiddingItem',
        label: 'Exclusão do Item',
        icon: mdiPackageVariantRemove,
      },
    ]

    return {
      isLoading,
      syncLoad,
      historyList,
      labelList,
    }
  },
  computed: {

  },
  created() {
    this.fetchHistorics()
  },
  methods: {
    fetchHistorics() {
      syslic
        .bidding
        .history
        .fetchHistory(this.biddingId)
        .then(response => {
          this.historyList = response.data.events
          this.syncLoad.ready('history')
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Não foi possível carregar o histórico desta licitação.',
              text: 'Não foi possível carregar o histórico, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
          this.syncLoad.ready('history')
        })
    },
    isEmpty(obj) {
      return Object.keys(obj).length === 0
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      mdiTrophyVariantOutline,
      mdiTrophyBroken,
      mdiGavel,
      mdiFileEditOutline,
      mdiFileCertificateOutline,
      mdiPaperclipPlus,
      mdiPaperclipRemove,
      mdiPackageVariantPlus,
      mdiPackageVariantRemove,
      mdiPencilOutline,
    }
  },
}
</script>

<style lang="sass">

</style>
