<template>
  <div>
    <div
      v-if="!isOpen"
    >
      <b-card
        class="mb-0 biddingProductLoteCard"
        :class="isEven ? 'itemEven' : 'itemOdd'"
        no-body
      >
        <b-card-body
          class="pt-50 pb-50"
        >
          <b-row>
            <b-col
              cols="12"
              md="1"
              class="d-flex justify-content-start align-self-center"
            >
              <div>
                <svg-icon
                  v-if="lote.result !== 'pending'"
                  :class="['d-inline-block', 'mr-50', lote.result == 'gain' ? 'biddingGainOk' : 'biddingGainFail']"
                  type="mdi"
                  :path="mdiTrophyOutline"
                />
                <svg-icon
                  v-if="isItemOk"
                  class="d-inline-block biddingMarginOk"
                  type="mdi"
                  :path="mdiPackageVariantClosedCheck"
                />
                <svg-icon
                  v-else
                  class="d-inline-block biddingMarginFail"
                  type="mdi"
                  :path="mdiPackageVariantClosedRemove"
                />
                <svg-icon
                  class="d-inline-block biddingIconType ml-50"
                  type="mdi"
                  :path="mdiAlphaLBox"
                />
              </div>
            </b-col>
            <b-col
              cols="12"
              md="11"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="1"
                  class="biddingCardItemBlock biddingCardItemSection"
                >
                  <div>
                    <span
                      class="biddingCardItemSectionTitle"
                    >
                      Nº ITEM:
                    </span>
                    <b-media
                      class="biddingCardItemSectionContent"
                    >
                      <b-row>
                        <b-col>
                          <h5
                            v-if="lote.number"
                            class="mb-0 d-flex"
                          >
                            {{ lote.number }}
                          </h5>
                          <h5 v-else>
                            -
                          </h5>
                        </b-col>
                      </b-row>
                    </b-media>
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  md="2"
                  class="biddingCardItemBlock biddingCardItemSection"
                >
                  <div
                    class="ml-sm-1"
                  >
                    <span
                      class="biddingCardItemSectionTitle"
                    >
                      NOME DO LOTE:
                    </span>
                    <b-media
                      class="biddingCardItemSectionContent"
                    >
                      <b-row>
                        <b-col>
                          <h5
                            v-if="lote.name"
                            class="mb-0 d-flex"
                          >
                            {{ lote.name }}
                          </h5>
                          <h5 v-else>
                            -
                          </h5>
                        </b-col>
                      </b-row>
                    </b-media>
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  md="2"
                  class="biddingCardItemBlock biddingCardItemSection"
                >
                  <div
                    class="ml-sm-1"
                  >
                    <span
                      class="biddingCardItemSectionTitle"
                    >
                      QUANTIDADE:
                    </span>
                    <b-media
                      class="biddingCardItemSectionContent"
                    >
                      <b-row>
                        <b-col>
                          <h5
                            class="mb-0 d-flex"
                          >
                            -
                          </h5>
                        </b-col>
                      </b-row>
                    </b-media>
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  md="2"
                  class="biddingCardItemBlock biddingCardItemSection"
                >
                  <div
                    class="ml-sm-1"
                  >
                    <span
                      class="biddingCardItemSectionTitle"
                    >
                      VALOR UNITÁRIO MÍNIMO:
                    </span>
                    <b-media
                      class="biddingCardItemSectionContent"
                    >
                      <b-row>
                        <b-col>
                          <h5
                            class="mb-0 d-flex"
                          >
                            R$ {{ totalMin | formatCurrency() }}
                          </h5>
                        </b-col>
                      </b-row>
                    </b-media>
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  md="2"
                  class="biddingCardItemBlock biddingCardItemSection"
                >
                  <div
                    class="ml-sm-1"
                  >
                    <span
                      class="biddingCardItemSectionTitle"
                    >
                      VALOR TOTAL MÍNIMO:
                    </span>
                    <b-media
                      class="biddingCardItemSectionContent"
                    >
                      <b-row>
                        <b-col>
                          <h5
                            class="mb-0 d-flex"
                          >
                            R$ {{ totalMin | formatCurrency }}
                          </h5>
                        </b-col>
                      </b-row>
                    </b-media>
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  md="2"
                  class="biddingCardItemBlock biddingCardItemSection"
                >
                  <div
                    class="ml-sm-1"
                  >
                    <span
                      class="biddingCardItemSectionTitle"
                    >
                      {{ lote.result === 'gain'? 'VALOR GANHO': 'VALOR ESTIMADO' }}
                    </span>
                    <b-media
                      class="biddingCardItemSectionContent"
                    >
                      <b-row>
                        <b-col>
                          <h5
                            class="mb-0 d-flex"
                          >
                            R$ {{ total | formatCurrency }}
                          </h5>
                        </b-col>
                      </b-row>
                    </b-media>
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  md="1"
                  class="p-0 d-flex justify-content-end"
                >
                  <div
                    class="d-flex align-items-center justify-content-center"
                  >
                    <b-dropdown
                      v-if="!readonly"
                      id="idDropdownMenu"
                      variant="link"
                      pill
                      no-caret
                      :dropleft="true"
                      :boundary="'scrollParent'"
                      :right="$store.state.appConfig.isRTL"
                    >

                      <template #button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="align-middle text-body"
                          style="color: #005C96"
                        />
                      </template>

                      <b-dropdown-item
                        @click="showConfirmOrCancelDuplicate()"
                      >
                        <svg-icon
                          type="mdi"
                          :path="mdiContentDuplicate"
                        />
                        <span class="align-middle ml-50">Duplicar Lote</span>
                      </b-dropdown-item>

                      <b-dropdown-item
                        @click="showConfirmOrCancelDelete()"
                      >
                        <svg-icon
                          type="mdi"
                          :path="mdiTrashCanOutline"
                        />
                        <span class="align-middle ml-50">Remover</span>
                      </b-dropdown-item>
                    </b-dropdown>

                    <b-button
                      class="btn-icon rounded-circle"
                      variant="flat-primary"
                      size="sm"
                      @click="toggleCard()"
                    >
                      <svg-icon
                        type="mdi"
                        :path="mdiChevronRight"
                      />
                    </b-button>

                    <div
                      v-if="!readonly"
                      class="d-flex align-items-center justify-content-center ml-1"
                    >
                      <b-form-checkbox
                        :id="`checkboxItem${lote.id}`"
                        v-model="itemCheck"
                        @input="handleCheckbox"
                      />
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
      <div>
        <b-modal
          id="idModalConfirmDuplicate"
          ref="modalConfirmDuplicate"
          no-close-on-backdrop
          no-close-on-esc
          centered
          cancel-variant="outline-primary"
          @ok="showCreateDuplicateModal()"
          @cancel="closeConfirmDuplicate()"
          @hidden="closeConfirmDuplicate()"
        >

          <template #modal-ok>
            Sim
          </template>
          <template #modal-cancel>
            Cancelar
          </template>

          <b-row
            align-h="center"
          >
            <b-col
              cols="12"
              md="12"
            >
              <div
                class="d-flex flex-column align-items-center justify-content-center"
              >
                <svg-icon
                  size="70"
                  type="mdi"
                  :path="mdiAlertCircle"
                />
                <span
                  class="my-1 fs-1 font-weight-bold"
                >
                  Deseja duplicar o lote selecionado?
                </span>
              </div>
            </b-col>
          </b-row>
        </b-modal>
      </div>
      <div>
        <b-modal
          id="idModalCreateDuplicate"
          ref="modalCreateDuplicate"
          no-close-on-backdrop
          no-close-on-esc
          centered
          hide-footer
        >
          <template #modal-title>
            Duplicar Lote
          </template>

          <validation-observer
            v-slot="{ handleSubmit }"
            ref="refDuplicateFormObserver"
          >
            <b-form
              class="mt-1"
              @submit.prevent="handleSubmit(duplicateLote)"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <validation-provider
                    #default="validationContext"
                    name="lote_name"
                    :rules="{
                      required: true,
                    }"
                  >
                    <b-form-group
                      label="Nome do novo Lote"
                      label-for="lote_name"
                      :label-class="'formLabel'"
                    >
                      <b-form-input
                        id="lote_name"
                        v-model="duplicateLoteName"
                        type="text"
                        :placeholder="'Nome do Lote'"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row
                class="py-1"
              >
                <b-col
                  cols="12"
                  md="12"
                  class="d-flex justify-content-end"
                >
                  <b-button
                    class="d-flex justify-content-center align-items-center mr-1"
                    variant="primary"
                    type="submit"
                  >
                    Salvar
                  </b-button>
                  <b-button
                    class="d-flex justify-content-center align-items-center"
                    variant="outline-primary"
                    @click="closeCreateDuplicate"
                  >
                    Cancelar
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-modal>
      </div>
    </div>
    <div
      v-else
      id="biddingProductList"
    >
      <b-row
        v-if="showHeader"
        id="biddingProductListHeader"
        class="pt-1 pb-1"
      >
        <b-col
          cols="12"
          md="6"
          class="align-self-center"
        >
          <div
            class="d-flex justify-content-start align-self-center"
          >
            <svg-icon
              class="d-inline-block biddingIconType ml-2"
              type="mdi"
              :path="mdiAlphaLCircleOutline"
              size="30"
            />
            <span class="ml-1 biddingLoteHeader">
              {{ lote.name }}
            </span>
          </div>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="biddingCardItemBlock biddingCardItemSection"
        >
          <div
            class="justify-content-end d-flex mr-1"
          >
            <div
              class="mr-2"
            >
              <span
                class="biddingCardItemSectionTitleHeader"
              >
                TOTAL MÍNIMO DO LOTE
              </span>
              <div
                class="biddingCardItemSectionContent mb-0 mt-50 loteHeaderContent"
              >
                <b-row>
                  <b-col>
                    <h5
                      class="mb-0 d-flex justify-content-end"
                    >
                      R$ {{ totalMin | formatCurrency }}
                    </h5>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row
        v-if="waitRefresh"
      >
        <b-col
          class="d-flex justify-content-center align-self-center mt-2"
        >
          <b-spinner
            variant="info"
            class="align-middle mb-1 mt-3"
          />
        </b-col>
      </b-row>
      <b-row
        v-for="(item, index) in indexItem"
        v-else
        :key="index"
        class="biddingProductListItem"
        :class="(index % 2 == 0) ? 'itemEven' : 'itemOdd'"
      >
        <b-col
          v-if="items[item].parent === lote.id"
        >
          <bidding-product
            v-if="items[item].type === 'unit'"
            :ref="'refBiddingProductLote' + item"
            :bidding="bidding"
            :item="items[item]"
            :item-list="items"
            :item-index="item"
            :lote="lote.id"
            :readonly="readonly"
            :is-even="(index % 2 == 0)"
            @open="updateOpen(item)"
            @isAdding="isAddingProduct"
            @delete="deleteItem"
          />
          <bidding-product-compound
            v-if="items[item].type === 'compound'"
            :ref="'refBiddingProductLote' + item"
            :bidding="bidding"
            :item="items[item]"
            :item-list="items"
            :item-index="item"
            :lote="lote.id"
            :readonly="readonly"
            :is-even="(index % 2 == 0)"
            @open="updateOpen(item)"
            @isAdding="isAddingProduct"
            @delete="deleteItem"
          />
        </b-col>
      </b-row>
      <b-row
        v-if="isEmpty && !waitRefresh"
        id="biddingProductEmpty"
      >
        <b-col
          class="d-flex justify-content-center align-self-center"
        >
          <p id="emptyText">
            Não existem produtos cadastrados.
          </p>
        </b-col>
      </b-row>
      <b-row
        id="biddingProductAddRow"
      >
        <b-col
          class="justify-content-end d-flex"
        >
          <b-button
            v-if="!readonly && !addingProduct && showHeader"
            v-b-tooltip.hover
            v-b-modal.idModalAddProductLote
            class="btn-icon rounded-circle m-1"
            variant="primary"
            size="md"
            title="Adicionar produto"
          >
            <svg-icon
              type="mdi"
              :path="mdiPackageVariantClosedPlus"
            />
          </b-button>
          <b-button
            v-if="showHeader"
            v-b-tooltip.hover
            class="btn-icon rounded-circle m-1"
            variant="primary"
            size="md"
            title="Fechar lote"
            @click="toggleCard()"
          >
            <svg-icon
              type="mdi"
              :path="mdiCloseThick"
            />
          </b-button>
        </b-col>
      </b-row>
      <div>
        <b-modal
          id="idModalAddProductLote"
          ref="modalAddProduct"
          no-close-on-backdrop
          no-close-on-esc
          hide-footer
          centered
        >
          <template #modal-title>
            Selecione o Tipo de Produto
          </template>
          <b-row
            class="mt-1"
            align-h="center"
          >
            <b-col
              cols="12"
              md="4"
            >
              <b-button
                v-b-tooltip.hover
                class="m-1 addProductBtn"
                size="md"
                variant=""
                title="Adicionar Produto Unitário"
                @click="addProductUnit()"
              >
                <b-row>
                  <b-col>
                    <svg-icon
                      type="mdi"
                      size="45"
                      :path="mdiAlphaUBoxOutline"
                    />
                  </b-col>
                </b-row>
                <b-row class="mt-50">
                  <b-col>
                    <span>Produto Unitário</span>
                  </b-col>
                </b-row>
              </b-button>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <b-button
                v-b-tooltip.hover
                class="m-1 addProductBtn"
                size="md"
                variant=""
                title="Adicionar Produto Composto"
                @click="addProductCompound()"
              >
                <b-row>
                  <b-col>
                    <svg-icon
                      type="mdi"
                      size="45"
                      :path="mdiAlphaCBox"
                    />
                  </b-col>
                </b-row>
                <b-row class="mt-50">
                  <b-col>
                    <span>Produto Composto</span>
                  </b-col>
                </b-row>
              </b-button>
            </b-col>
          </b-row>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormCheckbox,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BModal,
  VBTooltip,
  VBModal,
  BCard,
  BCardBody,
  BDropdown,
  BDropdownItem,
  BMedia,
  BSpinner,
} from 'bootstrap-vue'

import {
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate'

import {
  mdiPlus,
  mdiAlphaUBoxOutline,
  mdiAlphaCBox,
  mdiAlphaLBox,
  mdiAlertCircle,
  mdiTrashCanOutline,
  mdiTrophyOutline,
  mdiPackageVariantClosedCheck,
  mdiPackageVariantClosedRemove,
  mdiChevronDown,
  mdiChevronRight,
  mdiListStatus,
  mdiAlphaLCircleOutline,
  mdiCloseThick,
  mdiPackageVariantClosedPlus,
  mdiContentDuplicate,
} from '@mdi/js'

import {
  useToast,
} from 'vue-toastification/composition'

import SvgIcon from '@jamescoyle/vue-icon'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import BiddingProduct from '@/views/bidding/components/BiddingProduct.vue'
import BiddingProductCompound from '@/views/bidding/components/BiddingProductCompound.vue'
import formatCurrency from '@/components/utils/filters/formatCurrency'
import syslic from '@/syslic'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormInvalidFeedback,
    BButton,
    BModal,
    SvgIcon,
    BiddingProduct,
    BiddingProductCompound,
    BCard,
    BCardBody,
    BDropdown,
    BDropdownItem,
    BMedia,
    BSpinner,
    ValidationObserver,
    ValidationProvider,
  },
  filters: {
    formatCurrency,
  },
  directives: {
    VBModal,
    'b-tooltip': VBTooltip,
  },
  props: {
    bidding: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: true,
    },
    lote: {
      type: Object,
      required: true,
    },
    itemList: {
      type: Array,
      required: true,
    },
    itemIndex: {
      type: Number,
      required: true,
    },
    isEven: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    const itemBlank = {
      type: '',
      parent: '',
      product_list: [],
      number: '',
      name: '',
      quantity: '',
      description: '',
      cost: '',
      price: '',
      price_min: '',
      freight: '',
      tax: '',
      result: 'pending',
      fixed_cost: '',
      margin_min: 0,
      meta: {
        isOpen: false,
      },
    }

    const total = 0
    const totalMin = 0

    const duplicateLoteName = ''

    const itemCheck = false

    const items = []
    const indexItem = []

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(this.lote)

    const addingProduct = false
    const showHeader = true

    const options = {
      number: {
        numeral: true,
        numeralDecimalScale: 0,
        numeralDecimalMark: '',
        delimiter: '',
        numeralPositiveOnly: true,
      },
    }

    const isOpen = false
    const isItemOk = true
    const isEmpty = true
    const waitRefresh = false

    return {
      itemBlank,
      items,
      itemCheck,
      total,
      totalMin,
      indexItem,
      addingProduct,
      showHeader,
      options,
      refFormObserver,
      getValidationState,
      isOpen,
      isItemOk,
      isEmpty,
      waitRefresh,
      duplicateLoteName,
    }
  },
  watch: {
    items: {
      handler: function updateTotal(val) {
        let empty = true

        this.refreshTotal()
        this.refreshStatus()
        this.refreshIndex()

        val.forEach(element => {
          if (element.parent === this.lote.id) {
            empty = false
          }
        })

        this.isEmpty = empty
      },
      deep: true,
    },
  },
  created() {
    this.showHeader = true
    this.items = this.itemList
  },
  methods: {
    getBasicItem(type) {
      const newItem = JSON.parse(JSON.stringify(this.itemBlank))
      newItem.bidding = this.bidding.id
      newItem.type = type
      newItem.parent = this.lote.id

      return newItem
    },
    addProductUnit() {
      this.$refs.modalAddProduct.hide()
      const newItem = this.getBasicItem('unit')
      newItem.product_list = [
        {
          product: '',
          quantity: '',
          price: '',
          product_set: '',
        },
      ]

      this.items.push(newItem)
    },
    addProductCompound() {
      this.$refs.modalAddProduct.hide()
      const newItem = this.getBasicItem('compound')
      this.items.push(newItem)
    },
    updateOpen(indexOpen) {
      this.indexItem.forEach((item, index) => {
        if (index !== indexOpen) {
          const key = `refBiddingProductLote${index}`
          if (key in this.$refs) {
            if (this.$refs[key].length > 0) {
              this.$refs[key][0].close()
            }
          }
        }
      })
    },
    isAddingProduct(value) {
      this.addingProduct = value
    },
    refreshTotal() {
      let total = 0
      let totalMin = 0

      this.items.forEach(val => {
        if (val.parent === this.lote.id) {
          total = Number(total) + (Number(val.price) * Number(val.quantity))
          totalMin = Number(totalMin) + (Number(val.price_min) * Number(val.quantity))
        }
      })

      this.total = total
      this.totalMin = totalMin
    },
    refreshStatus() {
      let isItemOk = false
      let hasPriceLessThanMin = false

      this.items.forEach(val => {
        if (val.parent === this.lote.id) {
          if (parseFloat(val.price) < parseFloat(val.price_min)) {
            hasPriceLessThanMin = true
          }
          isItemOk = true
        }
      })

      if (hasPriceLessThanMin) isItemOk = false

      this.isItemOk = isItemOk
    },
    refreshIndex() {
      const listIndex = []
      this.items.forEach((element, index) => {
        if (element.parent === this.lote.id) {
          listIndex.push(index)
        }
      })

      this.indexItem = listIndex
    },
    deleteItem(index) {
      const itemsCache = this.items

      this.indexItem = []
      this.items = []

      this.waitRefresh = true

      setTimeout(() => {
        itemsCache.splice(index, 1)

        itemsCache.forEach((elem, i) => {
          if (Object.hasOwn(itemsCache[i], 'meta')) {
            if (Object.hasOwn(itemsCache[i].meta, 'isOpen')) {
              itemsCache[i].meta.isOpen = false
            }
          }
        })

        this.items = JSON.parse(JSON.stringify(itemsCache))

        setTimeout(() => {
          this.waitRefresh = false
        }, 500)
      }, 2000)
    },
    toggleCard() {
      if (!this.isOpen) {
        this.open()
      } else {
        this.close()
      }
      this.verifyGain()
    },
    open() {
      this.isOpen = true
      this.$emit('open', this.itemIndex)
      this.$emit('toggleCard', this.isOpen)
    },
    tryClose() {
      const promise = new Promise(resolve => {
        resolve()
      })
      return promise
    },
    close() {
      this.closeAllItems()
      this.isOpen = false
      this.$emit('close', this.itemIndex)
      this.$emit('toggleCard', this.isOpen)
    },
    closeAllItems() {
      this.indexItem.forEach(val => {
        const key = `refBiddingProductLote${val}`

        if (key in this.$refs) {
          if (this.$refs[key].length > 0) {
            this.$refs[key][0].close()
          }
        }
      })
    },
    remove() {
      syslic
        .bidding
        .item
        .deleteItem(this.lote.id)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Lote removido com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.$emit('delete', this.itemIndex)
        })
        .catch(() => {
          this.$swal({
            title: 'Atenção!',
            text: 'Não foi possível remover o lote da licitação, por favor entre em contato com o administrador do sistema.',
            icon: 'danger',
            showConfirmButton: false,
            timer: 10000,
          })
        })
    },
    duplicateLote() {
      const loteItems = [...this.itemList.filter(item => item.parent === this.lote.id)]
      loteItems.forEach(item => {
        item.id = null // eslint-disable-line no-param-reassign
      })
      const duplicateLote = JSON.parse(JSON.stringify(this.lote))
      duplicateLote.name = this.duplicateLoteName

      this.$emit('duplicate', duplicateLote, loteItems)
      this.closeCreateDuplicate()
    },
    showConfirmOrCancelDelete() {
      this.$bvModal
        .msgBoxConfirm('Você realmente quer remover este lote da licitação?', {
          id: 'confirmation_box',
          title: 'Confirmar?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.remove()
          }
        })
    },
    showConfirmOrCancelDuplicate() {
      this.$refs.modalConfirmDuplicate.show()
    },
    closeConfirmDuplicate() {
      this.$refs.modalConfirmDuplicate.hide()
    },
    showCreateDuplicateModal() {
      this.$refs.modalCreateDuplicate.show()
    },
    closeCreateDuplicate() {
      this.$refs.modalCreateDuplicate.hide()
      setTimeout(() => {
        this.duplicateLoteName = ''
      }, 1000)
    },
    verifyGain() {
      let itemResult = 'pending'
      let hasPendingResult = false
      let hasGainResult = false

      this.items.forEach(val => {
        if (val.parent === this.lote.id) {
          if (val.result === 'pending') {
            hasPendingResult = true
          } else if (val.result === 'gain') {
            hasGainResult = true
          }
          itemResult = 'lost'
        }
      })
      if (hasPendingResult) itemResult = 'pending'
      else if (hasGainResult) itemResult = 'gain'

      this.lote.result = itemResult
      this.updateItemResult(this.lote)
    },
    updateItemResult(item) {
      const itemResult = {
        observation: item.observation,
        result: item.result,
      }
      syslic
        .bidding
        .item
        .updateItemResult(item.id, itemResult)
        .then(() => {
          this.$emit('ready', true)
        })
        .catch(() => {
          this.$emit('ready', true)
        })
    },
    changeCheck(val) {
      this.itemCheck = val

      this.updateParentsCheck()
    },
    handleCheckbox() {
      this.$emit('product-check', this.lote.id, this.itemCheck)
      this.updateParentsCheck()
    },
    updateParentsCheck() {
      const parentsId = [...this.itemList.filter(item => item.parent === this.lote.id).map(item => item.id)]

      parentsId.forEach(id => {
        this.$emit('product-check', id, false)
      })
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      mdiPlus,
      mdiAlphaUBoxOutline,
      mdiAlphaCBox,
      mdiAlphaLBox,
      mdiAlertCircle,
      mdiTrashCanOutline,
      mdiTrophyOutline,
      mdiPackageVariantClosedCheck,
      mdiPackageVariantClosedRemove,
      mdiChevronDown,
      mdiChevronRight,
      mdiListStatus,
      mdiAlphaLCircleOutline,
      mdiCloseThick,
      mdiPackageVariantClosedPlus,
      mdiContentDuplicate,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/base/core/colors/palette-variables.scss";

#biddingCardItemPrice {
  border: 1px solid #ebe9f1;
}

.biddingCardItemBlock .media{
  margin-bottom: 0.5rem;
}

.biddingCardItemBlock .media-body{
  color: #5e5873;
  margin-top: 0.5rem;
}

.biddingCardItemBlock.left {
  border-left: 1px solid #ebe9f1;
}

.biddingCardItemSectionTitleHeader {
  font-weight: 600;
  font-size: 1rem;
  color: #005C96;
  margin-top: 0.75rem;
}

.biddingCardItemSectionContentHeader h5{
  font-weight: 600;
  font-size: 1.15rem;
}

#biddingProductListHeader {
  background-color: #89C1CD;
  border-bottom: 1px solid #ebe9f1;
}

.biddingProductListItem {
  border-bottom: 1px solid #ebe9f1;
}

.biddingProductListItem.itemOdd {
  background-color: #F6F6F9;
}

#biddingProductEmpty {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

#emptyText {
  color: #a3a3a3;
  font-weight: 500;
  font-size: 1.45rem;
}

#biddingProductList {
  min-height: 28.5rem;
  position: relative;
  padding-bottom: 4.5rem;
}

#biddingProductAddRow {
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.biddingCardItemBlock .media{
  margin-bottom: 0.5rem;
}

.biddingCardItemBlock .media-body{
  color: #5e5873;
  margin-top: 0.5rem;
}

.biddingCardItemBlock.left {
  border-left: 1px solid #ebe9f1;
}

.biddingCardItemSectionTitleHeader {
  font-weight: 600;
  font-size: 1rem;
  color: #005C96;
  margin-top: 0.75rem;
}

.biddingCardItemSectionContentHeader h5{
  font-weight: 600;
  font-size: 1.15rem;
}

.loteHeaderContent h5{
  font-weight: 600 !important;
  color: $primary;
  font-size: 1.15rem;
}

.biddingCardItemSectionTitle {
  font-weight: 600;
  font-size: 0.8rem;
  color: #005C96;
  margin-top: 0.75rem;
}

.biddingCardItemSectionContent h5{
  font-weight: 400;
  font-size: 1.15rem;
}

.biddingProductLoteCard.itemOdd {
  background-color: #F6F6F9 !important;
}

.biddingMarginOk {
  color: #16A32D;
}

.biddingMarginFail {
  color: #F3271A;
}

.biddingGainOk {
  color: #16A32D;
}
.biddingGainFail {
  color: #F3271A;
}

.biddingIconType {
  color: $primary;
}

.biddingLoteHeader {
  font-size: 1.5rem;
  color: $primary;
  font-weight: 600;
}

#biddingProductLoteHeader {
  background-color: #89C1CD;
  border-bottom: 1px solid #ebe9f1;
}

#idModalConfirmDuplicate {
  > span {
    font-size: 2rem;
  }
}
</style>

<style lang="scss">
@import "@core/scss/base/core/colors/palette-variables.scss";

#idModalAddProductLote .modal-title {
  color: $primary;
  font-weight: 600;
  font-size: 1.25rem;
}

#idModalConfirmDuplicate {
  .modal-body {
    span {
      text-align: center;
      font-size: 1.35rem;
    }

    svg {
      color: #FF7A00;
    }
  }

  .modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;

    padding-bottom: 1rem;

    button {
      min-width: 8rem;
    }
  }
}

#idModalCreateDuplicate {
  .modal-header .modal-title {
    font-size: 1.25rem;
  }

  .modal-footer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row-reverse;

    button {
      min-width: 8rem;
    }
  }
}

.addProductBtn {
  background-color: #D9D9D9 !important;
  border: 0;
  color: $primary !important;
  font-size: 1rem;
  min-width: 8rem;
  min-height: 8.5rem;
}

.formLabel {
  font-size: .9rem;
  font-weight: 400;
}

</style>
