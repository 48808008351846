<template>
  <div>
    <div>
      <b-row class="justify-content-center">
        <b-col
          cols="12"
          md="12"
          lg="12"
        >
          <div>
            <b-table
              class="position-relative"
              primary-key="id"
              responsive
              show-empty
              striped
              hover
              empty-text="Não há documentos vinculados."
              :items="biddingDocuments"
              :fields="tableColumns"
              :sort-by.sync="sortBy"
              :sort-desc.sync="isSortDirDesc"
              :busy="isLoadingBid"
              @row-clicked="viewDocument"
            >
              <template #cell(id)="field">
                <b-media vertical-align="center">
                  <b-link
                    class="font-weight-bold d-block text-nowrap"
                  >
                    #{{ field.item.document_set.id }}
                  </b-link>
                </b-media>
              </template>

              <template #cell(field_document_name)="field">
                <b-media vertical-align="center">
                  <div
                    class="d-block text-nowrap font-weight-bold"
                  >
                    {{ field.item.document_set.document_name }}
                  </div>
                </b-media>
              </template>

              <template #cell(field_status)="field">
                <div
                  class="d-block text-nowrap"
                >
                  <b-badge
                    pill
                    class="text-capitalize"
                    :style="{
                      'background-color': getCompanyStatus(field.item.document_set.date_validity) === 'Vencido' ? '#E2445C' : '#219753'
                    }"
                  >
                    {{ getCompanyStatus(field.item.document_set.date_validity) }}
                  </b-badge>
                </div>
              </template>

              <template #cell(field_date_emission)="field">
                <b-media vertical-align="center">
                  <div
                    v-if="field.item.document_set.date_emission"
                    class="d-block text-nowrap"
                  >
                    {{ moment(field.item.document_set.date_emission).format('DD/MM/YYYY') }}
                  </div>
                  <div v-else>
                    <span class="font-weight-bold">-</span>
                  </div>
                </b-media>
              </template>

              <template #cell(field_date_validity)="field">
                <div
                  v-if="field.item.document_set.date_validity"
                  class="d-block text-nowrap"
                >
                  {{ moment(field.item.document_set.date_validity ).format('DD/MM/YYYY') }}
                </div>
                <div v-else>
                  <span class="font-weight-bold">-</span>
                </div>
              </template>

              <template #cell(field_link_certificates)="field">
                <div
                  v-if="field.item.document_set.link_certificates"
                  class="d-block text-nowrap"
                >
                  {{ field.item.document_set.link_certificates }}
                </div>
                <div v-else>
                  <span class="font-weight-bold">-</span>
                </div>
              </template>

              <template #cell(field_actions)="field">
                <div v-if="!readonly">
                  <b-button
                    class="btn-icon rounded-circle"
                    variant="flat-info"
                    size="md"
                    @click="showConfirmOrCancelDelete(field.item)"
                  >
                    <svg-icon
                      type="mdi"
                      size="18"
                      :path="mdiTrashCanOutline"
                    />
                  </b-button>
                </div>
              </template>
            </b-table>

            <div class="mx-2 mb-2">
              <b-row>
                <b-col
                  cols="12"
                  class="d-flex align-items-center justify-content-center"
                >
                  <b-pagination
                    v-model="currentPageBid"
                    :total-rows="totalDocuments"
                    :per-page="perPage"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>

                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <validation-observer
      v-slot="{ handleSubmit, invalid }"
      ref="refFormObserver"
    >
      <b-modal
        id="idModalAddDocument"
        ref="modalAddDocument"
        class="modalDocument"
        no-close-on-backdrop
        no-close-on-esc
        size="xl"
        centered
        :ok-disabled="invalid"
        hide-footer
        @ok="handleSubmit(save)"
        @cancel="cancel()"
        @hidden="cancel()"
      >
        <template #modal-title>
          Buscar Documentos
        </template>
        <div class="mr-2 mb-1">
          <b-row>
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <b-form-group
                label="Buscar por Nome"
                label-for="idSearch"
                class="w-100 mb-0"
              >
                <b-form-input
                  id="idSearch"
                  v-model.lazy="searchQuery"
                  placeholder="Digite..."
                  type="text"
                  class="d-inline-block"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div>
          <b-table
            class="position-relative"
            primary-key="id"
            responsive
            show-empty
            striped
            hover
            empty-text="Não há documentos cadastrados."
            :items="companyDocuments"
            :fields="tableColumnsModal"
            :sort-by.sync="sortBy"
            :sort-desc.sync="isSortDirDesc"
          >
            <template #cell(id)="field">
              <b-media vertical-align="center">
                <b-link
                  class="font-weight-bold d-block text-nowrap"
                >
                  #{{ field.item.id }}
                </b-link>
              </b-media>
            </template>

            <template #cell(field_document_name)="field">
              <b-media vertical-align="center">
                <div
                  class="d-block text-nowrap font-weight-bold"
                >
                  {{ field.item.document_name }}
                </div>
              </b-media>
            </template>

            <template #cell(field_status)="field">
              <div
                class="d-block text-nowrap"
              >
                <b-badge
                  pill
                  class="text-capitalize"
                  :style="{
                    'background-color': getCompanyStatus(field.item.date_validity) === 'Vencido' ? '#E2445C' : '#219753'
                  }"
                >
                  {{ getCompanyStatus(field.item.date_validity) }}
                </b-badge>
              </div>
            </template>

            <template #cell(field_date_emission)="field">
              <b-media vertical-align="center">
                <div
                  v-if="field.item.date_emission"
                  class="d-block text-nowrap"
                >
                  {{ moment(field.item.date_emission).format('DD/MM/YYYY') }}
                </div>
                <div v-else>
                  <span class="font-weight-bold">-</span>
                </div>
              </b-media>
            </template>

            <template #cell(field_date_validity)="field">
              <div
                v-if="field.item.date_validity"
                class="d-block text-nowrap"
              >
                {{ moment(field.item.date_validity ).format('DD/MM/YYYY') }}
              </div>
              <div v-else>
                <span class="font-weight-bold">-</span>
              </div>
            </template>

            <template #cell(field_link_certificates)="field">
              <div
                v-if="field.item.link_certificates"
                class="d-block text-nowrap"
              >
                {{ field.item.link_certificates }}
              </div>
              <div v-else>
                <span class="font-weight-bold">-</span>
              </div>
            </template>

            <template #cell(field_actions)="field">
              <b-form-checkbox
                v-model="checkList[field.item.id]"
                name="checkbox-select"
                @input="checkboxChange(field.item)"
              />
            </template>
          </b-table>

          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                class="d-flex align-items-center justify-content-center"
              >
                <b-pagination
                  v-model="currentPageComp"
                  :total-rows="totalItems"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>
            </b-row>
          </div>
          <b-row class="mt-3 mb-1">
            <b-col class="d-flex justify-content-end footer-document-modal">
              <div class="buttonsFooter">
                <b-button
                  variant="primary"
                  :disabled="invalid || !hasPermissionToUpdate"
                  @click="save()"
                >
                  Salvar
                </b-button>
                <b-button
                  class="ml-1"
                  variant="outline-primary"
                  @click="cancel()"
                >
                  Cancelar
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-modal>
    </validation-observer>
    <validation-observer
      v-slot="{ handleSubmit, invalid }"
      ref="refFormObserver"
    >
      <b-modal
        ref="modalViewDocument"
        class="modalDocument"
        no-close-on-backdrop
        no-close-on-esc
        size="lg"
        centered
        :ok-disabled="invalid"
        hide-footer
        @ok="handleSubmit(save)"
        @cancel="cancel()"
        @hidden="cancel()"
      >
        <template #modal-title>
          Visualização do Documento
        </template>
        <b-form
          class="mt-1"
        >
          <b-row>
            <b-col
              class="pr-50"
            >
              <validation-provider
                #default="validationContext"
                name="document"
                :rules="{
                  required: true,
                }"
              >
                <b-form-group
                  label="Documento"
                  label-for="document"
                >
                  <b-form-input
                    id="document"
                    v-model="value.document_name"
                    name="document"
                    placeholder="Documento"
                    :readonly="true"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col
              class="px-50"
            >
              <validation-provider
                v-slot="{ errors }"
                name="date_emission"
                :rules="{
                  required: true,
                }"
              >
                <b-form-group
                  label="Emissão"
                  label-for="date_emission"
                >
                  <b-form-datepicker
                    id="id_date_emission"
                    v-model="value.date_emission"
                    :required="true"
                    locale="pt-BR"
                    :class="errors.length > 0 ? 'form-control is-invalid' : 'form-control'"
                    size="md"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    label-no-date-selected="Nenhuma data foi selecionada"
                    selected-variant="primary"
                    :disabled="true"
                    placeholder="Selecione"
                    label-help="Use o teclado ou o mouse para navegar pelas datas do calendário"
                    reset-button
                    reset-button-variant="info"
                    label-reset-button="Limpar"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              class="pl-50"
            >
              <validation-provider
                v-slot="{ errors }"
                name="date_validity"
                :rules="{
                  required: true,
                }"
              >
                <b-form-group
                  label="Validade"
                  label-for="date_validity"
                >
                  <b-form-datepicker
                    id="id_date_validity"
                    v-model="value.date_validity"
                    :required="true"
                    locale="pt-BR"
                    :class="errors.length > 0 ? 'form-control is-invalid' : 'form-control'"
                    size="md"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    label-no-date-selected="Nenhuma data foi selecionada"
                    selected-variant="primary"
                    :disabled="true"
                    placeholder="Selecione"
                    label-help="Use o teclado ou o mouse para navegar pelas datas do calendário"
                    reset-button
                    reset-button-variant="info"
                    label-reset-button="Limpar"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <validation-provider
                #default="validationContext"
                name="link_certificates"
              >
                <b-form-group
                  label="Link"
                  label-for="link_certificates"
                >
                  <b-form-input
                    id="link_certificates"
                    v-model="value.link_certificates"
                    name="link_certificates"
                    :readonly="true"
                    placeholder="https://"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <validation-provider
                #default="validationContext"
                name="annotation"
              >
                <b-form-group
                  label="Anotações"
                  label-for="fileAnnotation"
                >
                  <b-form-textarea
                    id="fileAnnotation"
                    v-model="value.annotation"
                    :state="getValidationState(validationContext)"
                    trim
                    :readonly="true"
                    :placeholder="'Anotações'"
                  />

                  <small class="text-danger">
                    {{ validationContext.errors[0] }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-button
            v-if="value.file_name"
            v-b-tooltip.hover
            variant="outline-primary"
            size="sm"
            @click="downloadFile(value)"
          >
            <svg-icon
              type="mdi"
              :path="mdiFileDownloadOutline"
            />
            <span
              class="font-weight-bolder"
            >
              Baixar Arquivo
            </span>
          </b-button>
          <div
            v-else
            class="font-italic p-1"
          >
            Não existe arquivo para ser baixado.
          </div>
        </b-form>
      </b-modal>
    </validation-observer>
    <div
      v-if="!readonly"
      class="d-flex justify-content-end"
    >
      <b-button
        v-b-tooltip.hover
        v-b-modal.idModalAddDocument
        class="btn-icon rounded-circle mx-3"
        variant="primary"
        size="md"
        :disabled="readonly"
        title="Buscar Documento"
      >
        <svg-icon
          type="mdi"
          :path="mdiFileSearchOutline"
        />
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BBadge,
  BForm,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BFormInvalidFeedback,
  BFormCheckbox,
  BFormDatepicker,
  BRow,
  BCol,
  BButton,
  BTable,
  BPagination,
  BModal,
  BMedia,
  BLink,
  VBModal,
  VBTooltip,
} from 'bootstrap-vue'

import {
  mdiFileSearchOutline,
  mdiTrashCanOutline,
  mdiFileDownloadOutline,
} from '@mdi/js'

import {
  useToast,
} from 'vue-toastification/composition'

import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'

import {
  required,
  confirmed,
} from '@validations'

import moment from 'moment'
import SvgIcon from '@jamescoyle/vue-icon'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import syslic from '@/syslic'
import SyncLoad from '@/components/utils/syncLoad'

export default {
  components: {
    BBadge,
    BForm,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BFormInvalidFeedback,
    BFormCheckbox,
    BFormDatepicker,
    BRow,
    BCol,
    BButton,
    BTable,
    BPagination,
    BModal,
    BMedia,
    BLink,
    SvgIcon,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    VBModal,
    'b-tooltip': VBTooltip,
  },
  props: {
    biddingId: {
      type: [Number, String],
      required: true,
      default: null,
    },
    companyId: {
      type: [Number, String],
      required: true,
      default: null,
    },
    readonly: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    const perPage = 10
    const totalItems = 0
    const totalDocuments = 0
    const currentPageComp = 1
    const currentPageBid = 1
    const searchQuery = ''
    const sortBy = 'name'
    const isSortDirDesc = false
    const isLoadingBid = true
    const isLoadingCom = true

    const tableColumns = [
      {
        key: 'id',
        label: 'Id',
        thClass: 'tableHeader',
      },
      {
        key: 'field_document_name',
        label: 'Documento',
        thClass: 'tableHeader',
      },
      {
        key: 'field_status',
        label: 'Status',
        thClass: 'tableHeader',
      },
      {
        key: 'field_date_emission',
        label: 'Emissão',
        thClass: 'tableHeader',
      },
      {
        key: 'field_date_validity',
        label: 'Validade',
        thClass: 'tableHeader',
      },
      {
        key: 'field_link_certificates',
        label: 'Link para retirar as certidões',
        thClass: 'tableHeader',
      },
    ]
    const fieldActions = {
      key: 'field_actions',
      label: '',
      thClass: 'tableHeader',
    }

    if (!this.readonly) {
      tableColumns.push(fieldActions)
    }

    const tableColumnsModal = [
      {
        key: 'id',
        label: 'Id',
      },
      {
        key: 'field_document_name',
        label: 'Documento',
      },
      {
        key: 'field_status',
        label: 'Status',
      },
      {
        key: 'field_date_emission',
        label: 'Emissão',
      },
      {
        key: 'field_date_validity',
        label: 'Validade',
      },
      {
        key: 'field_link_certificates',
        label: 'Link para retirar as certidões',
      },
      {
        key: 'field_actions',
        label: '',
      },
    ]

    const companyDocuments = []

    const biddingDocuments = []

    const checkList = []
    const selectedList = []

    const value = {
      id: '',
      file_name: '',
      document_name: '',
      company: this.companyId,
      annotation: '',
      date_emission: '',
      date_validity: '',
      link_certificates: '',
    }

    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('document')
    syncLoad.addTrigger('companyDocument')

    syncLoad.onReady(() => {
      this.isLoadingBid = false
      this.isLoadingCom = false
      this.$emit('ready', true)
    })

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(this.value)

    return {
      required,
      confirmed,
      refFormObserver,
      getValidationState,
      perPage,
      totalItems,
      totalDocuments,
      currentPageComp,
      currentPageBid,
      searchQuery,
      sortBy,
      isSortDirDesc,
      isLoadingBid,
      isLoadingCom,
      tableColumns,
      fieldActions,
      tableColumnsModal,
      companyDocuments,
      biddingDocuments,
      value,
      checkList,
      selectedList,
      syncLoad,
    }
  },
  computed: {
    hasPermissionToRead() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.company.can_read
    },
    hasPermissionToWrite() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.company.can_write
    },
    hasPermissionToUpdate() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.company.can_edit
    },
    hasPermissionToDelete() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.company.can_delete
    },
  },
  watch: {
    companyId: 'fetchDocumentsOfCompany',
    currentPageComp: 'fetchDocumentsOfCompany',
    currentPageBid: 'fetchData',
    searchQuery: function updateSearchQuery(value) {
      this.currentPageComp = 1
      this.searchQuery = value
      this.fetchDocumentsOfCompany()
    },
  },
  created() {
    this.fetchData()
    this.fetchDocumentsOfCompany()
  },
  methods: {
    fetchDocumentsOfCompany() {
      if (this.companyId) {
        this.isLoadingCom = true
        syslic
          .company
          .fileDetail
          .fetchAllFilesDetail({
            p: this.currentPageComp,
            page_size: this.perPage,
            search: this.searchQuery,
            company: this.companyId,
          })
          .then(response => {
            this.totalItems = response.count
            this.companyDocuments = response.results
            this.syncLoad.ready('companyDocument')
            this.isLoadingCom = false
          })
          .catch(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Falha ao ler os dados.',
                text: 'Não foi possível ler os dados dos atestados de capacidade técnica da empresa, por favor entre em contato com o administrador do sistema.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                timeout: false,
              },
            })
            this.syncLoad.ready('companyDocument')
            this.isLoadingCom = false
          })
      }
    },
    fetchData() {
      const params = {
        p: this.currentPageBid,
        page_size: 10,
        search: this.searchQuery,
        bidding: this.biddingId,
      }
      this.isLoadingBid = true

      syslic
        .bidding
        .file
        .fetchCompanyDocuments(params)
        .then(response => {
          this.totalDocuments = response.data.count
          this.biddingDocuments = response.data.results
          this.syncLoad.ready('document')
          this.isLoadingBid = false
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao ler os dados.',
              text: 'Não foi possível ler os dados dos atestados de capacidade técnica, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
          this.syncLoad.ready('document')
          this.isLoadingBid = false
        })
    },
    deleteDocument(item) {
      syslic
        .bidding
        .file
        .deleteCompanyDocument(item.id)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Documento removido com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.currentPageBid = 1
          this.fetchData()
        })
        .catch(() => {
          const textError = 'Não foi possível remover este documento de capacidade técnica, por favor entre em contato com o administrador do sistema.'

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao remover documento de capacidade técnica.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    showConfirmOrCancelDelete(item) {
      this.$bvModal
        .msgBoxConfirm('Por favor, confirme que você deseja remover este documento.', {
          id: 'confirmation_box',
          title: 'Confirmar?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteDocument(item)
          }
        })
    },
    addDocuments() {
      /* eslint arrow-body-style: ["off"] */
      const promiseList = []
      const listFiltered = this.selectedList.filter(element1 => {
        return !this.biddingDocuments.some(element2 => element2.document_set.id === element1.id)
      })
      listFiltered.forEach(element => {
        const params = {
          document: element.id,
          bidding: this.biddingId,
        }
        const promise = new Promise(resolve => {
          syslic
            .bidding
            .file
            .addCompanyDocument(params)
            .then(() => {
              resolve()
            })
        })
        promiseList.push(promise)
      })
      const promise = new Promise(() => {
        Promise.allSettled(promiseList)
          .then(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Documento adicionado com sucesso.',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
            this.fetchData()
          })
          .catch(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Falha adicionar documento.',
                text: 'Não foi possível adicionar o documento, por favor entre em contato com o administrador do sistema.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                timeout: false,
              },
            })
          })
      })
      return promise
    },
    viewDocument(item) {
      this.value = item.document_set
      this.$refs.modalViewDocument.show()
    },
    save() {
      this.addDocuments()
      this.$refs.modalAddDocument.hide()
      this.clearChecks()
    },
    cancel() {
      this.$refs.modalAddDocument.hide()
      this.value = {
        id: '',
        file_name: '',
        document_name: '',
        company: this.companyId,
        annotation: '',
        date_emission: '',
        date_validity: '',
        link_certificates: '',
      }
    },
    checkboxChange(val) {
      if (this.checkList[val.id]) {
        this.selectedList[val.id] = val
      } else {
        this.selectedList.splice(val.id, 1)
      }
    },
    clearChecks() {
      this.checkList = []
    },
    downloadFile(file) {
      syslic
        .company
        .file
        .fetchFile(file.id)
        .then(response => {
          const linkSource = `data:application/file;base64,${response.data.file}`
          const downloadLink = document.createElement('a')
          const fileName = this.value.file_name

          downloadLink.href = linkSource
          downloadLink.download = fileName
          downloadLink.click()
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha para realizar o download do documento da empresa.',
              text: 'Não foi possível realizar o download do documento da empresa, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    hasValidityExpired(dateValidity) {
      if (!dateValidity) {
        return false
      }

      const today = moment().startOf('day')
      const validityDate = moment(dateValidity)

      return validityDate.isBefore(today)
    },
    getCompanyStatus(date) {
      if (this.hasValidityExpired(date)) {
        return 'Vencido'
      }
      return 'Validado'
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      moment,
      mdiFileSearchOutline,
      mdiTrashCanOutline,
      mdiFileDownloadOutline,
    }
  },
}
</script>

<style lang="scss" scoped>
.smallField {
  width: 5px !important;
}

.per-page-selector {
  width: 90px;
}

.dropdownfix .dropdown.show{
  position: fixed;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "@core/scss/base/core/colors/palette-variables.scss";

.card-title {
  color: $primary
}

.modalDocument .vc-slider {
  width: 100% !important;
}

.modalDocument .modal-footer {
  display: flex !important;
  flex-direction: row-reverse;
  justify-content: end;
}

.buttonsFooter .btn {
  min-width: 107px;
}

.footer-document-modal {
  display: flex;
  width: 100%;

  justify-content: space-between;
}

.tableHeader {
  background-color: #ffffff !important;
  border-bottom: 0 !important;
}

.tableHeader * {
  color: #5e5873
}
</style>
